import ReactDatePicker from "react-datepicker";
import { PermitsContext } from "../../../../services/Permits";
import { useContext } from "react";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { useNavigate } from "react-router-dom";

const StepFourForm = ({ type, paymentMethods, handleSubmit }) => {
    const navigate = useNavigate();
    const { searchData, setSearchData } = useContext(PermitsContext);

    const handleChange = (evt) => {
        if ((evt.target.name === "numberOfInsurance") && String(evt.target.value).match(/^[a-z0-9]*$/i) === null) return;
        if (evt.target.name === "zipCode" && String(evt.target.value).match(/^[0-9]*$/i) === null) return;
        setSearchData({...searchData, [evt.target.name]: evt.target.value});
    }

    const handleChangeDateInsurance = (valDate) => {
        if (new Date() <= new Date(valDate)) {
            toast.warning("Loss date must higher than today's date", alertOptions);
            return;
        }
        setSearchData({...searchData, dateInsurance: valDate});
    }

    const handleBack = () => {
        navigate(type === "free" ? "/myQuotes/step_three" : "/home/step_three");
        setSearchData({...searchData, paymentMethod: "", numberOfInsurance: "", companyInsurance: "", dateInsurance: ""});
    }

    return (
        <>
            <div className="flex justify-center items-center space-x-5 pb-5">
                <h1 className="text-2xl md:text-xl lg:text-2xl font-light text-ag-secondary">
                    Payment Method
                </h1>
            </div>
            <div className="pt-2 pb-3">
                <p className="text-ag-secondary-letter">
                    To continue with the order, we need to collect some information:
                </p>
            </div>
            <div className="pt-6 border-t-2 border-ag-primary">
                <div className="text-center">
                    <label className="text-base text-ag-secondary-letter">Payment method:</label>
                </div>
                <select
                    className={`w-full text-center ${ searchData.paymentMethod === "default" || searchData.paymentMethod === "" ? "text-[#C7C7C7]" : "text-ag-secondary-letter" } ${ searchData.paymentMethod === "CAR_INSURANCE" ? "font-semibold" : "" } border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate`}
                    name="paymentMethod"
                    onChange={handleChange}
                    placeholder="Payment method"
                    value={searchData.paymentMethod}
                >
                    <option value="default">Payment method</option>
                    {
                        paymentMethods.map((element, index) => {
                            return <option key={index} value={element.value} className={`text-ag-secondary-letter ${element.style}`}>{element.label}</option>;
                        })
                    }
                </select>
            </div>
            {
                searchData.paymentMethod === "CAR_INSURANCE" ?
                    <div className="py-8 border-b-2 border-ag-primary space-y-5">
                        <div className="text-center text-ag-secondary-letter">
                            <span className="font-semibold">Insurance Information</span>
                        </div>
                        <div className="space-y-3">
                            <div>
                                <div className="text-center">
                                    <label className="text-base text-ag-secondary-letter">Policy number:</label>
                                </div>
                                <input
                                    className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                    name="numberOfInsurance"
                                    type="text"
                                    placeholder="Policy number"
                                    onChange={handleChange}
                                    value={searchData.numberOfInsurance}
                                />
                            </div>
                            <div className="grid gap-3 md:grid-cols-2">
                                <div>
                                    <div className="text-center">
                                        <label className="text-base text-ag-secondary-letter">Insurance Company:</label>
                                    </div>
                                    <input
                                        className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                        name="companyInsurance"
                                        type="text"
                                        placeholder="Insurance Company"
                                        onChange={handleChange}
                                        value={searchData.companyInsurance}
                                    />
                                </div>
                                <div className="customDatePickerWidth">
                                    <div className="text-center">
                                        <label className="text-base text-ag-secondary-letter">Date of lost {'(mm/dd/yyyy)'}:</label>
                                    </div>
                                    <ReactDatePicker
                                        className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                        placeholderText="(mm/dd/yyyy)"
                                        name="dateInsurance"
                                        selected={searchData.dateInsurance}
                                        onChange={(dateVal) => handleChangeDateInsurance(dateVal)}
                                        onKeyDown={(e) => e?.key !== "Backspace" ? e.preventDefault() : null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                : null
            }
            <div className="mt-6 pt-6 border-t-2 border-ag-primary">
                <div className="text-center">
                    <label className="text-base text-ag-secondary-letter">Comments:</label>
                </div>

                <div className="border border-ag-secondary-light rounded-xl p-2">
                    <textarea
                        className="w-full border-none space-y-1 overflow-y-auto p-2 rounded-xl focus:outline-none resize-none"
                        name="comments"
                        type="text"
                        placeholder="Comments"
                        onChange={handleChange}
                        onInput={(e) => {
                            e.target.style.height = "auto";
                            e.target.style.height = `${Math.min(e.target.scrollHeight, 150)}px`; 
                        }}
                    />
                </div>
            </div>
            <div className="mt-10 py-10 border-t-2 border-ag-primary space-y-3">
                <div className="flex justify-between items-center">
                    <button
                        className="w-full lg:w-[30%] bg-transparent text-ag-secondary-letter px-3 py-2 rounded-xl disabled:bg-zinc-200"
                        type="button"
                        onClick={() => handleBack()}
                    >
                        Back
                    </button>
                    <button
                        className="w-full lg:w-[30%] bg-ag-primary-light text-white px-3 py-2 rounded-xl disabled:bg-zinc-200"
                        type="button"
                        onClick={() => handleSubmit(searchData)}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </>
    );
}

export default StepFourForm;