import React from 'react'
import { useEffect, useState } from "react";
import { getAddresses } from "../../../../services/RetailAddress";
import { getWarehouses } from "../../../../services/Warehouse";
import { getCostdeliveryFee } from "../../../../services/ChangeDeliberyFee";


const FormShippingMethodPWRWT = ({ idRetail, quoteDetail, onModal, onMethod, onRefreshAddress, onResponseRefreshAddress }) => {
    const [showMethod, setShowMethod] = useState({ view1: false, view2: false });
    const [addresses, setAddresses] = useState([]);
    const [costShip, setCostShip] = useState(0);

    const handleAddress = (idAddress, addressAux, type, costShipValue) => {
        let addressCurrent = null;
        const newAddress = addressAux.map(addressElement => {
            if (addressElement._id === idAddress._id) {
                addressCurrent = addressElement;
                return { ...addressElement, status: true }
            }
            return { ...addressElement, status: false }
        });
        setAddresses(newAddress);
        onMethod({
            shippingMethod: type === "PICK_UP" ? "PICK_UP" : "SHIP",
            costShipping: type === "PICK_UP" ? 0 : (costShipValue === 0 ? costShip : costShipValue),
            id: idAddress._id,
            idWarehouse: idAddress?._idWarehouse ? idAddress?._idWarehouse : quoteDetail?.productInventoryAllInfo ? quoteDetail?.productInventoryAllInfo?.warehouse?._id : "",
            address: addressCurrent
        });
    }

    const refreshAddress = (costShipValue) => {
        new Promise((resolve, reject) => {
            if (idRetail !== "") {
                getAddresses(idRetail).then(res => {
                    if (res.status === 200 && res.data.length > 0) {
                        let currentAdress = null;
                        const newAddresses = res.data.map((element, i) => {
                            if (element.selected) {
                                currentAdress = element;
                                return { ...element, status: true }
                            }
                            return element;
                        });
                        if (newAddresses.length > 0 && currentAdress === null) {
                            currentAdress = newAddresses[0];
                        }
                        setAddresses(newAddresses);
                        resolve([currentAdress, newAddresses]);
                    } else {
                        setAddresses([]);
                        resolve([null, []]);
                    }
                });
            } else {
                const newAddresses = window.localStorage.getItem("user-data-address");
                setAddresses(newAddresses ? JSON.parse(newAddresses) : []);
                resolve([null, newAddresses ? JSON.parse(newAddresses) : []]);
            }
        }).then((responseAddress) => {
            if (responseAddress[0] !== null) handleAddress(responseAddress[0], responseAddress[1], "SHIP", costShipValue);
        });
    }

    const handleMethod = (type) => {
        if (type === "ship") {
            getCostdeliveryFee('RETAIL_USER_PWR_SERVICE').then(res => {
                if (res.status === 200) {
                    setShowMethod({ ...showMethod, view1: true, view2: false });
                    setCostShip(res.data.cost);
                    refreshAddress(res.data.cost);
                } else {
                    setShowMethod({ ...showMethod, view1: true, view2: false });
                    refreshAddress();
                }
            });
        } else {
            getWarehouses().then(res => {
                setShowMethod({ ...showMethod, view1: false, view2: true });
                if (res.status === 200 && res.data.length > 0) {
                    let currentAdress = null;
                    let warehousesCurrent = res.data.filter(element => !element.deleted);
                    if (quoteDetail.type === 'WINDOW_TINT') {
                        warehousesCurrent = warehousesCurrent.filter(element => element._id.toString() === '65b9991504aa9a000ba8eac5');
                    }
                    const foundLocations = warehousesCurrent.map(elementLocation => {
                        if (elementLocation._id === quoteDetail?.productInventoryAllInfo?.warehouse?._id) {
                            currentAdress = { ...elementLocation.address, _idWarehouse: elementLocation._id, status: true };
                            return { ...elementLocation.address, _idWarehouse: elementLocation._id, status: true };
                        }
                        return { ...elementLocation.address, _idWarehouse: elementLocation._id };
                    });
                    if (currentAdress === null) {
                        currentAdress = {
                            ...foundLocations[0],
                            status: true
                        }
                    }
                    setAddresses(foundLocations);
                    handleAddress(currentAdress, foundLocations, "PICK_UP", 0);
                }
            });
        }
    }


    useEffect(() => {
        if (onRefreshAddress) {
            refreshAddress(0);
            onResponseRefreshAddress(false);
        }
    }, [onRefreshAddress]);

    return (
        <div className="space-y-3">
            {
                quoteDetail?.type === 'POWER_WINDOW_REPAIR' &&
                <div className={`w-full p-4 border rounded-xl ${showMethod.view1 ? "border-ag-primary" : "border-ag-secondary-light"}`}>
                    <div className="flex justify-start items-center space-x-10 px-3">
                        <div>
                            <input
                                className="w-4 h-4 accent-ag-primary cursor-pointer"
                                type="radio"
                                name="shipping_method_retail"
                                onClick={() => handleMethod("ship")}
                            />
                        </div>
                        <div>
                            <span>Mobile Service</span>
                        </div>
                    </div>
                    {
                        showMethod.view1 ?
                            <div className="mt-3 pt-3 space-y-5 border-t border-ag-secondary-light">
                                <div className="px-16 text-ag-secondary-letter">
                                    <span className="px-1">Address</span>
                                </div>
                                {
                                    addresses.map((addressElement, i) => (
                                        <div className={`flex justify-center items-center space-x-3 md:space-x-0 px-3 py-2 border rounded-xl ${addressElement?.status ? "border-ag-primary" : "border-ag-secondary-light"}`} key={i}>
                                            <div className="w-[7%]">
                                                <input
                                                    className="w-4 h-4 accent-ag-primary cursor-pointer"
                                                    type="radio"
                                                    name="shipping_method_address_retail"
                                                    onClick={() => handleAddress(addressElement, addresses, "SHIP", 0)}
                                                    checked={addressElement?.status}
                                                />
                                            </div>
                                            <div className="w-[93%] text-ag-secondary-letter text-xs md:text-base">
                                                <ul>
                                                    <li><p className="truncate w-full">{addressElement.street}, {addressElement.city},</p></li>
                                                    <li><p className="truncate w-full">{addressElement.suburb}, {addressElement.postalCode}</p></li>
                                                    <li><p className="truncate w-full">{addressElement.references}</p></li>
                                                </ul>
                                            </div>
                                        </div>
                                    ))
                                }
                                <div className="px-10 md:px-14">
                                    <button className="text-ag-secondary-letter" type="button" onClick={() => onModal(true)}>
                                        + Save new address
                                    </button>
                                </div>
                            </div>
                            : null
                    }
                </div>
            }
            <div className={`w-full p-4 border rounded-xl ${showMethod.view2 ? "border-ag-primary" : "border-ag-secondary-light"}`}>
                <div className="flex justify-start items-center space-x-10 px-3">
                    <div>
                        <input
                            className="w-4 h-4 accent-ag-primary cursor-pointer"
                            type="radio"
                            name="shipping_method_retail"
                            onClick={() => handleMethod("pickup")}
                        />
                    </div>
                    <div>
                        <span>In Shop</span>
                    </div>
                </div>
                {
                    showMethod.view2 ?
                        <div className="mt-3 pt-3 space-y-5 border-t border-ag-secondary-light">
                            <div className="px-16 text-ag-secondary-letter">
                                <span className="px-1">Locations</span>
                            </div>
                            {
                                addresses.map((addressElement, i) => (
                                    <div className={`flex justify-center items-center space-x-3 md:space-x-0 px-3 py-2 border rounded-xl ${addressElement?.status ? "border-ag-primary" : "border-ag-secondary-light"}`} key={i}>
                                        <div className="w-[7%]">
                                            <input
                                                className="w-4 h-4 accent-ag-primary cursor-pointer"
                                                type="radio"
                                                name="shipping_method_address_retail"
                                                onClick={() => handleAddress(addressElement, addresses, "PICK_UP", 0)}
                                                checked={addressElement?.status}
                                            />
                                        </div>
                                        <div className="w-[93%] text-ag-secondary-letter text-xs md:text-base">
                                            <ul>
                                                <li><p className="truncate w-full">{addressElement?.street}, {addressElement?.locality},</p></li>
                                                <li><p className="truncate w-full">{addressElement?.state} {addressElement?.postalCode}</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        : null
                }
            </div>
        </div>
    )
}

export default FormShippingMethodPWRWT