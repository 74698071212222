import React from 'react'
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import NavbarClients from "../../../../components/navbar/NavbarClients";
import SidebarClients from "../../../../components/sidebar/SidebarClients";
import ChoiceWTForm from './ChoiceWTForm';
import Loader from "../../../../components/loader/Loader";
import { FiChevronLeft } from "react-icons/fi";
import InformationQuotePWRWT from '../details/InformationQuotePWRWT';


const ChoiceWT = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);

    const [quote, setQuote] = useState(null);


    
    const handleContinue = () => {

    }
    const handleStep = async () => {
        // if (searchData.nag !== "") {
        //     navigate(type === "free" ? "/myQuotes/step_four" : "/home/step_four");
        // } else {
        //     toast.info("Please fill in all the fields of the form", alertOptions);
        // }
    }

    const handleBack = () => {

    }

    return (
        <>
            {loader ? <Loader /> : null}

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 py-5 md:py-8 px-5 md:px-16">
                            <div className="grid grid-cols-12">
                                <div>
                                    <button className="p-1 rounded-md" type="button" onClick={() => navigate(-1)}>
                                        <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                    </button>
                                </div>
                                <div className="flex justify-center items-center col-span-11 text-center">
                                    <h1 className="text-xl text-ag-secondary md:mr-[8%]">Choice of window tint</h1>
                                </div>
                            </div>
                          
                                <div className="flex justify-start items-center">
                                    <span className='italic text-ag-secondary ms-4'>Select the window tint service you want based on what you need.</span>
                                </div>
                                <div className="lg:flex lg:justify-center lg:items-start lg:space-x-10 space-y-5 lg:space-y-0">
                                    <div className="w-full lg:w-[65%]">
                                        <ChoiceWTForm />
                                    </div>
                                    <div className="w-full lg:w-[35%]">
                                        {/* <InformationQuote quote={quote} onContinue={handleContinue} /> */}
                                        <InformationQuotePWRWT quote={quote} onContinue={handleContinue} />
                                    </div>
                                </div>
                       
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChoiceWT