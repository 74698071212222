const ServiceCheckbox = ({ label, checked, onChange }) => (
  <li className="text-ag-secondary-letter">
      <div className="flex justify-start items-center space-x-3">
          <input
              type="checkbox"
              checked={checked}
              onChange={(e) => onChange(e.target.checked)}
          />
          <span>{label}</span>
      </div>
  </li>
);

export default ServiceCheckbox;