import { _delete, _get, _getParams, _post, _put } from "./http";

export const getOrder = (id) => _get(`/orders/${id}`);

export const getOrdersByInventory = (idInventory) => _get(`/orders/searchByIdInventory/${idInventory}`);

export const getOrders = (filterParams) => _getParams(`/orders`, filterParams);

export const saveOrder = (ordersObject) => _post(`/orders`, ordersObject);

export const cancelOrder = (id, ordersObject) => _put(`/orders/${id}/cancel`, ordersObject);

export const paidOrder = (id, ordersObject) => _put(`/orders/${id}/paid`, ordersObject);

export const attendOrder = (id, ordersObject) => _put(`/orders/${id}/attend`, ordersObject);

export const organizeOrder = (id, ordersObject) => _put(`/orders/${id}/organize`, ordersObject);

export const deliveryOrder = (id, ordersObject) => _put(`/orders/${id}/delivery`, ordersObject);

export const cancelDeliveryOrder = (id, ordersObject) => _put(`/orders/${id}/cancelDelivery`, ordersObject);

export const checkDeliveryOrder = (id, ordersObject) => _put(`/orders/${id}/checkProduct`, ordersObject);

export const inventoryOrder = (id, ordersObject) => _put(`/orders/${id}/inventoryAllocation`, ordersObject);

// Retail endpoints

export const getOrdersRetail = (idRetail) => _get(`/retailUser/${idRetail}/myOrders`);
