import { useEffect, useState } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdCloseCircle, IoMdImage } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { validateQuotePhoto } from "../../../utils/Validators";
import IconImageAdd from "../../../assets/icons/image_add.svg";
import ReactDatePicker from "react-datepicker";

const ProductRequest = ({ informationData, paymentMethods, onSubmit, onClose }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [photosRequest, setPhotosRequest] = useState({
        names: "",
        lastNames: "",
        phoneNumber: "",
        email: "",
        description: "",
        comments: "",
        photos: [],
        paymentMethod: "",
        numberOfInsurance: "",
        companyInsurance: "",
        dateInsurance: ""
    });

    const [imageUpload, setImageUpload] = useState([
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null }
    ]);

    const handlePhotos = (evt) => {
        const numCurrentImages = imageUpload.filter(imgElement => imgElement.file !== null);
        if ((numCurrentImages.length + evt.target.files.length) > 3) {
            toast.warning("It is not possible to upload more than three images", alertOptions);
        } else {
            const array_files = Array.prototype.slice.call(evt.target.files);
            const typeFileAccept = array_files.filter(imgElement => (imgElement.type !== "image/png" && imgElement.type !== "image/jpg" && imgElement.type !== "image/jpeg"));
            if (typeFileAccept.length > 0) {
                toast.warning("It is not possible to upload a file other than png, jpg or jpeg", alertOptions);
            } else {
                const files = evt.target.files;
                let filesAux = [];
                const newImageUpload = imageUpload.map((imgEle, i) => {
                    if (numCurrentImages.length === 0) {
                        if ((i+1) <= files.length) return { name: files[i].name, file: files[i] }
                    } else {
                        if (imgEle.file === null) {
                            for (let ifiles = 0; ifiles < files.length; ifiles++) {
                                const foundFile = filesAux.find(fileElement => fileElement === files[ifiles].name);
                                if (!foundFile) {
                                    filesAux.push(files[ifiles].name);
                                    return { name: files[ifiles].name, file: files[ifiles] }
                                }
                            }
                        }
                    }
                    return imgEle;
                });
                setImageUpload(newImageUpload);
            }
        }
    }

    const handleDeleteImage = (imgToDelete, evt) => {
        if (evt.target.name === undefined) {
            const newImageUpload = imageUpload.map((imgEle, i) => {
                if (`${imgEle.name}${i}` === imgToDelete) {
                    return { name: "", file: null }
                }
                return { name: imgEle.name, file: imgEle.file }
            });
            setImageUpload(newImageUpload);
        }
    }

    const handleChange = (evt) => {
        if (evt.target.name === "numberOfInsurance" && String(evt.target.value).match(/^[a-z0-9]*$/i) === null) return;
        if (evt.target.name === "phoneNumber") {
            const pattern = new RegExp(/^[0-9\s]+$/g);
            if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                setPhotosRequest({...photosRequest, [evt.target.name]: evt.target.value});
            }
        } else if (evt.target.name === "names" || evt.target.name === "lastNames") {
            const pattern = new RegExp(/^[A-Za-z\s]+$/g);
            if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                setPhotosRequest({...photosRequest, [evt.target.name]: evt.target.value});
            }
        } else {
            setPhotosRequest({...photosRequest, [evt.target.name]: evt.target.value});
        }
    }

    const handleChangeDateInsurance = (valDate) => {
        if (new Date() <= new Date(valDate)) {
            toast.warning("Loss date must higher than today's date", alertOptions);
            return;
        }
        setPhotosRequest({...photosRequest, dateInsurance: valDate});
    }

    const handleSubmit = () => {
        const numCurrentImages = imageUpload.filter(imgElement => imgElement.file !== null);
        if (
            photosRequest.names !== "" && photosRequest.lastNames !== "" && photosRequest.phoneNumber !== "" && photosRequest.email !== "" &&
            photosRequest.description !== "" && numCurrentImages.length > 0 && photosRequest.paymentMethod !== "" && (
            photosRequest.paymentMethod !== "CAR_INSURANCE" || (photosRequest.paymentMethod === "CAR_INSURANCE" && 
            photosRequest.numberOfInsurance !== "" && photosRequest.companyInsurance !== "" && photosRequest.dateInsurance !== ""))
        ) { 
            let newPhotosRequest = {
                names: photosRequest.names,
                lastNames: photosRequest.lastNames,
                phoneNumber: photosRequest.phoneNumber,
                email: photosRequest.email,
                description: photosRequest.description,
                comments: photosRequest.comments,
                photos: imageUpload,
                paymentMethod: photosRequest.paymentMethod,
            };

            if (photosRequest.paymentMethod === "CAR_INSURANCE") {
                newPhotosRequest.numberOfInsurance = photosRequest.numberOfInsurance;
                newPhotosRequest.companyInsurance = photosRequest.companyInsurance;
                newPhotosRequest.dateInsurance = photosRequest.dateInsurance;
            }
            const result = validateQuotePhoto(newPhotosRequest);
            if (result.status) {
                onSubmit(newPhotosRequest);
            } else {
                toast.warning(result.msg, alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        if (informationData?.names !== "" && informationData?.lastNames !== "" && informationData?.email !== "") {
            setIsDisabled(true);
            setPhotosRequest({...photosRequest,
                numberOfInsurance: informationData.numberOfInsurance !== "" ? informationData.numberOfInsurance : "",
                companyInsurance: informationData.companyInsurance !== "" ? informationData.companyInsurance : "",
                dateInsurance: informationData.dateInsurance !== "" ? informationData.dateInsurance : "",
                names: informationData.names,
                lastNames: informationData.lastNames,
                email: informationData.email,
                phoneNumber: informationData.phoneNumber
            });
        }
    }, [informationData]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none mb-0 md:mb-5 xl:mb-0">
                    <div className="w-[320px] md:w-[400px] lg:w-[500px] h-[85vh] md:h-[500px] xl:h-auto">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold pl-[5%]">
                                        Product Requests
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-2 md:px-5 pb-3 pt-5 h-[450px] overflow-y-auto">
                                <div className="space-y-1">
                                    <div className="px-2 text-ag-secondary-letter text-sm pb-2">
                                        <span>
                                            Fill in your details of the product you are looking for and we will contact 
                                            you to give you an answer
                                        </span>
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Name:</label>
                                        </div>
                                        <input
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            name="names"
                                            type="text"
                                            placeholder="Name"
                                            onChange={handleChange}
                                            value={photosRequest.names}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Last Name:</label>
                                        </div>
                                        <input
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            name="lastNames"
                                            type="text"
                                            placeholder="Last Name"
                                            onChange={handleChange}
                                            value={photosRequest.lastNames}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Email:</label>
                                        </div>
                                        <input
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            name="email"
                                            type="text"
                                            placeholder="Email"
                                            onChange={handleChange}
                                            value={photosRequest.email}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Phone Number:</label>
                                        </div>
                                        <input
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            name="phoneNumber"
                                            type="text"
                                            placeholder="Phone Number"
                                            onChange={handleChange}
                                            value={photosRequest.phoneNumber}
                                            maxLength={10}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Description:</label>
                                        </div>
                                        <textarea
                                            className="overflow-y-hidden w-full min-h-20 text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200"
                                            name="description"
                                            type="text"
                                            placeholder="Description"
                                            onChange={handleChange}
                                            value={photosRequest.description}
                                            onInput={(e) => {
                                                e.target.style.height = "auto";
                                                e.target.style.height = e.target.scrollHeight + "px";
                                            }}
                                        />
                                    </div>
                                    <div className="text-center text-ag-secondary-letter">
                                        <span className="text-base">Photos</span>
                                    </div>
                                    <div>
                                        <label className="relative">
                                            { imageUpload[0].name === "" && imageUpload[1].name === "" && imageUpload[2].name === "" ?
                                                <div className="w-full flex justify-center items-center space-x-3 text-ag-secondary-letter border border-gray-500 h-[85px] rounded-xl">
                                                    <span><img className="w-[35px] m-auto" src={IconImageAdd} /></span>
                                                    <span className="w-52 text-xs">
                                                        Drag or click to choose your images<br />
                                                        {'('}3 images maximum{')'}<br />
                                                        <b>Recommended resolution 569x320</b>
                                                    </span>
                                                </div>
                                            :
                                                <div className="w-full text-ag-secondary-letter border border-gray-500 h-[85px] px-6 py-4 rounded-xl" title="">
                                                    {
                                                        imageUpload.map((imageElement, i) => (
                                                            <div key={i} className="flex items-center space-x-3">
                                                                <span className="text-[20px] text-ag-secondary"><IoMdImage /></span>
                                                                <span className="text-left text-[10px] text-ag-secondary truncate cursor-pointer z-0">
                                                                    {imageElement.file === null ? "Image to load..." : imageElement.name }
                                                                </span>
                                                                {
                                                                    imageElement.file === null ?
                                                                        null
                                                                        // <button className="text-green-600 text-sm cursor-pointer" type="button" onClick={() => {}}>
                                                                        //     <AiFillPlusCircle />
                                                                        // </button>
                                                                    :
                                                                        <button className="text-red-500 text-sm cursor-pointer z-50" type="button" onClick={(evt) => handleDeleteImage(`${imageElement.name}${i}`, evt)}>
                                                                            <AiFillCloseCircle />
                                                                        </button>
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            <input
                                                type="file"
                                                title=""
                                                multiple
                                                className="absolute w-full h-full top-[0px] opacity-0 cursor-pointer"
                                                accept=".png, .jpg, .jpeg"
                                                name="photos"
                                                onChange={handlePhotos}
                                            />
                                        </label>
                                    </div>
                                    <div>
                                        <div className="text-center text-ag-secondary-letter">
                                            <span className="text-base">Payment Method</span>
                                        </div>
                                        <select
                                            className={`w-full text-center ${ photosRequest.paymentMethod === "default" || photosRequest.paymentMethod === "" ? "text-[#C7C7C7]" : "text-ag-secondary-letter" } ${ photosRequest.paymentMethod === "CAR_INSURANCE" ? "font-semibold" : "" } border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate`}
                                            name="paymentMethod"
                                            onChange={handleChange}
                                            placeholder="Payment Method"
                                            value={photosRequest.paymentMethod}
                                        >
                                            <option value="default">Payment Method</option>
                                            {
                                                paymentMethods.map((element, index) => {
                                                    return <option key={index} value={element.value} className={`text-ag-secondary-letter ${element.style}`}>{element.label}</option>;
                                                })
                                            }
                                        </select>
                                    </div>
                                    {
                                        photosRequest.paymentMethod === "CAR_INSURANCE" ?
                                            <div className="space-y-8">
                                                <div>
                                                    <div className="pt-2 pb-1 text-center">
                                                        <label className="text-base text-ag-secondary-letter font-semibold">Insurance Information</label>
                                                    </div>
                                                    <div className="space-y-3">
                                                        <div>
                                                            <div className="text-center">
                                                                <label className="text-base text-ag-secondary-letter">Policy Number:</label>
                                                            </div>
                                                            <input
                                                                className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                                                name="numberOfInsurance"
                                                                type="text"
                                                                placeholder="Policy Number"
                                                                onChange={handleChange}
                                                                value={photosRequest.numberOfInsurance}
                                                            />
                                                        </div>
                                                        <div className="grid gap-3 md:grid-cols-2">
                                                            <div>
                                                                <div className="text-center">
                                                                    <label className="text-base text-ag-secondary-letter">Insurance Company:</label>
                                                                </div>
                                                                <input
                                                                    className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                                                    name="companyInsurance"
                                                                    type="text"
                                                                    placeholder="Insurance Company"
                                                                    onChange={handleChange}
                                                                    value={photosRequest.companyInsurance}
                                                                />
                                                            </div>
                                                            <div className="customDatePickerWidth">
                                                                <div className="text-center">
                                                                    <label className="text-base text-ag-secondary-letter">Date of lost {'(mm/dd/yyyy)'}:</label>
                                                                </div>
                                                                <ReactDatePicker
                                                                    className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                                                    placeholderText="Date of lost (mm/dd/yyyy)"
                                                                    name="dateInsurance"
                                                                    selected={photosRequest.dateInsurance}
                                                                    onChange={(dateVal) => handleChangeDateInsurance(dateVal)}
                                                                    onKeyDown={(e) => e?.key !== "Backspace" ? e.preventDefault() : null}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        : null
                                    }
                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Comments:</label>
                                        </div>
                                        <textarea
                                            className="overflow-y-hidden w-full min-h-20 text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200"
                                            name="comments"
                                            type="text"
                                            placeholder="Comments"
                                            onChange={handleChange}
                                            value={photosRequest.comments}
                                            onInput={(e) => {
                                                e.target.style.height = "auto";
                                                e.target.style.height = e.target.scrollHeight + "px";
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="px-2 md:px-5 w-full flex justify-center">
                                <button
                                    className="w-full bg-ag-primary-light text-center text-white p-2 rounded-xl disabled:bg-zinc-200 m-auto"
                                    type="button"
                                    onClick={() => handleSubmit()}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
        </>
    );
}

export default ProductRequest;