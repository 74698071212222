import ImageCar from "../../../assets/images/logo_b.png";

const QuoteByPhoto = ({ setShowModalRequest }) => {
    return (
        <div className="w-full md:w-auto pb-10 md:pb-0 space-y-5">
            <div className="border border-ag-secondary-light rounded-xl p-5 space-y-3">
                <div className="px-2 py-4">
                    <div className="pb-5">
                        <img className="w-64 h-auto m-auto" src={ImageCar} />
                    </div>
                    <div className="w-[120px] text-center text-ag-secondary-letter m-auto">
                        <span>Not sure of your damage type?</span>
                    </div>
                </div>
                <div>
                    <button
                        className="w-full py-1 border border-ag-primary-light text-sm text-ag-secondary-letter rounded-xl"
                        type="button"
                        onClick={() => setShowModalRequest(true)}
                    >
                        Upload photos for review
                    </button>
                </div>
            </div>
        </div>
    );
}

export default QuoteByPhoto;