import { _delete, _get, _getParamsFree, _post, _postFree, _put, _putFree } from "./http";

export const getQuote = (id) => _get(`/quotes/${id}`);

export const getQuotes = () => _get(`/quotes`);

export const getOptionsToQuote = (id) => _get(`/quotes/${id}/options`);

export const getOptionsToQuoteByPilkington = (id) => _get(`/quotes/${id}/optionsPilkington`);

export const selectProductQuote = (id, quotesObject) => _put(`/quotes/${id}/selectProduct`, quotesObject);

export const cancelQuote = (id, quotesObject) => _put(`/quotes/${id}/cancelQuote`, quotesObject);

export const saveQuote = (quoteObject) => _postFree(`/quotes`, quoteObject);

export const updateAvailability = (idQuote, quoteObject) => _put(`/quotes/${idQuote}/selectProduct/changeAvailability`, quoteObject);


// Retail endpoints

export const getQuotesByRetail = (idRetail) => _get(`/retailUser/${idRetail}/myQuotes`);

export const getHoursNotAvailable = (filterParams) => _getParamsFree(`/appointments/hoursNotAvailable`, filterParams);

export const savePaymentQuoteRetail = (idQuote, quoteObject) => _postFree(`/quotes/${idQuote}/savePayment`, quoteObject);

export const scheduleQuoteRetail = (idQuote, quoteObject) => _postFree(`/quotes/${idQuote}/schedule`, quoteObject);

export const rescheduleQuoteRetail = (idRetail, idAppointment, bodyQuote) => _put(`/retailUser/${idRetail}/myAppointments/${idAppointment}/reschedule`, bodyQuote);

export const shippingOptionQuoteRetail = (idQuote, quoteObject) => _postFree(`/quotes/${idQuote}/shipping`, quoteObject);

export const paymentOptionQuoteRetail = (idRetail, idQuote, quoteObject) => _post(`/retailUser/${idRetail}/myQuotes/${idQuote}/payment`, quoteObject);

export const deleteQuoteRetail = (idRetail, idQuote) => _delete(`/retailUser/${idRetail}/myQuotes/${idQuote}/deleteQuote`);

export const cancelQuoteRetail = (idRetail, idQuote, quotesObject) => _put(`/retailUser/${idRetail}/myQuotes/${idQuote}/cancelQuote`, quotesObject);

export const requestInformation = (quoteObject) => _postFree(`/requestInformation`, quoteObject);