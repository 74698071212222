import ToolIcon from "../../../assets/images/tools_icon.png";

const QuoteByPowerWindowRepair = ({ handleModalReparation }) => {
    return (
        <div className="bg-zinc-100 border-2 border-green-600 rounded-xl p-5 space-y-5">
            <div>
                <img className="w-20 m-auto" src={ToolIcon} />
            </div>
            <div className="w-28 text-center m-auto">
                <span className="text-ag-secondary-letter">
                    Do you want a reparation?
                </span>
            </div>
            <div>
                <button className="w-full py-1 text-sm text-ag-secondary-letter font-semibold border border-green-600 rounded-xl" type="button" onClick={() => handleModalReparation(true)}>
                    Power window repair
                </button>
            </div>
        </div>
    );
}

export default QuoteByPowerWindowRepair;