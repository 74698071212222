import ServiceCheckbox from "./ServiceCheckbox";

const AdditionalServices = ({ searchData, setSearchData }) => (
  <div>
      <div className="text-center pl-0 pb-8">
          <span className="text-ag-secondary-letter">
              Additional Services Upon Request:
          </span>
      </div>
      <ul className="space-y-2">
          <ServiceCheckbox
              label="Ceramic Full Windshield Tint 90% Heat Rejection $250"
              checked={searchData.ceramicFull90}
              onChange={(checked) =>
                  setSearchData({
                      ...searchData,
                      ceramicFull90: checked,
                      ceramicFull70: checked ? false : searchData.ceramicFull70
                  })
              }
          />
          <ServiceCheckbox
              label="Ceramic Full Windshield Tint 70% Heat Rejection $200"
              checked={searchData.ceramicFull70}
              onChange={(checked) =>
                  setSearchData({
                      ...searchData,
                      ceramicFull70: checked,
                      ceramicFull90: checked ? false : searchData.ceramicFull90
                  })
              }
          />
          <ServiceCheckbox
              label="Windshield Water Treatment $50"
              checked={searchData.waterTreatment}
              onChange={(checked) =>
                  setSearchData({ ...searchData, waterTreatment: checked })
              }
          />
          <ServiceCheckbox
              label="Windshield Visor Strip $50"
              checked={searchData.windshieldVisorStrip}
              onChange={(checked) =>
                  setSearchData({ ...searchData, windshieldVisorStrip: checked })
              }
          />
      </ul>
  </div>
);

export default AdditionalServices;