import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { validateInformation } from "../../../../utils/Validators";
import { PatternFormat } from "react-number-format";

const InformationFinalQuoteForm = ({ type, quote, onSave }) => {
    const [information, setInformation] = useState({
        name: "",
        lastNames: "",
        email: "",
        phoneNumber: ""
    });

    const handleChange = (evt) => {
        if (evt.target.name === "name" || evt.target.name === "lastNames") {
            const pattern = new RegExp(/^[A-Za-z\s]+$/g);
            if (pattern.test(evt.target.value) || evt.target.value === "") {
                setInformation({...information, [evt.target.name]: evt.target.value});
            }
        } else if (evt.target.name === "phoneNumber") {
            const pattern = new RegExp(/^[0-9\s]+$/g);
            if (pattern.test(evt.target.value) || evt.target.value === "") {
                setInformation({...information, [evt.target.name]: evt.target.value});
            }
        } else {
            setInformation({...information, [evt.target.name]: evt.target.value});
        }
    }

    const handleChangePhoneNumber = (value) => {
        const pattern = new RegExp(/^[0-9\s]+$/g);
            if (pattern.test(value) || value === "") {
                setInformation({...information, phoneNumber: value});
            }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (information.name !== "" && information.lastNames !== "" && information.email !== "" && information.phoneNumber !== "") {
            const result = validateInformation(information);
            if (result.status) {
                onSave(information);
            } else {
                toast.warning(result.msg, alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        if (quote && quote !== null) {
            setInformation({...information,
                name: quote?.name ? quote?.name : "",
                lastNames: quote?.lastNames ? quote?.lastNames : "",
                email: quote?.email ? quote?.email : "",
                phoneNumber: quote?.phoneNumber ? quote?.phoneNumber : ""
            });
        }
    }, [quote]);

    return (
        <form onSubmit={handleSubmit}>
            <div className="py-5 border-t-2 border-b-2 border-ag-primary">
                <div>
                    <div className="text-center">
                        <label className="text-base text-ag-secondary-letter">Name:</label>
                    </div>
                    <input
                        className="w-full text-center text-ag-secondary-letter border border-gray-500 pl-1 pr-3 py-2 rounded-xl disabled:bg-zinc-200 truncate"
                        type="text"
                        name="name"
                        placeholder="Name"
                        onChange={handleChange}
                        value={information.name}
                        maxLength={50}
                        disabled={type === "user" ? true : false}
                    />
                </div>
                <div>
                    <div className="text-center">
                        <label className="text-base text-ag-secondary-letter">Last Name:</label>
                    </div>
                    <input
                        className="w-full text-center text-ag-secondary-letter border border-gray-500 pl-1 pr-3 py-2 rounded-xl disabled:bg-zinc-200 truncate"
                        type="text"
                        name="lastNames"
                        placeholder="Last Names"
                        onChange={handleChange}
                        value={information.lastNames}
                        maxLength={50}
                        disabled={type === "user" ? true : false}
                    />
                </div>
                <div>
                    <div className="text-center">
                        <label className="text-base text-ag-secondary-letter">Email:</label>
                    </div>
                    <input
                        className="w-full text-center text-ag-secondary-letter border border-gray-500 pl-1 pr-3 py-2 rounded-xl disabled:bg-zinc-200 truncate"
                        type="text"
                        name="email"
                        placeholder="Email"
                        onChange={handleChange}
                        value={information.email}
                        maxLength={70}
                        disabled={type === "user" ? true : false}
                    />
                </div>
                <div>
                    <div className="text-center">
                        <label className="text-base text-ag-secondary-letter">Phone Number:</label>
                    </div>
                    <PatternFormat 
                        className="w-full text-center text-ag-secondary-letter border border-gray-500 pl-1 pr-3 py-2 rounded-xl disabled:bg-zinc-200 truncate" 
                        format="(###)###-####"
                        name="phoneNumber" 
                        placeholder="Phone Number" 
                        value={information.phoneNumber}
                        disabled={type === "user" ? true : false}
                        onValueChange= {(values, _) =>  handleChangePhoneNumber(values.value)}
                    />
                    {/* <input
                        className="w-full text-center text-ag-secondary-letter border border-gray-500 pl-1 pr-3 py-2 rounded-xl disabled:bg-zinc-200 truncate"
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        onChange={handleChange}
                        value={information.phoneNumber}
                        maxLength={10}
                        disabled={type === "user" ? true : false}
                    /> */}
                </div>
            </div>
            <div className="flex justify-between items-center pt-5">
                <button
                    className="w-full bg-ag-primary-light text-white px-3 py-2 rounded-xl disabled:bg-zinc-200"
                    type="submit"
                >
                    Continue
                </button>
            </div>
        </form>
    );
}

export default InformationFinalQuoteForm;