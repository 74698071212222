import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getQuote, shippingOptionQuoteRetail } from "../../../../services/Quotes";
import { PermitsContext } from "../../../../services/Permits";
import { FiChevronLeft } from "react-icons/fi";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { getWarehouses } from "../../../../services/Warehouse";
import Loader from "../../../../components/loader/Loader";
import NavbarClients from "../../../../components/navbar/NavbarClients";
import SidebarClients from "../../../../components/sidebar/SidebarClients";
import InformationQuote from "../details/InformationQuote";
import FormShippingMethod from "./FormShippingMethod";
import NewAddress from "../../../fragments/NewAddress";


const ShippingMethodQuote = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { dataSession } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [isRefreshAddress, setIsRefreshAddress] = useState(false);
    const [quote, setQuote] = useState(null);

    const handleShippingMethod = (shippingObject) => {
        if (shippingObject.shippingMethod === "SHIP") {
            getWarehouses().then(res => {
                if (res.status === 200) {
                    const warehousesAvailable = res.data.filter(element => !element?.deleted);
                    const foundWarehouse = warehousesAvailable[Math.floor(Math.random() * warehousesAvailable.length)];
                    setQuote({...quote,
                        shippingMethod: shippingObject.shippingMethod,
                        costShipping: shippingObject.costShipping,
                        fromWarehouse: { _id: foundWarehouse?._id },
                        userAddress: { _id: shippingObject.id },
                        address: shippingObject.address,
                    });
                }
            });
        } else {
            setQuote({...quote,
                shippingMethod: shippingObject.shippingMethod,
                costShipping: shippingObject.costShipping,
                fromWarehouse: { _id: shippingObject.idWarehouse },
                warehouseAddress: { _id: shippingObject.id },
                address: shippingObject.address
            });
        }
    }
    
    const handleContinue = () => {
        if (quote.shippingMethod !== "" && quote.costShipping >= 0) {
            setLoader(true);
            const body = {
                shippingMethod: quote.shippingMethod,
                costShipping: quote.costShipping,
                fromWarehouse: quote.fromWarehouse
            }

            if (quote.shippingMethod === "SHIP") {
                body.userAddress = quote.userAddress;
            } else {
                body.warehouseAddress = quote.warehouseAddress;
            }

            shippingOptionQuoteRetail(params.id, body).then(res => {
                if (res.status === 200) {
                    setLoader(false);
                    navigate(`/quotes/detail/${params.id}/schedule`, { state: quote });
                } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                }
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        } else {
            toast.warning("Please fill out service address to complete your quote", alertOptions);
        }
    }

    const handleResult = () => {
        setShowModal(false);
        setLoader(false);
        setIsRefreshAddress(true);
        toast.success("Address saved successfully", alertOptions);
    }

    useEffect(() => {
        if (location.state) {
            setLoader(true);
            const quoteValue = location.state;
            if (quoteValue?.searchType === "photos" && quoteValue?.paymentMethod === "CAR_INSURANCE") {
                setQuote({...quoteValue,
                    numberOfInsurance: quoteValue?.insurance?.numberOfInsurance,
                    companyInsurance: quoteValue?.insurance?.companyInsurance,
                    dateInsurance: quoteValue?.insurance?.dateInsurance
                });
                setLoader(false);
            } else {
                setQuote(quoteValue);
                setLoader(false);
            }
        } else {
            navigate("/");
        }
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showModal ?
                    <NewAddress
                        idUser={dataSession._id}
                        typeUser="retail"
                        onClose={() => setShowModal(false)}
                        onLoader={(sts) => setLoader(sts)}
                        onResult={() => handleResult()}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 p-5 md:p-8">
                            <div className="grid grid-cols-12 px-0 md:px-5">
                                <div>
                                    <button className="p-1 rounded-md" type="button" onClick={() => navigate(-1)}>
                                        <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                    </button>
                                </div>
                                <div className="flex justify-center items-center col-span-11 text-center">
                                    <h1 className="text-xl text-ag-secondary md:mr-[8%]">Mobile Service</h1>
                                </div>
                            </div>
                            <div className="pt-3 lg:flex lg:justify-center lg:items-start lg:space-x-10 space-y-5 lg:space-y-0">
                                <div className="w-full lg:w-[65%]">
                                    <FormShippingMethod
                                        idRetail={dataSession._id}
                                        quoteDetail={quote}
                                        onModal={() => setShowModal(true)}
                                        onMethod={handleShippingMethod}
                                        onRefreshAddress={isRefreshAddress}
                                        onResponseRefreshAddress={(sts) => setIsRefreshAddress(sts)}
                                    />
                                </div>
                                <div className="w-full lg:w-[35%]">
                                    <InformationQuote quote={quote} onContinue={handleContinue} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ShippingMethodQuote;