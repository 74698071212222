import { Link } from "react-router-dom";
import Logo from "../../assets/images/logo_b.png";

const NavbarFree = () => {
    return (
        <div className="relative fixed">
            <div className="bg-ag-secondary-light">
                <div className="mx-auto px-[25px] py-[6px]">
                    <div className="flex justify-between items-center">
                        <div className="flex justify-between items-center">
                            <div className="pl-5">
                                <img className="h-auto w-[90px] md:w-[153px] m-auto" src={Logo} />
                            </div>
                        </div>
                        <div className="flex items-center justify-end space-x-3 md:space-x-7 pr-0 md:pr-10">
                            <Link to="/">
                                <button className="text-white hover:text-zinc-600" type="button">
                                    <span className="text-lg">Log In</span>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavbarFree;