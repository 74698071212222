import { IoMdImage } from "react-icons/io";
import { formatAMPM, formatCurrency, formatDateEng } from "../../../utils/Utilities";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const statusAppointment = {
    ATTEND: { text: "Approved", textColor: "text-ag-secondary-letter", bgColor: "bg-green-600", borderColor: "border-green-600" },
    approved: { text: "Approved", textColor: "text-ag-secondary-letter", bgColor: "bg-green-600", borderColor: "border-green-600" },
    canceled: { text: "Canceled", textColor: "text-ag-primary-light", bgColor: "bg-ag-primary-light", borderColor: "border-ag-primary-light" },
    inprocess: { text: "In process", textColor: "text-ag-secondary-letter", bgColor: "bg-ag-secondary", borderColor: "border-ag-secondary" },
    INPROCESS: { text: "In process", textColor: "text-ag-secondary-letter", bgColor: "bg-ag-secondary", borderColor: "border-ag-secondary" }
}

const typeQuote = {
    POWER_WINDOW_REPAIR: "Power window repair",
    WINDOW_TINT: "Window Tint"
}

const typePaymetMethod = {
    PAY_ON_MY_WON: "Pay on my own",
    CAR_INSURANCE: "Car insurance"
}

const QuoteRepair = ({ quote, onCancel, onDelete }) => {
    const navigate = useNavigate();
    const [canRC, setCanRC] = useState(false);

    const handleReschedule = () => {
        const newModelQuote = {
            ...quote,
            _idAppointment: quote?._id,
            payment: quote?.payment,
            costShipping: quote?.payment?.costShipping,
            paymentMethod: quote?.payment?.paymentMethod,
            shippingMethod: quote?.payment?.shippingMethod,
            total: quote?.payment?.total,
            warehouseAddress: quote?.payment?.warehouseAddress,
            userAddress: quote?.payment?.userAddress,
            address: quote?.payment?.userAddress ? quote?.payment?.userAddress : quote?.payment?.warehouseAddress,
            installationType: quote?.installationType,
            warehouse: quote?.warehouse
        };

        navigate(`/quotes/detail/${quote._id}/reschedule`, { state: newModelQuote });
    }

    useEffect(() => {
        if (quote && quote !== null) {
            let d = new Date(quote.createdAt);
            d.setMinutes(d.getMinutes() + d.getTimezoneOffset());

            const diasTranscurridos = Math.floor((new Date() - d) / (1000 * 60 * 60 * 24));
            if (diasTranscurridos > 0) setCanRC(true);
        }
    }, [quote]);

    return (
        <>
            <div className="w-full lg:flex lg:justify-between xl:items-center p-3 md:p-0 border border-ag-secondary-light rounded-xl">
                <div className="w-full lg:w-[15%] flex justify-center items-center text-center text-ag-secondary-letter">
                    <span className="text-[70px] md:text-[60px] xl:text-[70px]"><IoMdImage /></span>
                </div>
                <div className="w-full lg:w-[35%] text-base md:text-xs lg:text-base relative h-[232px]">
                    <div className="px-3 py-8 text-ag-secondary-letter">
                        <ul>
                            <li className="flex justify-start items-center space-x-2">
                                <span className="font-semibold">Type:</span>
                                <p className="truncate">{quote?.type ? typeQuote[quote?.type] : "-----"}</p>
                            </li>
                            <li className="flex justify-start items-center space-x-2">
                                <span className="font-semibold">Description:</span>
                                <p className="truncate" title={quote?.description ? quote?.description : quote?.anotherQuote ? quote?.anotherQuote?.description : "-----"}>
                                    {quote?.description ? quote?.description : quote?.anotherQuote ? quote?.anotherQuote?.description : "-----"}
                                </p>
                            </li>
                            <li className="flex justify-start items-center space-x-2">
                                <span className="font-semibold">Year:</span>
                                <p className="truncate">{quote?.year ? quote?.year : quote?.anotherQuote ? quote?.anotherQuote?.year : "-----"}</p>
                            </li>
                            <li className="flex justify-start items-center space-x-2">
                                <span className="font-semibold">Make:</span>
                                <p className="truncate">{quote?.brand ? quote?.brand : quote?.anotherQuote ? quote?.anotherQuote?.brand : "-----"}</p>
                            </li>
                            <li className="flex justify-start items-center space-x-2">
                                <span className="font-semibold">Model:</span>
                                <p className="truncate">{quote?.model ? quote?.model : quote?.anotherQuote ? quote?.anotherQuote?.model : "-----"}</p>
                            </li>
                            <li className="flex justify-start items-center space-x-2">
                                <span className="font-semibold">Style:</span>
                                <p className="truncate">{quote?.style ? quote?.style : quote?.anotherQuote ? quote?.anotherQuote?.style : "-----"}</p>
                            </li>
                            {
                                quote?.anotherQuote ?
                                    <>
                                        <li className="flex justify-start items-center space-x-2">
                                            <span className="font-semibold">Style:</span>
                                            <p className="truncate">{quote.anotherQuote?.style ? quote.anotherQuote?.style : "-----"}</p>
                                        </li>
                                        <li className="flex justify-start items-center space-x-2">
                                            <span className="font-semibold">Warehouse:</span>
                                            <p className="truncate" title={quote.warehouse?.name ? quote.warehouse?.name : "-----"}>
                                                {
                                                    quote?.installationType === "WAREHOUSESERVICE" ?
                                                        quote.warehouse?.name ? quote.warehouse?.name : "-----"
                                                        : <span className="italic">Home services</span>
                                                }
                                            </p>
                                        </li>
                                    </>
                                    : null
                            }
                        </ul>
                    </div>
                    <div className={`absolute bottom-0 w-full flex justify-center space-x-3 px-3 py-0 text-white border ${statusAppointment[quote?.status]?.borderColor} ${statusAppointment[quote?.status]?.bgColor}`}>
                        <span>{statusAppointment[quote?.status]?.text}</span>
                    </div>
                </div>
                <div className="w-full lg:w-[35%] text-base md:text-xs lg:text-base text-ag-secondary-letter relative h-[232px]">
                    <div className={`px-3 ${quote?.anotherQuote ? "py-11" : "py-10"} text-ag-secondary-letter`}>
                        {
                            quote?.anotherQuote ?
                                <>
                                    <li className="flex justify-start items-center space-x-2">
                                        <span className="font-semibold">Date:</span>
                                        <p className="truncate">{quote?.date ? formatDateEng(quote?.date) : "---"}</p>
                                    </li>
                                    <li className="flex justify-start items-center space-x-2">
                                        <span className="font-semibold">Hour:</span>
                                        <p className="truncate">{quote?.date ? formatAMPM(quote?.date) : "---"}</p>
                                    </li>
                                    <li className="flex justify-start items-center space-x-2">
                                        <span className="font-semibold">Price:</span>
                                        <span>
                                            {
                                                quote?.total ?
                                                    formatCurrency(quote?.total)
                                                    : "---"
                                            }
                                        </span>
                                    </li>
                                </>
                                :
                                <div className="px-3 py-5 text-ag-secondary-letter"></div>
                        }
                        <li className="flex justify-start items-center space-x-2">
                            <span className="font-semibold">Payment method:</span>
                            <p className="truncate">{quote?.paymentMethod ? typePaymetMethod[quote?.paymentMethod] : "-----"}</p>
                        </li>
                        <br />
                        {
                            quote?.anotherQuote ?
                                <>
                                    <li className="flex justify-start items-center space-x-2">
                                        <p className="font-semibold">Address:</p>
                                        <p className="truncate w-full md:w-36 lg:w-full" title={quote?.payment?.warehouseAddress ?
                                            `${quote.payment?.warehouseAddress?.street},` : `${quote.payment?.userAddress?.street},`
                                        }>
                                            {
                                                quote?.payment?.warehouseAddress ?
                                                    `${quote.payment?.warehouseAddress?.street}, `
                                                    :
                                                    `${quote.payment?.userAddress?.street},`
                                            }
                                            {
                                                quote?.payment?.warehouseAddress ?
                                                    `${quote.payment?.warehouseAddress?.locality} ${quote.payment?.warehouseAddress?.postalCode}`
                                                    :
                                                    `${quote.payment?.userAddress?.city} ${quote.payment?.userAddress?.postalCode}`
                                            }

                                        </p>
                                    </li>
                                    <li className="flex justify-start items-center space-x-2">
                                        <p className="truncate w-full md:w-36 lg:w-full" title={quote?.payment?.warehouseAddress ?
                                            `${quote.payment?.warehouseAddress?.locality} ${quote.payment?.warehouseAddress?.postalCode}`
                                            : `${quote.payment?.userAddress?.city} ${quote.payment?.userAddress?.postalCode}`
                                        }> <br />
                                        </p>
                                    </li>
                                </>
                                :
                                <div className="px-3 py-5 text-ag-secondary-letter"></div>
                        }
                    </div>
                    <div className={`hidden md:block flex justify-center space-x-3 px-3 py-0 text-transparent border absolute bottom-0 w-full ${statusAppointment[quote?.status]?.borderColor} ${statusAppointment[quote?.status]?.bgColor}`}>
                        <span>...</span>
                    </div>
                </div>
                <div className="w-full lg:w-[15%] flex justify-center items-center md:block space-y-0 md:space-y-1 p-5">
                    {
                        quote?.status === "INPROCESS" && !canRC ?
                            <button className="w-full px-2 xl:px-4 py-2 bg-ag-secondary text-base md:text-xs xl:text-base text-white rounded-xl" type="button" onClick={() => handleReschedule()}>
                                Reschedule
                            </button>
                            :
                            null
                    }
                    {/* <button className="w-full px-2 xl:px-4 py-2 bg-ag-secondary text-base md:text-xs xl:text-base text-white rounded-xl" type="button"  onClick={() => navigate(`/quotes/detail/${quote._id}/shipping`, {state: quote})}>
                        Schedule
                    </button> */}
                    <button className="w-full px-2 xl:px-4 py-2 bg-ag-secondary text-base md:text-xs xl:text-base text-white rounded-xl" type="button" onClick={() => navigate(`/quotes/power-window-repair/window-tint/detail/${quote._id}/shipping`, { state: quote })}>
                        Schedule
                    </button>
                    {/* {
                        quote.type === 'POWER_WINDOW_REPAIR' ?
                            <button className="w-full px-2 xl:px-4 py-2 bg-ag-secondary text-base md:text-xs xl:text-base text-white rounded-xl" type="button" onClick={() => navigate(`/quotes/choice_of_power_window_repair/${quote._id}`, { state: quote })}>
                                Schedule
                            </button>
                            :
                            <button className="w-full px-2 xl:px-4 py-2 bg-ag-secondary text-base md:text-xs xl:text-base text-white rounded-xl" type="button" onClick={() => navigate(`/quotes/choice_of_window_tint/${quote._id}`, { state: quote })}>
                                Schedule
                            </button>
                    } */}
                    {/* {
                        quote?.status === "inprocess" ?
                            <button className="w-full px-2 xl:px-4 py-2 bg-ag-primary-light text-base md:text-xs xl:text-base text-white rounded-xl" type="button" onClick={() => onCancel(quote._id)}>
                                Cancel
                            </button>
                        :
                            null
                    }
                    {
                        quote?.status === "canceled" ?
                            <button className="w-full px-2 xl:px-4 py-2 bg-ag-primary-light text-base md:text-xs xl:text-base text-white rounded-xl" type="button" onClick={() => onDelete(quote._id)}>
                                Delete
                            </button>
                        :
                            null
                    } */}
                </div>
            </div>
        </>
    );
}

export default QuoteRepair;