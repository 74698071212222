import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../utils/Utilities";
import ReactDatePicker from "react-datepicker";

const NewInsurance = ({ insuranceInfo, onClose, onSave, onUpdate }) => {
    const [insuranceData, setInsuranceData] = useState({
        _id: "",
        numberOfInsurance: "",
        companyInsurance: "",
        dateInsurance: ""
    });

    const handleChange = (evt) => {
        if (evt.target.name === "numberOfInsurance" && String(evt.target.value).match(/^[a-z0-9]*$/i) === null) return;
        setInsuranceData({...insuranceData, [evt.target.name]: evt.target.value});
    }

    const handleChangeDateInsurance = (valDate) => {
        if (new Date() <= new Date(valDate)) {
            toast.warning("Loss date must higher than today's date", alertOptions);
            return;
        }
        setInsuranceData({...insuranceData, dateInsurance: valDate});
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (insuranceData.numberOfInsurance !== "" && insuranceData.companyInsurance !== "" && insuranceData.dateInsurance !== "") {
            const insuranceModel = {
                numberOfInsurance: insuranceData.numberOfInsurance,
                companyInsurance: insuranceData.companyInsurance,
                dateInsurance: insuranceData.dateInsurance
            }
            if (insuranceData._id !== "") {
                onUpdate({...insuranceModel, _id: insuranceData._id});
            } else {
                onSave(insuranceModel);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        if (insuranceInfo !== null) {
            setInsuranceData({...insuranceData,
                _id: insuranceInfo?._id,
                numberOfInsurance: insuranceInfo?.numberOfInsurance,
                companyInsurance: insuranceInfo?.companyInsurance,
                dateInsurance: new Date(insuranceInfo?.dateInsurance)
            });
        }
    }, [insuranceInfo]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[600px]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold ml-[5%]">
                                        Edition of Insurance Information
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-2 md:px-5 pb-0 pt-5">
                                <form className="space-y-3" onSubmit={handleSubmit}>
                                    <div className="space-y-2">
                                        <div>
                                            <div className="text-center">
                                                <label className="text-base text-ag-secondary-letter">Policy number:</label>
                                            </div>
                                            <input
                                                className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                                name="numberOfInsurance"
                                                type="text"
                                                placeholder="Policy number"
                                                onChange={handleChange}
                                                value={insuranceData.numberOfInsurance}
                                            />
                                        </div>
                                        <div className="grid gap-3 md:grid-cols-2">
                                            <div>
                                                <div className="text-center">
                                                    <label className="text-base text-ag-secondary-letter">Insurance Company:</label>
                                                </div>
                                                <input
                                                    className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                                    name="companyInsurance"
                                                    type="text"
                                                    placeholder="Insurance Company"
                                                    onChange={handleChange}
                                                    value={insuranceData.companyInsurance}
                                                />
                                            </div>
                                            <div className="customDatePickerWidth">
                                                <div className="text-center">
                                                    <label className="text-base text-ag-secondary-letter">Date of lost {'(mm/dd/yyyy)'}:</label>
                                                </div>
                                                <ReactDatePicker
                                                    className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                                    placeholderText="(mm/dd/yyyy)"
                                                    name="dateInsurance"
                                                    selected={insuranceData.dateInsurance}
                                                    onChange={(dateVal) => handleChangeDateInsurance(dateVal)}
                                                    onKeyDown={(e) => e?.key !== "Backspace" ? e.preventDefault() : null}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full flex justify-center">
                                        <button
                                            className="w-full bg-ag-primary-light text-center text-sm text-white p-2 rounded-xl disabled:bg-zinc-200 m-auto"
                                            type="submit"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

export default NewInsurance;