import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { validateFormReparation } from "../../../utils/Validators";
import { savePowerWindowRepair } from "../../../services/PowerWindowRepair";

const FormWindowTint = ({ information, informationData, onClose, onSuccess, onLoader }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [data, setData] = useState({
        type: "WINDOW_TINT",
        names: "",
        lastNames: "",
        email: "",
        phoneNumber: "",
        description: "",
        vin: "",
        year: "",
        brand: "",
        model: "",
        style: "",
        typeGlass: ""
    });

    const handleChange = (evt) => {
        if (evt.target.name === "phoneNumber") {
            const pattern = new RegExp(/^[0-9\s]+$/g);
            if (pattern.test(evt.target.value) || evt.target.value === "") {
                setData({...data, [evt.target.name]: evt.target.value});
            }
        } else {
            setData({...data, [evt.target.name]: evt.target.value});
        }
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (data.names !== "" && data.lastNames !== "" && data.phoneNumber !== "" && data.email !== "" && data.description !== "" && data.year !== "" && data.brand !== "" && data.model !== "" && data.style !== "") {
            onLoader(true);
            const result = validateFormReparation(data);
            if (result.status) {
                savePowerWindowRepair(data).then(res => {
                    if (res.status === 200) {
                        onLoader(false);
                        setData({...data,
                            type: "WINDOW_TINT",
                            names: "",
                            lastNames: "",
                            email: "",
                            phoneNumber: "",
                            vin: "",
                            year: "",
                            brand: "",
                            model: "",
                            style: "",
                            typeGlass: "",
                            description: ""
                        });
                        onSuccess(true);
                    } else {
                        onLoader(false);
                        toast.warning(res.response.data.message, alertOptions);
                    }
                }).catch((error) => {
                    onLoader(false);
                    toast.warning(error.response.data.message, alertOptions);
                });
            } else {
                onLoader(false);
                toast.warning(result.msg, alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        if (informationData?.names !== "" && informationData?.lastNames !== "" && informationData?.email !== "") {
            setIsDisabled(true);
        }

        setData({...data,
            type: "WINDOW_TINT",
            names: informationData?.names,
            lastNames: informationData?.lastNames,
            email: informationData?.email,
            phoneNumber: informationData?.phoneNumber,
            year: information.year,
            brand: information.brand,
            model: information.model,
            style: information.style,
            typeGlass: information.typeGlass,
            vin: information.vin
        });
    }, [informationData]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none mb-0 md:mb-5 xl:mb-0">
                    <div className="w-[320px] md:w-[400px] lg:w-[500px] h-auto md:h-[500px] xl:h-auto">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold pl-[5%]">
                                        Window Tint
                                    </span>
                                </div>

                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>

                            <div className="px-2 md:px-5 pb-3 pt-5 h-[300px] md:h-auto overflow-auto">
                                <div className="space-y-1">
                                    <div className="px-2 text-ag-secondary-letter text-sm">
                                        <span>
                                            In order to continue with the quote for a window tint service, please provide the following 
                                            information.
                                        </span>
                                    </div>

                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Name:</label>
                                        </div>

                                        <input
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            name="names"
                                            type="text"
                                            placeholder="Name"
                                            onChange={handleChange}
                                            value={data.names}
                                            disabled={isDisabled}
                                        />
                                    </div>

                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Last Name:</label>
                                        </div>

                                        <input
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            name="lastNames"
                                            type="text"
                                            placeholder="Last Name"
                                            onChange={handleChange}
                                            value={data.lastNames}
                                            disabled={isDisabled}
                                        />
                                    </div>

                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Email:</label>
                                        </div>

                                        <input
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            name="email"
                                            type="text"
                                            placeholder="Email"
                                            onChange={handleChange}
                                            value={data.email}
                                            disabled={isDisabled}
                                        />
                                    </div>

                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Phone Number:</label>
                                        </div>

                                        <input
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            name="phoneNumber"
                                            type="text"
                                            placeholder="Phone Number"
                                            onChange={handleChange}
                                            value={data.phoneNumber}
                                            maxLength={10}
                                            disabled={isDisabled}
                                        />
                                    </div>

                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Description:</label>
                                        </div>

                                        <textarea
                                            className="overflow-y-hidden w-full min-h-20 text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200"
                                            name="description"
                                            type="text"
                                            placeholder="Description"
                                            onChange={handleChange}
                                            value={data.description}
                                            onInput={(e) => {
                                                e.target.style.height = "auto";
                                                e.target.style.height = e.target.scrollHeight + "px";
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="px-2 md:px-5 w-full flex justify-center">
                                <button
                                    className="w-full bg-ag-primary-light text-center text-white p-2 rounded-xl disabled:bg-zinc-200 m-auto"
                                    type="button"
                                    onClick={(evt) => handleSubmit(evt)}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
        </>
    );
}

export default FormWindowTint;