import { useContext, useEffect, useState } from "react";
import { fileUpload } from "../../../../services/File";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { validateCarInsuranceAux, validateQuote } from "../../../../utils/Validators";
import { saveQuote } from "../../../../services/Quotes";
import { useNavigate } from "react-router-dom";
import { PermitsContext } from "../../../../services/Permits";
import NavbarClients from "../../../../components/navbar/NavbarClients";
import SidebarClients from "../../../../components/sidebar/SidebarClients";
import StepFourForm from "./StepFourForm";
import ModalCalculateRetail from "../ModalCalculateRetail";
import QuoteInProcessConfirm from "../QuoteInProcessConfirm";
import Loader from "../../../../components/loader/Loader";

const StepFour = () => {
    const navigate = useNavigate();
    const { dataSession, setDataSession, searchData, setSearchData, clearQuote } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showModalInProcessPP, setShowModalInProcessPP] = useState(false);
    const [showModalCalculate, setShowModalCalculate] = useState({ status: false, itemReq: null, itemResp: null });

    const [paymentMethods] = useState([
        { label: "Pay with Insurance (Free Full Ceramic Windshield Tint)", value: "CAR_INSURANCE", style: "font-semibold" },
        { label: "Pay on my own", value: "PAY_ON_MY_WON", style: "" }
    ]);

    const handleSaveQuote = (quoteObject) => {
        saveQuote(quoteObject).then(res => {
            if (res.status === 200) {
                if (quoteObject?.paymentMethod === "CAR_INSURANCE") {
                    let infoUserAux = JSON.parse(window.localStorage.getItem("user-data"));
                    localStorage.setItem("user-data", JSON.stringify({...infoUserAux,
                        numberOfInsurance: quoteObject.numberOfInsurance,
                        companyInsurance: quoteObject.companyInsurance,
                        dateInsurance: quoteObject.dateInsurance
                    }));

                    setDataSession({...dataSession,
                        numberOfInsurance: quoteObject.numberOfInsurance,
                        companyInsurance: quoteObject.companyInsurance,
                        dateInsurance: quoteObject.dateInsurance
                    });
                }

                if (quoteObject?.searchType === "specifications" && quoteObject?.selectProduct?.total > 0) {
                    let modelRes = res.data;
                    if (quoteObject?.paymentMethod === "CAR_INSURANCE") {
                        modelRes.numberOfInsurance = quoteObject.numberOfInsurance;
                        modelRes.companyInsurance = quoteObject.companyInsurance;
                        modelRes.dateInsurance = quoteObject.dateInsurance;

                        setDataSession({...dataSession,
                            numberOfInsurance: quoteObject.numberOfInsurance,
                            companyInsurance: quoteObject.companyInsurance,
                            dateInsurance: quoteObject.dateInsurance
                        });
                    }
                    
                    setLoader(false);
                    navigate(`/quotes/detail/${res.data?._id}/shipping`, { state: modelRes });
                } else {
                    toast.success("Quote saved successfully", alertOptions);
                    setShowModalInProcessPP(true);
                    clearQuote();
                    setLoader(false);
                }
            } else {
                setLoader(false);
                toast.warning(res.response.data.message ? res.response.data.message : "There was a problem saving the quote. Please try again in a few minutes", alertOptions);
            }
        }).catch((error) => {
            toast.warning(error.response.data.message ? error.response.data.message : "There was a problem saving the quote. Please try again in a few minutes", alertOptions);
        })
    }

    const handleSubmitAux = (quoteObject, quoteObjAux) => {
        setLoader(true);
        let newQuoteObj = quoteObject;
        // newQuoteObj.installationType = "WAREHOUSESERVICE";
        newQuoteObj.selectProduct = {
            total: quoteObjAux.total,
            tax: quoteObjAux.tax,
            nag: quoteObject.nag?.nag
        }

        handleSaveQuote(newQuoteObj);
    }

    const handleSubmit = async (searchModel) => {
        if (
            searchModel.paymentMethod !== "" && searchModel.paymentMethod !== "default" && (searchModel.paymentMethod !== "CAR_INSURANCE" || 
            (searchModel.paymentMethod === "CAR_INSURANCE" && searchModel.numberOfInsurance !== "" && 
            searchModel.companyInsurance !== "" && searchModel.dateInsurance !== ""))
        ) {
            if (
                searchModel.searchType !== "photos" && searchModel.vin === "" && searchModel.year === "" && 
                searchModel.brand === "" && searchModel.model === "" && searchModel.style === "" && searchModel.typeGlass === ""
            ) {
                toast.info("Please fill in all the fields of the form", alertOptions);
            } else if (searchModel.searchType === "photos" && searchModel.description === "" && searchModel.photos.length === 0) {
                toast.info("Please fill in all the fields of the form", alertOptions);
            } else {
                setLoader(true);
                let quoteObject = {
                    paymentMethod: searchModel.paymentMethod,
                    numberOfInsurance: searchModel.numberOfInsurance,
                    companyInsurance: searchModel.companyInsurance,
                    dateInsurance: searchModel.dateInsurance,
                    comments: searchModel.comments,
                    typeService: searchModel.typeService
                };

                if (searchModel.searchType === "photos") {
                    // Upload file
                    let quoteValidImages = [];
                    for (let i = 0; i < searchModel.photos.length; i++) {
                        if (searchModel.photos[i].file !== null && searchModel.photos[i].name !== "") {
                            let bodyFormData = new FormData();
                            bodyFormData.append('file', searchModel.photos[i].file);
                            const valueUpload = await fileUpload(bodyFormData).then(res => {
                                if (res.status === 200) return res.data;
                                return "";
                            });
                            quoteValidImages.push(valueUpload);
                        } else if (searchModel.photos[i].name !== "") {
                            quoteValidImages.push(searchModel.photos[i].name);
                        }
                    }

                    quoteObject.photos = quoteValidImages;
                    quoteObject.description = searchModel.description;
                    quoteObject.searchType = searchModel.searchType;
                    quoteObject.names = searchModel.names;
                    quoteObject.lastNames = searchModel.lastNames;
                    quoteObject.phoneNumber = searchModel.phoneNumber;
                    quoteObject.email = searchModel.email;
                    if (quoteObject.paymentMethod === "CAR_INSURANCE") {
                        quoteObject.insurance = {
                            numberOfInsurance: searchModel.numberOfInsurance,
                            companyInsurance: searchModel.companyInsurance,
                            dateInsurance: searchModel.dateInsurance,
                        }
                    }
                } else {
                    quoteObject.searchType = "specifications";
                    quoteObject.vin = searchModel.vin;
                    quoteObject.year = searchModel.year;
                    quoteObject.brand = searchModel.brand;
                    quoteObject.model = searchModel.model;
                    quoteObject.style = searchModel.style;
                    quoteObject.typeGlass = searchModel.typeGlass;
                    quoteObject.windshieldVisorStrip = searchModel.windshieldVisorStrip;
                    quoteObject.ceramicFull70 = searchModel.ceramicFull70;
                    quoteObject.ceramicFull90 = searchModel.ceramicFull90;
                    quoteObject.waterTreatment = searchModel.waterTreatment;
                    quoteObject.installationType = searchModel.installationType;
                    quoteObject.nag = searchModel.nag;
                }

                if (quoteObject.paymentMethod === "CAR_INSURANCE") {
                    if (new Date() <= new Date(quoteObject.dateInsurance)) {
                        toast.warning("Loss date must higher than today's date", alertOptions);
                        setLoader(false);
                        return;
                    }

                    const resultCar = validateCarInsuranceAux(quoteObject);
                    if (!resultCar.status) {
                        toast.warning(resultCar.msg, alertOptions);
                        setLoader(false);
                        return 0;
                    }
                }

                const result = validateQuote(quoteObject);
                if (result.status) {
                    if (quoteObject.searchType === "photos") {
                        handleSaveQuote(quoteObject);
                    } else {
                        setShowModalCalculate({...showModalCalculate,
                            status: true,
                            itemReq: searchModel.nag,
                            itemResp: quoteObject
                        });
                        setLoader(false);
                    }
                } else {
                    setLoader(false);
                    toast.warning(result.msg, alertOptions);
                }
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        setSearchData({...searchData,
            numberOfInsurance: dataSession.numberOfInsurance ? dataSession.numberOfInsurance : "",
            companyInsurance: dataSession.companyInsurance ? dataSession.companyInsurance : "",
            dateInsurance: dataSession.dateInsurance !== "" ? new Date(dataSession.dateInsurance) : "",
            names: dataSession.names ? dataSession.names : "",
            lastNames: dataSession.lastNames ? dataSession?.lastNames : "",
            email: dataSession.email ? dataSession.email : "",
            phoneNumber: dataSession.phoneNumber ? dataSession.phoneNumber : ""
        });
    }, [dataSession]);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showModalCalculate.status ?
                    <ModalCalculateRetail
                        itemReq={showModalCalculate.itemReq}
                        itemResp={showModalCalculate.itemResp}
                        onClose={() => setShowModalCalculate({...showModalCalculate, status: false, itemReq: null, itemResp: null})}
                        onSelect={(otherResp) => handleSubmitAux(showModalCalculate.itemResp, otherResp)}
                    />
                : null
            }

            {
                showModalInProcessPP ?
                    <QuoteInProcessConfirm onClose={() => navigate("/appointments")} />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-16 md:mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="px-5 xl:px-28 2xl:px-40 pt-3">
                                <StepFourForm type="user" paymentMethods={paymentMethods} handleSubmit={handleSubmit} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StepFour;