import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../services/Permits";
import { getRetailUser, updateRetailUser } from "../../../services/Retail";
import { saveInsuranceRetail, updateInsuranceRetail } from "../../../services/RetailInsurance";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import Loader from "../../../components/loader/Loader";
import NavbarClients from "../../../components/navbar/NavbarClients";
import SidebarClients from "../../../components/sidebar/SidebarClients";
import ProfileView from "../../fragments/profile/ProfileView";

const Profile = () => {
    const { dataSession, setDataSession } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [profile, setProfile] = useState(null);

    const getData = () => {
        getRetailUser(dataSession._id).then(res => {
            if (res.status === 200 && !Array.isArray(res.data)) setProfile(res.data);
            setLoader(false);
        });
    }

    const handleSave = (insureModel) => {
        setLoader(true);
        saveInsuranceRetail(dataSession._id, insureModel).then(res => {
            if (res.status === 200) {
                getData();
                toast.success("Insurance saved successfully", alertOptions);
                setIsSuccess(true);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleEdit = (insureModel) => {
        setLoader(true);
        updateInsuranceRetail(dataSession._id, insureModel?._id, insureModel).then(res => {
            if (res.status === 200) {
                let infoUserAux = JSON.parse(window.localStorage.getItem("user-data"));
                localStorage.setItem("user-data", JSON.stringify({...infoUserAux,
                    numberOfInsurance: insureModel.numberOfInsurance,
                    companyInsurance: insureModel.companyInsurance,
                    dateInsurance: insureModel.dateInsurance
                }));

                setDataSession({...dataSession,
                    numberOfInsurance: insureModel.numberOfInsurance,
                    companyInsurance: insureModel.companyInsurance,
                    dateInsurance: insureModel.dateInsurance
                });

                getData();
                toast.success("Insurance updated successfully", alertOptions);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleUpdate = (profileModel) => {
        setLoader(true);
        let newProfileModel = {
            _id: profile?._id,
            image: profileModel?.image,
            names: profileModel?.names,
            lastNames: profileModel?.lastNames,
            email: profileModel?.email,
            phoneNumber: profileModel?.phoneNumber,
            approve: profile?.approve
        }
        if (profileModel?.password !== "") newProfileModel.password = profileModel.password;
        updateRetailUser(profile?._id, newProfileModel).then(res => {
            if (res.status === 200) {
                let infoUserAux = JSON.parse(window.localStorage.getItem("user-data"));
                localStorage.setItem("user-data", JSON.stringify({...infoUserAux,
                    names: profileModel?.names,
                    lastNames: profileModel?.lastNames,
                    phoneNumber: profileModel?.phoneNumber,
                    email: profileModel?.email,
                }));

                setDataSession({...dataSession,
                    names: profileModel?.names,
                    lastNames: profileModel?.lastNames,
                    email: profileModel?.email,
                    phoneNumber: profileModel?.phoneNumber,
                });

                getData();
                toast.success("Profile updated successfully", alertOptions);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    useEffect(() => {
        setLoader(true);
        getData();
    }, [dataSession]);

    return (
        <>
            { loader ? <Loader /> : null }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="text-center">
                                <h1 className="text-2xl md:text-xl lg:text-2xl font-light text-ag-secondary">Profile</h1>
                            </div>
                            <div className="pt-3">
                                <ProfileView
                                    type="retail"
                                    information={profile}
                                    isSuccess={isSuccess}
                                    onSuccess={setIsSuccess}
                                    onUpdate={handleUpdate}
                                    onSave={handleSave}
                                    onEdit={handleEdit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Profile;