import { createContext, useState } from "react";
import { getProductsCart } from "./Shopping";
import RearRightIcon from "../assets/images/car_icon/rear_right_glass.png";
import RearLeftIcon from "../assets/images/car_icon/rear_left_glass.png";
import BackIcon from "../assets/images/car_icon/back_glass.png";
import FrontLeftIcon from "../assets/images/car_icon/front_left_glass.png";
import FrontRightIcon from "../assets/images/car_icon/front_right_glass.png";
import QuarterRightIcon from "../assets/images/car_icon/quarter_right_glass.png";
import QuarterLeftIcon from "../assets/images/car_icon/quarter_left_glass.png";
import WindshieldIcon from "../assets/images/car_icon/windshield_glass.png";
import LeftIcon from "../assets/images/car_icon/left_glass.png";
import RightIcon from "../assets/images/car_icon/right_glass.png";
import LeftVentFrontIcon from "../assets/images/car_icon/left_front_vent_icon.png";
import RightVentFrontIcon from "../assets/images/car_icon/right_front_vent_icon.png";
import LeftRearVentIcon from "../assets/images/car_icon/left_rear_vent_glass.png";
import RightRearVentIcon from "../assets/images/car_icon/right_rear_vent_glass.png";

export const PermitsContext = createContext();

export const PermitsProvider = ({ children }) => {
    const [glassSelected, setGlassSelected] = useState("")

    const [dataSession, setDataSession] = useState({
        _id: "",
        _idAdmin: "",
        token: "",
        names: "",
        lastNames: "",
        email: "",
        phoneNumber: "",
        userType: "",
        numberOfInsurance: "",
        companyInsurance: "",
        dateInsurance: ""
    });

    const [searchData, setSearchData] = useState({
        searchType: "",
        names: "",
        lastNames: "",
        phoneNumber: "",
        email: "",
        vin: "",
        year: "",
        brand: "",
        model: "",
        style: "",
        typeGlass: "",
        typeService: "",
        paymentMethod: "",
        numberOfInsurance: "",
        companyInsurance: "",
        dateInsurance: "",
        description: "",
        photos: [],
        windshieldVisorStrip: false,
        ceramicFull70: false,
        ceramicFull90: false,
        waterTreatment : false,
        installationType: "",
        nag: ""
    });

    const [typexOptions, setTypexOptions] = useState([
        { title: "Windshield", image: WindshieldIcon, show: false, expand: false, sons: [
            { title: "Replace my windshield", image: null, show: false, selected: false, value: "Windshield" },
            { title: "Repair my windshield", image: null, show: false, selected: false, value: "Windshield" }
        ]},
        { title: "Left Side", image: LeftIcon, show: false, expand: false, value: "Left", sons: [
            { title: "Front Door", image: FrontLeftIcon, show: false, selected: false, value: "Door / Front / Left" },
            { title: "Front Vent", image: LeftVentFrontIcon, show: false, selected: false, value: "Vent / Front / Left" },
            { title: "Rear Door", image: RearLeftIcon, show: false, selected: false, value: "Door / Rear / Left" },
            { title: "Rear Vent", image: LeftRearVentIcon, show: false, selected: false, value: "Vent / Rear / Left" },
            { title: "Rear Quater", image: QuarterLeftIcon, show: false, selected: false, value: "Quarter / Left" }
        ]},
        { title: "Right Side", image: RightIcon, show: false, expand: false, value: "Right", sons: [
            { title: "Front Door", image: FrontRightIcon, show: false, selected: false, value: "Door / Front / Right" },
            { title: "Front Vent", image: RightVentFrontIcon, show: false, selected: false, value: "Vent / Front / Right" },
            { title: "Rear Door", image: RearRightIcon, show: false, selected: false, value: "Door / Rear / Right" },
            { title: "Rear Vent", image: RightRearVentIcon, show: false, selected: false, value: "Vent / Rear / Right" },
            { title: "Rear Quater", image: QuarterRightIcon, show: false, selected: false, value: "Quarter / Right" }
        ]},
        { title: "Backglass", image: BackIcon, show: false, expand: false, value: "Back", sons: [
            { title: "Backglass", image: BackIcon, show: false, selected: false, value: "Back Window" }
        ]}
    ]);

    const [typexOptionsBase, setTypexOptionsBase] = useState([
        { title: "Windshield", image: WindshieldIcon, show: false, expand: false, sons: [
            { title: "Replace my windshield", image: null, show: false, selected: false, value: "Windshield" },
            { title: "Repair my windshield", image: null, show: false, selected: false, value: "Windshield" }
        ]},
        { title: "Left Side", image: LeftIcon, show: false, expand: false, value: "Left", sons: [
            { title: "Front Door", image: FrontLeftIcon, show: false, selected: false, value: "Door / Front / Left" },
            { title: "Front Vent", image: LeftVentFrontIcon, show: false, selected: false, value: "Vent / Front / Left" },
            { title: "Rear Door", image: RearLeftIcon, show: false, selected: false, value: "Door / Rear / Left" },
            { title: "Rear Vent", image: LeftRearVentIcon, show: false, selected: false, value: "Vent / Rear / Left" },
            { title: "Rear Quater", image: QuarterLeftIcon, show: false, selected: false, value: "Quarter / Left" }
        ]},
        { title: "Right Side", image: RightIcon, show: false, expand: false, value: "Right", sons: [
            { title: "Front Door", image: FrontRightIcon, show: false, selected: false, value: "Door / Front / Right" },
            { title: "Front Vent", image: RightVentFrontIcon, show: false, selected: false, value: "Vent / Front / Right" },
            { title: "Rear Door", image: RearRightIcon, show: false, selected: false, value: "Door / Rear / Right" },
            { title: "Rear Vent", image: RightRearVentIcon, show: false, selected: false, value: "Vent / Rear / Right" },
            { title: "Rear Quater", image: QuarterRightIcon, show: false, selected: false, value: "Quarter / Right" }
        ]},
        { title: "Backglass", image: BackIcon, show: false, expand: false, value: "Back", sons: [
            { title: "Backglass", image: BackIcon, show: false, selected: false, value: "Back Window" }
        ]}
    ]);

    
    const [typeGlassOptionsPWRBase, setTypeGlassOptionsPWRBase] = useState([
        { label: "Front door (left)", value: "Door / Front / Left" },
        { label: "Front door (right)", value: "Door / Front / Right" },
        { label: "Rear door (left )", value: "Door / Rear / Left" },
        { label: "Rear door (right)", value: "Door / Rear / Right" },
        { label: "Rear quarter (left)", value: "Quarter / Left" },
        { label: "Rear quarter (right)", value: "Quarter / Right" },
        { label: "Back glass (Slider)", value: "Back Window" },
        { label: "Sunroof", value: "Roof" }
    ]);

    const [distFeatures, setDistFeatures] = useState([]);
    const [produOptions, setProduOptions] = useState([]);
    const [produOptionsUniv, setProduOptionsUniv] = useState([]);

    const [cartValue, setCartValue] = useState(0);
    const [permits, setPermits] = useState({
        ADMINISTRATORS: {
            VIEW: false,
            FILTER: false,
            ADD: false,
            ROLE: false,
            EDIT: false,
            DELETE: false
        },
        USERS: {
            VIEW: false,
            FILTER: false,
            ADD_WOLESALER: false,
            ADD_RETAILER: false,
            EDIT: false,
            DELETE: false,
            APPROVE_DISPPROVE: false
        },
        SUPPLIERS: {
            VIEW: false,
            FILTER: false,
            ADD: false,
            EDIT: false,
            DELETE: false
        },
        PRODUCTS: {
            VIEW: false,
            FILTER: false,
            ADD: false,
            HISTORY: false,
            EDIT: false,
            DEACTIVATE: false
        },
        INVENTORY: {
            VIEW: false,
            FILTER: false,
            ACTIONS: false,
            RECORD: false,
            EDIT: false,
            ORDER_QUERY: false
        },
        WAREHOUSE: {
            VIEW: false,
            FILTER: false,
            ADD: false,
            EDIT: false,
            REMOVE: false,
            CREATE_RACK: false,
            EDIT_RACK: false,
            DELETE_RACK: false
        },
        ORDERS: {
            VIEW: false,
            ORDERS_FILTER: false,
            ACTIONS_ORDERS: false,
            RETURNS_FILTERS: false,
            ACTIONS_RETURNS: false
        },
        SALES: {
            VIEW: false,
            FILTER: false
        },
        QUOTES: {
            VIEW: false,
            FILTER: false,
            SEARCH: false,
            CANCEL: false
        },
        REPORTS: {
            VIEW: false,
            FILTER: false,
            DOWNLOAD: false
        }
    });

    const getCurrentPermits = (permitsStr) => {
        let permitsStructure = JSON.parse(permitsStr);
        
        const newPermits = permits;

        for (const key in permitsStructure[0]) {
            if (Object.hasOwnProperty.call(permitsStructure[0], key)) {
                const element = permitsStructure[0][key];
                const newObjPermits = newPermits[key];

                newObjPermits['VIEW'] = true;
                for (let ip = 0; ip < element.length; ip++) {
                    newObjPermits[element[ip]] = true;
                }
            }
        }
        setPermits(newPermits);
    }

    const getCurrentCart = (idWholesaler) => {
        getProductsCart(idWholesaler).then(res => {
            if (res.status === 200) {
                setCartValue(res.data.length);
            } else {
                setCartValue(0);
            }
        });
    }
  
    const clearContext = () => {
        setCartValue(0);
        setDataSession({...dataSession,
            _id: "",
            _idAdmin: "",
            token: "",
            names: "",
            lastNames: "",
            email: "",
            phoneNumber: "",
            userType: "",
            numberOfInsurance: "",
            companyInsurance: "",
            dateInsurance: ""
        });
        window.localStorage.removeItem("user-data");
        // window.localStorage.clear();
    }

    const clearQuote = () => {
        setDistFeatures([]);
        setProduOptions([]);
        setProduOptionsUniv([]);
        setSearchData({...searchData,
            searchType: "",
            names: "",
            lastNames: "",
            phoneNumber: "",
            email: "",
            vin: "",
            year: "",
            brand: "",
            model: "",
            style: "",
            typeGlass: "",
            typeService: "",
            paymentMethod: "",
            numberOfInsurance: "",
            companyInsurance: "",
            dateInsurance: "",
            description: "",
            photos: [],
            windshieldVisorStrip: false,
            ceramicFull70: false,
            ceramicFull90: false,
            waterTreatment : false,
            installationType: "",
            nag: ""
        });

        setTypexOptions([
            { title: "Windshield", image: WindshieldIcon, show: false, expand: false, sons: [
                { title: "Replace my windshield", image: null, show: false, selected: false, value: "Windshield" },
                { title: "Repair my windshield", image: null, show: false, selected: false, value: "Windshield" }
            ]},
            { title: "Left Side", image: LeftIcon, show: false, expand: false, value: "Left", sons: [
                { title: "Front Door", image: FrontLeftIcon, show: false, selected: false, value: "Door / Front / Left" },
                { title: "Front Vent", image: LeftVentFrontIcon, show: false, selected: false, value: "Vent / Front / Left" },
                { title: "Rear Door", image: RearLeftIcon, show: false, selected: false, value: "Door / Rear / Left" },
                { title: "Rear Vent", image: LeftRearVentIcon, show: false, selected: false, value: "Vent / Rear / Left" },
                { title: "Rear Quater", image: QuarterLeftIcon, show: false, selected: false, value: "Quarter / Left" }
            ]},
            { title: "Right Side", image: RightIcon, show: false, expand: false, value: "Right", sons: [
                { title: "Front Door", image: FrontRightIcon, show: false, selected: false, value: "Door / Front / Right" },
                { title: "Front Vent", image: RightVentFrontIcon, show: false, selected: false, value: "Vent / Front / Right" },
                { title: "Rear Door", image: RearRightIcon, show: false, selected: false, value: "Door / Rear / Right" },
                { title: "Rear Vent", image: RightRearVentIcon, show: false, selected: false, value: "Vent / Rear / Right" },
                { title: "Rear Quater", image: QuarterRightIcon, show: false, selected: false, value: "Quarter / Right" }
            ]},
            { title: "Backglass", image: BackIcon, show: false, expand: false, value: "Back", sons: [
                { title: "Backglass", image: BackIcon, show: false, selected: false, value: "Back Window" }
            ]}
        ]);
    }
  
    return (
        <PermitsContext.Provider
            value={{
                dataSession,
                setDataSession,
                searchData,
                setSearchData,
                typexOptions,
                setTypexOptions,
                typexOptionsBase,
                typeGlassOptionsPWRBase,
                setTypeGlassOptionsPWRBase,
                distFeatures,
                setDistFeatures,
                produOptions,
                setProduOptions,
                produOptionsUniv,
                setProduOptionsUniv,
                cartValue,
                setCartValue,
                permits,
                setPermits,
                getCurrentPermits,
                getCurrentCart,
                clearContext,
                clearQuote,
                glassSelected,
                setGlassSelected
            }}
      >
        {children}
      </PermitsContext.Provider>
    );
};