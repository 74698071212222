import React from 'react'
import { useState } from "react";


const ChoiceWTForm = () => {
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    return (
        <>
            <div className="space-y-3">
                <div className={`w-full p-4 border rounded-xl  ${isChecked ? "border-ag-primary" : "border-ag-secondary-letter"}`}>
                    <div className="flex justify-start items-center space-x-10 px-3">
                        <div>
                            <input
                                className="w-4 h-4 accent-ag-primary cursor-pointer rounded"
                                type="checkbox"
                                name="shipping_method_retail"
                                // onClick={() => handleMethod("ship")}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                        <div className='flex w-[100%] items-center'>
                            <div className='w-[80%]'>
                                <div>
                                    <span className='font-[20px] font-semibold text-ag-secondary-letter'>Window name: </span>
                                    <span className='font-[20px] font-normal text-ag-secondary-letter'>Nme</span>
                                </div>
                                <div>
                                    <span className='font-[20px] font-semibold text-ag-secondary-letter'>Description: </span>
                                    <span className='font-[20px] font-normal text-ag-secondary-letter'>descrocion</span>
                                </div>
                                <div>
                                    <span className='font-[20px] font-semibold text-ag-secondary-letter'>Warranty: </span>
                                    <span className='font-[20px] font-normal text-ag-secondary-letter'>warranty</span>
                                </div>
                            </div>
                            <div className='w-[20%]'>
                                <span className='font-[20px] font-semibold text-ag-secondary-letter'>Price: </span>
                                <span className='font-[20px] font-normal text-ag-secondary-letter'>$650</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`w-full p-4 border rounded-xl ${isChecked ? "border-ag-primary" : "border-ag-secondary-letter"}`}>
                    <div className="flex justify-start items-center space-x-10 px-3">
                        <div>
                            <input
                                className="w-4 h-4 accent-ag-primary cursor-pointer"
                                type="checkbox"
                                name="shipping_method_retail"
                                // onClick={() => handleMethod("ship")}
                                onChange={handleCheckboxChange}
                            />
                        </div>
                        <div className='flex w-[100%] items-center'>
                            <div className='w-[80%]'>
                                <div>
                                    <span className='font-[20px] font-semibold text-ag-secondary-letter'>Window name: </span>
                                    <span className='font-[20px] font-normal text-ag-secondary-letter'>Nme</span>
                                </div>
                                <div>
                                    <span className='font-[20px] font-semibold text-ag-secondary-letter'>Description: </span>
                                    <span className='font-[20px] font-normal text-ag-secondary-letter'>descrocion</span>
                                </div>
                                <div>
                                    <span className='font-[20px] font-semibold text-ag-secondary-letter'>Warranty: </span>
                                    <span className='font-[20px] font-normal text-ag-secondary-letter'>warranty</span>
                                </div>
                            </div>
                            <div className='w-[20%]'>
                                <span className='font-[20px] font-semibold text-ag-secondary-letter'>Price: </span>
                                <span className='font-[20px] font-normal text-ag-secondary-letter'>$650</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ChoiceWTForm