import { useEffect, useState } from "react";
import { IoMdCloseCircle, IoMdImage } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { validateProfile } from "../../../utils/Validators";
import { AiFillCloseCircle } from "react-icons/ai";
import { fileUpload } from "../../../services/File";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";

const FormProfile = ({ userData, onSave, onClose }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [focusClass, setFocusClass] = useState({
        names: { placeholder: "Names", status: false },
        lastNames: { placeholder: "Last Names", status: false },
        email: { placeholder: "Email", status: false },
        password: { placeholder: "Password", status: false },
        phoneNumber: { placeholder: "Phone Number", status: false }
    });

    const [profile, setProfile] = useState({
        names: "",
        lastNames: "",
        email: "",
        password: "",
        phoneNumber: "",
        image: ""
    });

    const [imageUpload, setImageUpload] = useState({ name: "", file: null });

    const handleUpload= (evt) => {
        if (evt.currentTarget.files[0].type !== "image/png" && evt.currentTarget.files[0].type !== "image/jpg" && evt.currentTarget.files[0].type !== "image/jpeg") {
            toast.warning("It is not possible to upload a file other than png, jpg or jpeg", alertOptions);
        } else {
            const file = evt.currentTarget.files;
            if (file !== null) setImageUpload({...imageUpload, name: file[0].name, file: file[0]});
        }
    }

    const handleChange = (evt) => {
        if (evt.currentTarget.name === "names" || evt.currentTarget.name === "lastNames") {
            const pattern = new RegExp(/^[A-Za-z\s]+$/g);
            if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                setProfile({...profile, [evt.target.name]: evt.target.value});
            }
        } else if (evt.currentTarget.name === "phoneNumber") {
            const pattern = new RegExp(/^[0-9\s]+$/g);
            if (pattern.test(evt.currentTarget.value) || evt.currentTarget.value === "") {
                setProfile({...profile, [evt.target.name]: evt.target.value});
            }
        } else {
            setProfile({...profile, [evt.target.name]: evt.target.value});
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        if (profile.names !== "" && profile.lastNames !== "" && profile.email !== "" && profile.phoneNumber !== "") {
            let newProfile = {
                names: profile.names,
                lastNames: profile.lastNames,
                email: profile.email,
                phoneNumber: profile.phoneNumber
            }
            if (profile.password !== "") newProfile.password = profile.password;
            const result = validateProfile(newProfile);
            if (result.status) {
                let newProfileModel = newProfile;

                // Upload file
                if (imageUpload.file !== null && imageUpload.name !== "") {
                    let bodyFormData = new FormData();
                    bodyFormData.append('file', imageUpload.file);
                    const valueUpload = await fileUpload(bodyFormData).then(res => {
                        if (res.status === 200) return res.data;
                        return "";
                    });
                    newProfileModel.image = valueUpload;
                }

                onSave(newProfileModel);
            } else {
                toast.warning(result.msg, alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        setProfile({...profile,
            names: userData?.names,
            lastNames: userData?.lastNames,
            email: userData?.email,
            phoneNumber: userData?.phoneNumber,
            image: userData?.image
        });
        setImageUpload({...imageUpload, name: userData?.image, file: null});

        setFocusClass({...focusClass,
            names: { placeholder: "Names", status: true },
            lastNames: { placeholder: "Last Names", status: true },
            email: { placeholder: "Email", status: true },
            password: { placeholder: "Password", status: false },
            phoneNumber: { placeholder: "Phone Number", status: true }
        });
    }, [userData]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none">
                    <div className="w-[335px] md:w-[500px]">
                        <div className="p-3 md:p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold">
                                        Profile Editing
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="relative px-5 pb-2 pt-3 flex-auto">
                                <form onSubmit={handleSubmit}>
                                    <div className="pb-3 pl-2 text-left">
                                        <p className="text-xs text-ag-secondary-letter">If the password field is sent empty, it remains the same, otherwise your password will be changed</p>
                                    </div>
                                    <div className="space-y-2">
                                        <div>
                                            <input
                                                className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.names.status ? "text-left" : "text-center"}`}
                                                type="text"
                                                name="names"
                                                placeholder={focusClass.names.placeholder}
                                                onChange={handleChange}
                                                value={profile.names}
                                                onFocus={() => setFocusClass({...focusClass, names: { placeholder: "", status: true }})}
                                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, names: { placeholder: "Names", status: false }}) : null}
                                                maxLength={40}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.lastNames.status ? "text-left" : "text-center"}`}
                                                type="text"
                                                name="lastNames"
                                                placeholder={focusClass.lastNames.placeholder}
                                                onChange={handleChange}
                                                value={profile.lastNames}
                                                onFocus={() => setFocusClass({...focusClass, lastNames: { placeholder: "", status: true }})}
                                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, lastNames: { placeholder: "Last names", status: false }}) : null}
                                                maxLength={40}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.email.status ? "text-left" : "text-center"}`}
                                                type="text"
                                                name="email"
                                                placeholder={focusClass.email.placeholder}
                                                onChange={handleChange}
                                                value={profile.email}
                                                onFocus={() => setFocusClass({...focusClass, email: { placeholder: "", status: true }})}
                                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, email: { placeholder: "Email", status: false }}) : null}
                                                maxLength={40}
                                            />
                                        </div>
                                        <div className="flex justify-center items-center border border-gray-500 rounded-xl">
                                            <input
                                                className={`w-full text-ag-secondary-letter text-xs lg:text-base py-2 rounded-xl ${focusClass.password.status ? "text-left pl-2" : "text-center pl-10"}`}
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                placeholder={focusClass.password.placeholder}
                                                onChange={handleChange}
                                                value={profile.password}
                                                onFocus={() => setFocusClass({...focusClass, password: { placeholder: "", status: true }})}
                                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, password: { placeholder: "Password", status: false }}) : null}
                                                maxLength={40}
                                            />
                                            { showPassword ?
                                                <button 
                                                    className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                                                    type="button"
                                                    onClick={() => setShowPassword(false)}
                                                >
                                                    <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeSlashFill /></span>
                                                </button>
                                            :
                                                <button 
                                                    className="border-none p-2 rounded-xl disabled:bg-zinc-200"
                                                    type="button"
                                                    onClick={() => setShowPassword(true)}
                                                >
                                                    <span className="text-ag-secondary-letter text-base lg:text-xl"><BsFillEyeFill /></span>
                                                </button>
                                            }
                                        </div>
                                        {/* <div>
                                            <input
                                                className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.password.status ? "text-left" : "text-center"}`}
                                                type="password"
                                                name="password"
                                                placeholder={focusClass.password.placeholder}
                                                onChange={handleChange}
                                                value={profile.password}
                                                onFocus={() => setFocusClass({...focusClass, password: { placeholder: "", status: true }})}
                                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, password: { placeholder: "Password", status: false }}) : null}
                                                maxLength={40}
                                            />
                                        </div> */}
                                        <div>
                                            <input
                                                className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate ${focusClass.phoneNumber.status ? "text-left" : "text-center"}`}
                                                type="text"
                                                name="phoneNumber"
                                                placeholder={focusClass.phoneNumber.placeholder}
                                                onChange={handleChange}
                                                value={profile.phoneNumber}
                                                onFocus={() => setFocusClass({...focusClass, phoneNumber: { placeholder: "", status: true }})}
                                                onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, phoneNumber: { placeholder: "Phone number", status: false }}) : null}
                                                maxLength={10}
                                            />
                                        </div>
                                        <div>
                                            <label className="relative w-full">
                                                <div className="w-full border border-gray-500 px-2 py-4 rounded-xl m-auto">
                                                    <div>
                                                        { imageUpload.name ?
                                                            <div className="flex">
                                                                <div className="w-full flex justify-center items-center space-x-2">
                                                                    <span className="text-ag-secondary-letter text-2xl">
                                                                        <IoMdImage />
                                                                    </span>
                                                                    <span
                                                                        className="w-44 md:w-80 text-left text-ag-secondary-letter text-xs lg:text-base truncate"
                                                                    >
                                                                        { imageUpload.name }
                                                                    </span>
                                                                </div>
                                                                <button
                                                                    className="text-red-500 text-xl"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        setImageUpload({...imageUpload, name: "", file: null});
                                                                        setProfile({...profile, image: ""});
                                                                    }}
                                                                >
                                                                    <AiFillCloseCircle />
                                                                </button>
                                                            </div>
                                                            :
                                                            <div className="flex justify-center items-center space-x-2">
                                                                <span className="text-ag-secondary-letter text-2xl">
                                                                    <IoMdImage />
                                                                </span>
                                                                <span
                                                                    className="w-full text-left text-ag-secondary-letter text-xs lg:text-base truncate"
                                                                >
                                                                    Upload image...
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <input type="file" className="absolute w-[100px] top-[0px] opacity-0" name="productImages" accept=".png, .jpg, .jpeg" onChange={handleUpload} />
                                            </label>
                                        </div>
                                        <div className="w-full m-auto">
                                            <button
                                                className="w-full bg-ag-primary-light text-white text-xs lg:text-base p-2 rounded-xl"
                                                type="submit"
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
        </>
    );
}

export default FormProfile;