import { IoMdImage } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const statusAppointment = {
    approved: { text: "Approved", textColor: "text-ag-secondary-letter", bgColor: "bg-green-600", borderColor: "border-green-600" },
    canceled: { text: "Canceled", textColor: "text-ag-primary-light", bgColor: "bg-ag-primary-light", borderColor: "border-ag-primary-light" },
    inprocess: { text: "In process", textColor: "text-ag-secondary-letter", bgColor: "bg-ag-secondary", borderColor: "border-ag-secondary"}
}

const paymentQuote = {
    PAY_ON_MY_WON: "Pay on my own",
    CAR_INSURANCE: "Pay with Insurance"
}

const QuotePhotoCard = ({ quote, onCancel, onDelete }) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="w-full lg:flex lg:justify-between xl:items-center p-3 md:p-0 border border-ag-secondary-light rounded-xl">
                <div className="w-full lg:w-[15%] flex justify-center items-center text-center text-ag-secondary-letter">
                    {
                        quote?.photos.length > 0 ?
                            <div className="w-[150px] h-[150px] flex justify-center items-center rounded-xl p-1">
                                <img className="w-full h-full object-contain rounded-xl" src={quote?.photos[0]} />
                            </div>
                        :
                            <span className="text-[70px] md:text-[60px] xl:text-[70px]"><IoMdImage /></span>
                    }
                </div>
                <div className="w-full lg:w-[35%] text-base md:text-xs lg:text-base">
                    <div className="px-3 py-8 text-ag-secondary-letter">
                        <ul>
                            <li className="flex justify-start items-center space-x-2">
                                <span className="font-semibold">Type:</span>
                                <p className="truncate">{ "Per photo..." }</p>
                            </li>
                            <li className="flex justify-start items-center space-x-2">
                                <span className="font-semibold">Description:</span>
                                <p className="truncate" title={quote?.description}>
                                    { quote?.description ? quote?.description : "Quote per photo..." }
                                </p>
                            </li>
                            {
                                quote?.paymentMethod === "CAR_INSURANCE" ?
                                    <li className="py-2"></li>
                                : null
                            }
                        </ul>
                    </div>
                    <div className={`flex justify-center space-x-3 px-3 py-0 text-white border ${statusAppointment[quote?.status].borderColor} ${statusAppointment[quote?.status].bgColor}`}>
                        <span>{ statusAppointment[quote.status].text }</span>
                    </div>
                </div>
                <div className="w-full lg:w-[35%] text-base md:text-xs lg:text-base text-ag-secondary-letter">
                    <div className="px-3 py-8 text-ag-secondary-letter">
                        <ul>
                            <li className="flex justify-start items-center space-x-2">
                                <span className="font-semibold">Payment method:</span>
                                <p className="truncate">{ quote?.paymentMethod ? paymentQuote[quote?.paymentMethod] : "---" }</p>
                            </li>
                            {
                                quote?.paymentMethod === "CAR_INSURANCE" ?
                                    <li className="text-xs">{'(Free full Ceramic Windshield Tint)'}</li>
                                : null
                            }
                            <li className="flex justify-start items-center space-x-2">
                                <span className="font-semibold">{ quote?.status === "canceled" ? "Reason for cancellation:" : "" }</span>
                                {
                                    quote?.status === "canceled" ?
                                        <p className="lg:w-20 xl:w-32 truncate" title={quote?.reasonCancel ? quote?.reasonCancel : "Canceled by customer"}>
                                            { quote?.reasonCancel ? quote?.reasonCancel : "Canceled by customer" }
                                        </p>
                                    : <p className="truncate text-transparent">.</p>
                                }
                            </li>
                        </ul>
                    </div>
                    <div className={`hidden md:block flex justify-center space-x-3 px-3 py-0 text-transparent border ${statusAppointment[quote.status].borderColor} ${statusAppointment[quote.status].bgColor}`}>
                        <span>...</span>
                    </div>
                </div>
                <div className="w-full lg:w-[15%] flex justify-center items-center p-5">
                    {
                        quote.status === "approved" ?
                            <button className="w-full px-2 xl:px-4 py-2 bg-ag-secondary text-base md:text-xs xl:text-base text-white rounded-xl" type="button" onClick={() => navigate(`/quotes/detail/${quote._id}/shipping`, {state: quote})}>
                                Schedule
                            </button>
                        :
                            null
                    }
                    {
                        quote.status === "inprocess" ?
                            <button className="w-full px-2 xl:px-4 py-2 bg-ag-primary-light text-base md:text-xs xl:text-base text-white rounded-xl" type="button" onClick={() => onCancel(quote._id)}>
                                Cancel
                            </button>
                        :
                            null
                    }
                    {
                        quote.status === "canceled" ?
                            <button className="w-full px-2 xl:px-4 py-2 bg-ag-primary-light text-base md:text-xs xl:text-base text-white rounded-xl" type="button" onClick={() => onDelete(quote._id)}>
                                Delete
                            </button>
                        :
                            null
                    }
                </div>
            </div>
        </>
    );
}

export default QuotePhotoCard;