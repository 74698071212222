import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { fileUpload } from "../../../../services/File";
import { validateCarInsuranceAux, validateQuote } from "../../../../utils/Validators";
import { saveQuote } from "../../../../services/Quotes";
import { PermitsContext } from "../../../../services/Permits";
import Loader from "../../../../components/loader/Loader";
import NavbarClients from "../../../../components/navbar/NavbarClients";
import SidebarClients from "../../../../components/sidebar/SidebarClients";
import StepTwoForm from "./StepTwoForm";
import ProductRequest from "../ProductRequest";
import FormReparation from "../FormReparation";
import FormWindowTint from "../FormWindowTint";
import QuoteInProcessConfirm from "../QuoteInProcessConfirm";

const StepTwo = () => {
    const navigate = useNavigate();
    const { dataSession, setDataSession, searchData, setSearchData, clearQuote } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showModalRequest, setShowModalRequest] = useState(false);
    const [showModalReparation, setShowModalReparation] = useState(false);
    const [showModalWindowTint, setShowModalWindowTint] = useState(false);
    const [showModalInProcessPP, setShowModalInProcessPP] = useState(false);

    const [paymentMethods] = useState([
        { label: "Pay with Insurance (Free Full Ceramic Windshield Tint)", value: "CAR_INSURANCE", style: "font-semibold" },
        { label: "Pay on my own", value: "PAY_ON_MY_WON", style: "" }
    ]);

    const handleModalReparation = () => {
        // if (searchData.typeGlass !== "") {
        //     setShowModalReparation(true);
        // } else {
        //     toast.info("Please select the type of glass in your vehicle to access this function.", alertOptions);
        // }
        setShowModalReparation(true);
    }

    const handleModalWindowTint = () => {
        // if (searchData.typeGlass !== "") {
        //     setShowModalWindowTint(true);
        // } else {
        //     toast.info("Please select the type of glass in your vehicle to access this function.", alertOptions);
        // }
        setShowModalWindowTint(true);
    }

    const handleChangePhotos = (photosModel) => {
        new Promise((resolve, reject) => {
            const newSearchModel = {
                searchType: "photos",
                names: photosModel.names,
                lastNames: photosModel.lastNames,
                phoneNumber: photosModel.phoneNumber,
                comments: photosModel.comments,
                email: photosModel.email,
                description: photosModel.description,
                photos: photosModel.photos,
                paymentMethod: photosModel.paymentMethod,
                numberOfInsurance: photosModel.numberOfInsurance,
                companyInsurance: photosModel.companyInsurance,
                dateInsurance:photosModel.dateInsurance,
                vin: "",
                year: "",
                brand: "",
                model: "",
                style: "",
                typeGlass: "",
                typeService: "REPAIR",
                windshieldVisorStrip: false,
                ceramicFull70: false,
                ceramicFull90: false,
                waterTreatment : false,
                installationType: "",
                nag: ""
            };
            resolve(newSearchModel);
        }).then((res) => {
            handleSubmit(res);
        });
    }

    const handlePowerRepair = () => {
        setShowModalReparation(false);
        setShowModalInProcessPP(true);
        clearQuote();
    }

    const handleWindowTint = () => {
        setShowModalWindowTint(false);
        setShowModalInProcessPP(true);
        clearQuote();
    }

    const handleSubmit = async (searchModel) => {
        if (
            searchModel.paymentMethod !== "" && searchModel.paymentMethod !== "default" && (searchModel.paymentMethod !== "CAR_INSURANCE" || 
            (searchModel.paymentMethod === "CAR_INSURANCE" && searchModel.numberOfInsurance !== "" && 
            searchModel.companyInsurance !== "" && searchModel.dateInsurance !== ""))
        ) {
            if (
                searchModel.searchType !== "photos" && searchModel.vin === "" && searchModel.year === "" && 
                searchModel.brand === "" && searchModel.model === "" && searchModel.style === "" && searchModel.typeGlass === ""
            ) {
                toast.info("Please fill in all the fields of the form", alertOptions);
            } else if (searchModel.searchType === "photos" && searchModel.description === "" && searchModel.photos.length === 0) {
                toast.info("Please fill in all the fields of the form", alertOptions);
            } else {
                setLoader(true);
                let quoteObject = {
                    paymentMethod: searchModel.paymentMethod,
                    numberOfInsurance: searchModel.numberOfInsurance,
                    companyInsurance: searchModel.companyInsurance,
                    dateInsurance: searchModel.dateInsurance,
                    comments: searchModel.comments,
                    typeService: searchModel.typeService
                };

                // Upload file
                let quoteValidImages = [];
                for (let i = 0; i < searchModel.photos.length; i++) {
                    if (searchModel.photos[i].file !== null && searchModel.photos[i].name !== "") {
                        let bodyFormData = new FormData();
                        bodyFormData.append('file', searchModel.photos[i].file);
                        const valueUpload = await fileUpload(bodyFormData).then(res => {
                            if (res.status === 200) return res.data;
                            return "";
                        });
                        quoteValidImages.push(valueUpload);
                    } else if (searchModel.photos[i].name !== "") {
                        quoteValidImages.push(searchModel.photos[i].name);
                    }
                }

                quoteObject.photos = quoteValidImages;
                quoteObject.description = searchModel.description;
                quoteObject.searchType = searchModel.searchType;
                quoteObject.names = searchModel.names;
                quoteObject.lastNames = searchModel.lastNames;
                quoteObject.phoneNumber = searchModel.phoneNumber;
                quoteObject.email = searchModel.email;
                if (quoteObject.paymentMethod === "CAR_INSURANCE") {
                    quoteObject.insurance = {
                        numberOfInsurance: searchModel.numberOfInsurance,
                        companyInsurance: searchModel.companyInsurance,
                        dateInsurance: searchModel.dateInsurance,
                    }
                }

                if (quoteObject.paymentMethod === "CAR_INSURANCE") {
                    if (new Date() <= new Date(quoteObject.dateInsurance)) {
                        toast.warning("Loss date must higher than today's date", alertOptions);
                        setLoader(false);
                        return;
                    }

                    const resultCar = validateCarInsuranceAux(quoteObject);
                    if (!resultCar.status) {
                        toast.warning(resultCar.msg, alertOptions);
                        setLoader(false);
                        return 0;
                    }
                }

                const result = validateQuote(quoteObject);
                if (result.status) {
                    handleSaveQuote(quoteObject);
                } else {
                    setLoader(false);
                    toast.warning(result.msg, alertOptions);
                }
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    const handleSaveQuote = (quoteObject) => {
        saveQuote(quoteObject).then(res => {
            if (res.status === 200) {
                if (quoteObject?.paymentMethod === "CAR_INSURANCE") {
                    let infoUserAux = JSON.parse(window.localStorage.getItem("user-data"));
                    localStorage.setItem("user-data", JSON.stringify({...infoUserAux,
                        numberOfInsurance: quoteObject.numberOfInsurance,
                        companyInsurance: quoteObject.companyInsurance,
                        dateInsurance: quoteObject.dateInsurance
                    }));

                    setDataSession({...dataSession,
                        numberOfInsurance: quoteObject.numberOfInsurance,
                        companyInsurance: quoteObject.companyInsurance,
                        dateInsurance: quoteObject.dateInsurance
                    });
                }

                toast.success("Quote saved successfully", alertOptions);
                setShowModalRequest(false);
                setShowModalInProcessPP(true);
                clearQuote();
                setLoader(false);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message ? res.response.data.message : "There was a problem saving the quote. Please try again in a few minutes", alertOptions);
            }
        }).catch((error) => {
            toast.warning(error.response.data.message ? error.response.data.message : "There was a problem saving the quote. Please try again in a few minutes", alertOptions);
        })
    }

    useEffect(() => {
        setSearchData({...searchData,
            numberOfInsurance: dataSession.numberOfInsurance ? dataSession.numberOfInsurance : "",
            companyInsurance: dataSession.companyInsurance ? dataSession.companyInsurance : "",
            dateInsurance: dataSession.dateInsurance !== "" ? new Date(dataSession.dateInsurance) : "",
            names: dataSession.names ? dataSession.names : "",
            lastNames: dataSession.lastNames ? dataSession?.lastNames : "",
            email: dataSession.email ? dataSession.email : "",
            phoneNumber: dataSession.phoneNumber ? dataSession.phoneNumber : ""
        });
    }, [dataSession]);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showModalInProcessPP ?
                    <QuoteInProcessConfirm onClose={() => navigate("/appointments")} />
                : null
            }

            {
                showModalRequest ?
                    <ProductRequest
                        paymentMethods={paymentMethods}
                        onSubmit={handleChangePhotos}
                        onClose={() => setShowModalRequest(false)}    
                        informationData={{
                            numberOfInsurance: searchData.numberOfInsurance,
                            companyInsurance: searchData.companyInsurance,
                            dateInsurance: searchData.dateInsurance,
                            names: searchData.names,
                            lastNames: searchData.lastNames,
                            email: searchData.email,
                            phoneNumber: searchData.phoneNumber
                        }}
                    />
                : null
            }

            {
                showModalReparation ?
                    <FormReparation
                        information={searchData}
                        onClose={() => setShowModalReparation(false)}
                        onSuccess={() => handlePowerRepair()}
                        onLoader={setLoader}
                        informationData={{
                            names: searchData.names,
                            lastNames: searchData.lastNames,
                            email: searchData.email,
                            phoneNumber: searchData.phoneNumber
                        }}
                    />
                : null
            }

            {
                showModalWindowTint ?
                    <FormWindowTint
                        information={searchData}
                        onClose={() => setShowModalWindowTint(false)}
                        onSuccess={() => handleWindowTint()}
                        onLoader={setLoader}
                        informationData={{
                            names: searchData.names,
                            lastNames: searchData.lastNames,
                            email: searchData.email,
                            phoneNumber: searchData.phoneNumber
                        }}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-16 md:mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="px-5 xl:px-28 2xl:px-40 pt-3">
                                <StepTwoForm
                                    type="user"
                                    setLoader={setLoader}
                                    setShowModalRequest={setShowModalRequest}
                                    handleModalWindowTint={handleModalWindowTint}
                                    handleModalReparation={handleModalReparation}    
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StepTwo;