import { useState } from "react";
import { validateMail } from "../../../utils/Validators";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../../services/Auth";
import LogoMAY from "../../../assets/images/logo_a.png";
import Loader from "../../../components/loader/Loader";

const RecoverPassword = () => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [mail, setMail] = useState("");

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (mail !== "" && String(mail).trim().length > 0) {
            const result = validateMail(mail);
            if (result) {
                setLoader(true);
                resetPassword({ email: mail }).then((res) => {
                    if (res.status === 200) {
                        toast.success("Please check your mailbox to continue with the process", alertOptions);
                        setMail("");
                    } else {
                        toast.warning(res.response.data.message, alertOptions);
                    }
                    setLoader(false);
                });
            } else {
                toast.warning("Please review the following fields: Email", alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    return (
        <>
            { loader ? <Loader /> : null }

            <div className="min-h-screen bg-black flex justify-center items-center">
                <div className="lex flex-col md:grid md:gap-5 lg:gap-20 md:grid-cols-2 w-[80%] md:w-[800px] lg:w-[1100px] space-y-10 md:space-y-0">
                    <div className="flex justify-center items-center w-full">
                        <img className="h-auto w-auto" src={LogoMAY} />
                    </div>
                    <div className="lex justify-center p-0 md:p-7 lg:p-10">
                        <div className="w-full bg-white rounded-xl p-5 w-full space-y-5">
                            <div className="w-full text-center pb-5">
                                <h1 className="text-2xl text-ag-secondary font-bold">Recover password</h1>
                            </div>
                            <div className="w-full">
                                <form onSubmit={handleSubmit}>
                                    <div className="space-y-3">
                                        <div className="text-center">
                                            <p className="text-xs text-ag-secondary-letter">
                                                We will send you a link to reset your password
                                            </p>
                                        </div>
                                        <div className="pb-3">
                                            <input
                                                className="w-full text-center border border-gray-500 p-2 rounded-xl"
                                                type="text"
                                                name="mail"
                                                placeholder="Email"
                                                onChange={(evt) => setMail(evt.target.value)}
                                                value={mail}
                                            />
                                        </div>
                                        <div className="text-center">
                                            <p className="text-xs text-ag-secondary-letter">
                                                <button type="button" onClick={() => navigate(-1)}>
                                                    <span>Do you already have an account?</span>
                                                </button>
                                            </p>
                                        </div>
                                        <div className="pb-7">
                                            <button className="w-full bg-ag-primary-light text-white p-2 rounded-xl" type="submit">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RecoverPassword;