import { IoCameraOutline } from "react-icons/io5";

const BannerQuoteByPhoto = ({ setShowModalRequest }) => {
  return (
    <div className="text-center webkit-center">
      <div className="md:w-[70%] space-y-5 rounded-xl bg-gradient-to-r from-[#5d94bb] to-[#ff5f52]">
        <div className="pt-4">
          <div className="text-xl font-bold text-center text-white">
              <span>
                Not sure of your damage type?
              </span>
          </div>
        </div>

        <div className="text-center mt-[0px]">
          <button
            className="border-white hover:text-ag-primary-light hover:bg-white hover:font-bold mb-[20px] py-[5px] px-[20px] border-2 text-sm text-white rounded-xl"
            type="button"
            onClick={() => setShowModalRequest(true)}
          >
            <div className="flex items-center gap-[10px]">
              <div className="text-2xl">
                <IoCameraOutline />
              </div>

              Upload photos for review
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}

export default BannerQuoteByPhoto;