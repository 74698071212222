import { useEffect, useState } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { validateAddress } from "../../../utils/Validators";

const AddressesForm = ({ addressData, onClose, onLoader, onAdd, onEdit }) => {
    const [address, setAddress] = useState({
        firstNames: "",
        lastNames: "",
        phoneNumber: "",
        street: "",
        streetOne: "NA",
        streetTwo: "NA",
        numberOutSide: "NA",
        numberInside: "NA",
        postalCode: "",
        suburb: "",
        city: "",
        country: "NA",
        references: "",
        selected: false
    });

    const handleChange = (evt) => {
        setAddress({...address, [evt.target.name]: evt.target.value});
    }

    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (
            address.street !== "" && address.postalCode !== "" && address.suburb !== "" && address.city !== ""
        ) {
            onLoader(true);

            const result = validateAddress(address);
            if (result.status) {
                if (addressData !== null) {
                    onEdit({...address, _id: addressData._id});
                } else {
                    onAdd(address);
                }
            } else {
                onLoader(false);
                toast.warning(result.msg, alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    useEffect(() => {
        if (addressData !== null) {
            setAddress({...address,
                firstNames: addressData?.firstNames ? addressData?.firstNames : "NA",
                lastNames: addressData?.lastNames ? addressData?.lastNames : "NA",
                phoneNumber: addressData?.phoneNumber ? addressData?.phoneNumber : "NA",
                street: addressData?.street ? addressData?.street : "NA",
                streetOne: addressData?.streetOne ? addressData?.streetOne : "NA",
                streetTwo: addressData?.streetTwo ? addressData?.streetTwo : "NA",
                numberOutSide: addressData?.numberOutSide ? addressData?.numberOutSide : "NA",
                numberInside: addressData?.numberInside ? addressData?.numberInside : "NA",
                postalCode: addressData?.postalCode ? addressData?.postalCode : "NA",
                suburb: addressData?.suburb ? addressData?.suburb : "NA",
                city: addressData?.city ? addressData?.city : "NA",
                country: addressData?.country ? addressData?.country : "NA",
                references: addressData?.references ? addressData?.references : "NA",
                selected: addressData?.selected ? addressData?.selected : false
            });
        }
    }, [addressData]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[750px] lg:w-[850px]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold">
                                        { addressData !== null ? "Address edition" : "New Address" }
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-2 md:px-5 pb-0 pt-5">
                                <form onSubmit={handleSubmit}>
                                    <div className="grid gap-x-3 md:gap-x-6 gap-y-2 md:grid-cols-2">
                                        <div>
                                            <input
                                                className="w-full text-center text-ag-secondary-letter text-sm border border-gray-500 p-2 rounded-xl truncate"
                                                type="text"
                                                name="street"
                                                placeholder="Street"
                                                onChange={handleChange}
                                                value={address.street}
                                            />
                                        </div>
                                        <div>
                                            <input
                                                className="w-full text-center text-ag-secondary-letter text-sm border border-gray-500 p-2 rounded-xl truncate"
                                                type="text"
                                                name="city"
                                                placeholder="City"
                                                onChange={handleChange}
                                                value={address.city}
                                            />
                                        </div>
                                            <div>
                                                <input
                                                    className="w-full text-center text-ag-secondary-letter text-sm border border-gray-500 p-2 rounded-xl truncate"
                                                    type="text"
                                                    name="suburb"
                                                    placeholder="State"
                                                    onChange={handleChange}
                                                    value={address.suburb}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    className="w-full text-center text-ag-secondary-letter text-sm border border-gray-500 p-2 rounded-xl truncate"
                                                    type="text"
                                                    name="postalCode"
                                                    placeholder="ZIP Code"
                                                    onChange={handleChange}
                                                    value={address.postalCode}
                                                />
                                            </div>
                                    </div>
                                    <div className="py-3">
                                        <input
                                            className="w-full text-center text-ag-secondary-letter text-sm border border-gray-500 p-2 rounded-xl truncate"
                                            type="text"
                                            name="references"
                                            placeholder="Additional information"
                                            onChange={handleChange}
                                            value={address.references}
                                        />
                                    </div>
                                    <div className="w-full flex justify-center">
                                        <button
                                            className="w-full md:w-[40%] bg-ag-primary-light text-center text-sm text-white p-2 rounded-xl disabled:bg-zinc-200 m-auto"
                                            type="submit"
                                        >
                                            Save
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

export default AddressesForm;