import { _delete, _get, _post, _put } from "./http";

export const getPaymentMethod = (idRetail, idPaymentMethod) => _get(`/retailUser/${idRetail}/paymentMethods/${idPaymentMethod}`);

export const getPaymentMethods = (idRetail) => _get(`/retailUser/${idRetail}/paymentMethods`);

export const savePaymentMethod = (idRetail, paymentMethodObject) => _post(`/retailUser/${idRetail}/paymentMethods`, paymentMethodObject);

export const updatePaymentMethod = (idRetail, idPaymentMethod, paymentMethodObject) => _put(`/retailUser/${idRetail}/paymentMethods/${idPaymentMethod}`, paymentMethodObject);

export const deletePaymentMethod = (idRetail, idPaymentMethod) => _delete(`/retailUser/${idRetail}/paymentMethods/${idPaymentMethod}`);
