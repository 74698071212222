import { AiFillInfoCircle } from "react-icons/ai";

const ModalSameDay = ({ onClose, onConfirm }) => {
  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
          <div className="w-[320px] md:w-[420px]">
            <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
              <div className="px-2 pb-5">
                <div className="flex justify-center pb-5">
                  <p className="text-[60px] text-ag-secondary-letter"><AiFillInfoCircle /></p>
                </div>

                <p className="text-lg text-ag-secondary-letter font-semibold text-center">Same day installation please call for available, and finish the quotation for our records:</p>

                <p className="text-lg text-ag-secondary-letter text-center">glassquote1@gmail.com</p>

                <p className="text-lg text-ag-secondary-letter text-center">623-337-3352</p>
              </div>

              <div className="w-full flex justify-center items-center space-x-3">
                <button
                  className="w-full bg-ag-primary-light text-center text-sm text-white p-2 rounded-xl disabled:bg-zinc-200 m-auto"
                  type="button"
                  onClick={() => onClose(true)}
                >
                  Cancel
                </button>

                <button
                  className="w-full bg-green-700 text-center text-sm text-white p-2 rounded-xl disabled:bg-zinc-200 m-auto"
                  type="button"
                  onClick={() => onConfirm(true)}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
    </>
  );
};

export default ModalSameDay;