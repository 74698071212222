import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { alertOptions, formatReverseDate } from "../../../../utils/Utilities";
import { getWarehouses } from "../../../../services/Warehouse";
import { useLocation, useNavigate } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { rescheduleQuoteRetail } from "../../../../services/Quotes";
import { PermitsContext } from "../../../../services/Permits";
import InformationQuote from "../details/InformationQuote";
import ScheduleQuote from "../schedule/ScheduleQuote";
import SidebarClients from "../../../../components/sidebar/SidebarClients";
import NavbarClients from "../../../../components/navbar/NavbarClients";
import Loader from "../../../../components/loader/Loader";
import ModalSameDay from "../../../fragments/ModalSameDay";
import ModalSunday from "../../../fragments/ModalSunday";

const Reschedule = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { dataSession } = useContext(PermitsContext);
    const [loader, setLoader] = useState(false);
    const [showSameDay, setShowSameDay] = useState({ status: false, data: null });
    const [showSunday, setShowSunday] = useState({ status: false, data: null });
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [quote, setQuote] = useState(null);

    const handleSchedule = () => {
        if (quote.day && quote.hour) {
            setLoader(true);
            rescheduleQuoteRetail(dataSession._id, quote?._idAppointment, { date: `${formatReverseDate(quote.day)}T${quote.hour}:00` }).then(res => {
                if (res.status === 200) {
                    setLoader(false);
                    navigate(`/appointments`);
                } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                }
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        } else {
            toast.warning("Please select the date and time of the appointment", alertOptions);
        }
    }

    const handleDay = (daySelected) => {
        setQuote({...quote, day: daySelected, hour: null});
    }

    const handleShowSunday = (sndVal, daySel) => {
        if (sndVal) {
            setShowSunday({...showSunday, status: true, data: null});
            setQuote({...quote, day: daySel});
        } else {
            handleSundayClose(daySel);
        }
    }

    const handleSunday = async () => {
        setShowSunday({...showSunday, status: false, data: null});
        const resWarehouses = await getWarehouses();
        const warehouses = resWarehouses.status === 200 ? resWarehouses.data : [];
        const warehouseCurrent = warehouses.filter(elem => elem.name === "Phoenix Shop");
        if (warehouseCurrent.length > 0) {
            const newQuoteModel = {...quote,
                warehouseAddress: { _id: warehouseCurrent[0]?.address?._id },
                fromWarehouse: { _id: warehouseCurrent[0]?._id},
                address: {...warehouseCurrent[0]?.address, _idWarehouse: warehouseCurrent[0]?._id}
            };
            if (!quote?.lastAddress) {
                newQuoteModel.lastWarehouseAddress = quote?.warehouseAddress;
                newQuoteModel.lastFromWarehouse = quote?.fromWarehouse;
                newQuoteModel.lastAddress = quote?.address;
            }
            setQuote(newQuoteModel);
        } else {
            toast.info("There was a problem finding the warehouse", alertOptions);
        }
    }

    const handleSundayClose = (daySel) => {
        const newQuoteModel = {...quote, day: daySel, hour: null };

        if (quote?.lastAddress) {
            newQuoteModel.warehouseAddress = quote?.lastWarehouseAddress;
            newQuoteModel.fromWarehouse = quote?.lastFromWarehouse;
            newQuoteModel.address = quote?.lastAddress;

            delete newQuoteModel?.lastWarehouseAddress;
            delete newQuoteModel?.lastFromWarehouse;
            delete newQuoteModel?.lastAddress;
        }

        setQuote(newQuoteModel);
        setShowSunday({...showSunday, status: false, data: null});
    }

    useEffect(() => {
        if (location.state) {
            setLoader(true);
            setQuote({...location.state, total: location?.state?.paymentMethod === "CAR_INSURANCE" ? 0 : location.state?.total});
            setLoader(false);
        } else {
            navigate("/");
        }
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showSameDay.status ?
                    <ModalSameDay
                        onClose={() => {
                            setShowSameDay({...showSameDay, status: false, data: null});
                            setQuote({...quote, sameDay: false});
                        }}
                        onConfirm={() => {
                            setShowSameDay({...showSameDay, status: false, data: null});
                            setQuote({...quote, sameDay: true});
                        }}
                    />
                : null
            }

            {
                showSunday.status ?
                    <ModalSunday
                        onClose={() => handleSundayClose(null)}
                        onConfirm={() => handleSunday()}
                    />
                : null
            }
            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 p-5 md:p-8">
                            <div className="grid grid-cols-12 px-0 md:px-5">
                                <div>
                                    <button className="p-1 rounded-md" type="button" onClick={() => navigate(-1)}>
                                        <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                    </button>
                                </div>
                                <div className="flex justify-center items-center col-span-11 text-center">
                                    <h1 className="text-xl text-ag-secondary md:mr-[8%]">Reschedule your appointment</h1>
                                </div>
                            </div>
                            <div className="pt-3 lg:flex lg:justify-center lg:items-start lg:space-x-10 space-y-5 lg:space-y-0">
                                <div className="w-full lg:w-[65%]">
                                    <ScheduleQuote
                                        shippingMethod={quote?.shippingMethod}
                                        warehouseID={quote?.fromWarehouse}
                                        sameDayValue={quote?.sameDay}
                                        dayValue={quote?.day}
                                        onDay={(daySelected) => handleDay(daySelected)}
                                        onHour={(hourSelected) => hourSelected !== null ? setQuote({...quote, hour: hourSelected}) : setQuote({...quote, hour: null})}
                                        onSame={() => setShowSameDay({...showSameDay, status: true, data: null})}
                                        onSunday={(sndVal, daySel) => handleShowSunday(sndVal, daySel)}
                                    />
                                </div>
                                <div className="w-full lg:w-[35%]">
                                    <InformationQuote quote={quote} onContinue={handleSchedule} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Reschedule;