import { IoMdImage } from "react-icons/io";
import { RiErrorWarningLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { formatCurrency } from "../../../utils/Utilities";

const statusQuote = {
    approved: { text: "Approved", textColor: "text-ag-secondary-letter", bgColor: "bg-green-600", borderColor: "border-green-600" },
    canceled: { text: "Canceled", textColor: "text-ag-primary-light", bgColor: "bg-ag-primary-light", borderColor: "border-ag-primary-light" },
    inprocess: { text: "In process", textColor: "text-ag-secondary-letter", bgColor: "bg-ag-secondary", borderColor: "border-ag-secondary"},
    scheduled: { text: "Scheduled", textColor: "text-ag-secondary-letter", bgColor: "bg-blue-500", borderColor: "border-blue-500"}
}

const ListQuotes = ({ quotes, onCancel, onDelete }) => {
    return (
        <div className="space-y-5">
            {
                quotes.length > 0 ?
                    quotes.map((quote, i) => (
                        <div className="w-full lg:flex lg:justify-between lg:items-center p-3 lg:p-0 border border-ag-secondary-light rounded-xl" key={i}>
                            <div className="w-full lg:w-[15%] xl:w-[15%] flex justify-center text-center text-ag-secondary-letter">
                                {
                                    quote?.photos.length > 0 ?
                                        <div className="w-[150px] h-[150px] flex justify-center items-center rounded-xl p-1">
                                            <img className="w-full h-full object-contain rounded-xl" src={quote?.photos[0]} />
                                        </div>
                                    :
                                        <span className="text-[70px]"><IoMdImage /></span>
                                }
                            </div>
                            <div className="w-full lg:w-[32%] xl:w-[35%] text-center text-sm md:text-xs lg:text-sm xl:text-base">
                                <div className="xl:flex xl:justify-center space-x-3 px-3 py-3 xl:py-8 text-ag-secondary-letter">
                                    <div className="flex justify-center space-x-3 px-3 py-3 lg:py-8 text-ag-secondary-letter">
                                        <p className="font-semibold">{ quote.year ? "Year:" : "Type:" }</p>
                                        <p className="truncate">{ quote.year ? quote.year : "Per photo..." }</p>
                                    </div>
                                    <div className="flex justify-center space-x-3 px-3 py-8 text-ag-secondary-letter">
                                        <p className="font-semibold">{ quote.brand ? "Make" : "" }</p>
                                        <p className="truncate">{ quote.brand ? quote.brand : "" }</p>
                                    </div>
                                </div>
                                <div className={`flex justify-center space-x-3 px-3 py-8 text-white border ${statusQuote[quote.status].borderColor} ${statusQuote[quote.status].bgColor}`}>
                                    <p className="font-semibold">Request:</p>
                                    <p className="truncate">{ statusQuote[quote.status].text }</p>
                                </div>
                            </div>
                            <div className="w-full lg:w-[32%] xl:w-[35%] text-center text-sm md:text-xs lg:text-sm xl:text-base text-ag-secondary-letter">
                                <div className="xl:flex xl:justify-center space-x-3 px-3 py-3 xl:py-8 text-ag-secondary-letter">
                                    <div className="flex justify-center space-x-3 px-3 py-3 lg:py-8 text-ag-secondary-letter">
                                        <p className="font-semibold">{ quote.model ? "Model:" : "Type:" }</p>
                                        <p className="truncate">{ quote.model ? quote.model : "Per photo..." }</p>
                                    </div>
                                    <div className="flex justify-center space-x-3 px-3 py-8 text-ag-secondary-letter">
                                        <p className="font-semibold">{ quote.style ? "Style" : "" }</p>
                                        <p className="truncate">{ quote.style ? quote.style : "" }</p>
                                    </div>
                                </div>
                                <div className={`flex justify-center space-x-3 px-3 py-8 ${statusQuote[quote.status].textColor} border ${statusQuote[quote.status].borderColor}`}>
                                    <span className="font-semibold">{ quote.status === "canceled" ? "Reason:" : "Price:" }</span>
                                    <span className="truncate" title={quote?.reasonCancel}>
                                        { quote?.status === "canceled" ? quote?.reasonCancel : quote?.selectProduct ? formatCurrency(quote?.selectProduct?.total) : "---" }
                                    </span>
                                </div>
                            </div>
                            <div className="w-full lg:w-[21%] xl:w-[15%] p-5">
                                {
                                    quote.status === "approved" ?
                                        <Link to={`/quotes/detail/${quote._id}/shipping`}>
                                            <button className="w-full px-4 py-2 bg-green-600 text-base md:text-xs lg:text-sm xl:text-base text-white rounded-xl disabled:bg-ag-secondary-light" type="button" disabled={quote?.selectProduct?.productAvailable === false ? true : false}>
                                                Schedule appointment
                                            </button>
                                        </Link>
                                    : quote.status === "inprocess" ?
                                        <button className="w-full px-4 py-2 bg-ag-primary-light text-base md:text-xs lg:text-sm xl:text-base text-white rounded-xl" type="button" onClick={() => onCancel(quote._id)}>
                                            Cancel
                                        </button>
                                    : quote.status === "canceled" ?
                                        <button className="w-full px-4 py-2 bg-ag-primary-light text-base md:text-xs lg:text-sm xl:text-base text-white rounded-xl" type="button" onClick={() => onDelete(quote._id)}>
                                            Delete
                                        </button>
                                    : null
                                }
                            </div>
                        </div>
                    ))
                :
                <div className="w-full flex justify-center items-center space-x-3 pt-[15%] text-center text-ag-secondary-letter">
                    <div>
                        <span className="flex justify-center pb-3 text-2xl"><RiErrorWarningLine /></span>
                        <span className="text-xs italic md:text-base">
                            You currently do not have any quotes in the system...
                        </span>
                    </div>
                </div>
            }
        </div>
    );
}

export default ListQuotes;