import { BsFillBellFill } from "react-icons/bs";
import { MdMenuOpen, MdShoppingCart } from "react-icons/md";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { PermitsContext } from "../../services/Permits";
import LanguageIcon from "../../assets/icons/languaje_icon.png";
import Logo from "../../assets/images/logo_a.png";
import LogoAux from "../../assets/images/logo_b.png";

const NavbarClients = ({ type, sidebarOpen, setSidebarOpen }) => {
    const navigate = useNavigate();
    const { cartValue, getCurrentCart } = useContext(PermitsContext);

    useEffect(() => {
        let infoUserAux = JSON.parse(window.localStorage.getItem("user-data"));
        if (infoUserAux.userType === "WHOLESALER_USER") getCurrentCart(infoUserAux.idAdmin);
    }, []);

    return (
        <div className="relative fixed">
                <div className="bg-ag-secondary-light">
                    <div className={`mx-auto px-[25px] ${ !sidebarOpen ? "py-[6px]" : "py-[32px]" }`}>
                        <div className="flex justify-between items-center">
                            {
                                !sidebarOpen ?
                                    <div className="flex justify-between items-center">
                                        <div className="">
                                            <button aria-expanded={sidebarOpen} onClick={(e) => { setSidebarOpen(!sidebarOpen); }}>
                                                <span className="text-2xl md:text-3xl text-white"><MdMenuOpen /></span>
                                            </button>
                                        </div>
                                        <div className="pl-5">
                                            <img className={`h-auto ${ type === "retail" ? "w-[90px] md:w-[153px]" : "w-[130px] md:w-[220px]"} m-auto`} src={type === "retail" ? LogoAux : Logo} />
                                        </div>
                                    </div>
                                :
                                <div className="pl-[270px]">
                                    <button aria-expanded={sidebarOpen} onClick={(e) => { setSidebarOpen(!sidebarOpen); }}>
                                        <span className="text-2xl md:text-3xl text-white"><MdMenuOpen /></span>
                                    </button>
                                </div>
                            }
                            <div className="flex items-center justify-end space-x-3 md:space-x-7 pr-0 md:pr-10">
                                {/* <button type="button">
                                    <span className="text-3xl text-white">
                                        <img className="w-[30px] md:w-[40px] m-auto" src={LanguageIcon} />
                                    </span>
                                </button> */}
                                <button type="button" onClick={() => navigate(`/profile`)}>
                                    <span className="text-2xl md:text-3xl text-white"><FaUserCircle /></span>
                                </button>
                                <button type="button">
                                    <span className="text-2xl md:text-3xl text-white"><BsFillBellFill /></span>
                                </button>
                                {
                                    type !== "retail" ?
                                    <button type="button" onClick={() => navigate(`/mycart`)}>
                                        <span className="text-2xl md:text-3xl text-white"><MdShoppingCart /></span>
                                        {
                                            cartValue > 0 ?
                                                <div className="absolute top-7 right-14 mr-0 bg-red-500 rounded-xl">
                                                    <span className="flex justify-center items-center px-[6px] py-[2px] text-white text-[9px]">{ cartValue }</span>
                                                </div>
                                            : null
                                        }
                                    </button>
                                        // <button type="button" onClick={() => navigate(`/${type}/mycart`)}>
                                        //     <span className="text-2xl md:text-3xl text-white"><MdShoppingCart /></span>
                                        // </button>
                                    : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default NavbarClients;