import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { toast } from "react-toastify";
import { alertOptions } from "../../utils/Utilities";
import { shippingOptionQuoteRetail } from "../../services/Quotes";
import { getWarehouses } from "../../services/Warehouse";
import Loader from "../../components/loader/Loader";
import NavbarFree from "../../components/navbar/NavbarFree";
import NewAddress from "../fragments/NewAddress";
import FormShippingMethod from "../retail/quotes/shippingMethod/FormShippingMethod";
import InformationQuote from "../retail/quotes/details/InformationQuote";

const MyQuotesShipping = () => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const [loader, setLoader] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isRefreshAddress, setIsRefreshAddress] = useState(false);
    const [quote, setQuote] = useState(null);

    const handleShippingMethod = (shippingObject) => {
        if (shippingObject.shippingMethod === "SHIP") {
            getWarehouses().then(res => {
                if (res.status === 200) {
                    const warehousesAvailable = res.data.filter(element => !element?.deleted);
                    const foundWarehouse = warehousesAvailable[Math.floor(Math.random() * warehousesAvailable.length)];
                    setQuote({...quote,
                        shippingMethod: shippingObject.shippingMethod,
                        costShipping: shippingObject.costShipping,
                        fromWarehouse: { _id: foundWarehouse?._id },
                        userAddress: { _id: shippingObject.id },
                        address: shippingObject.address
                    });
                }
            });
        } else {
            setQuote({...quote,
                shippingMethod: shippingObject.shippingMethod,
                costShipping: shippingObject.costShipping,
                fromWarehouse: { _id: shippingObject.idWarehouse },
                warehouseAddress: { _id: shippingObject.id },
                address: shippingObject.address
            });
        }
    }

    const handleContinue = () => {
        if (quote.shippingMethod !== "" && quote.costShipping >= 0) {
            setLoader(true);
            const body = {
                shippingMethod: quote.shippingMethod,
                costShipping: quote.costShipping,
                fromWarehouse: quote.fromWarehouse
            }

            if (quote.shippingMethod === "SHIP") {
                body.userAddress = quote.userAddress;
            } else {
                body.warehouseAddress = quote.warehouseAddress;
            }

            shippingOptionQuoteRetail(params.id, body).then(res => {
                if (res.status === 200) {
                    setLoader(false);
                    navigate(`/myQuotes/detail/${params.id}/schedule`, { state: quote });
                } else {
                    setLoader(false);
                    toast.warning(res.response.data.message, alertOptions);
                }
            }).catch(error => {
                setLoader(false);
                toast.warning(error.response.data.message, alertOptions);
            });
        } else {
            toast.warning("Please select the shipping method and the address of the appointment", alertOptions);
        }
    }

    const handleResult = () => {
        setShowModal(false);
        setLoader(false);
        setIsRefreshAddress(true);
        toast.success("Address saved successfully", alertOptions);
    }

    useEffect(() => {
        if (location.state) {
            setLoader(true);
            setQuote(location.state);
            setLoader(false);
        } else {
            navigate("/myQuotes");
        }
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            {
                showModal ?
                    <NewAddress
                        idUser={""}
                        typeUser="retail"
                        onClose={() => setShowModal(false)}
                        onLoader={(sts) => setLoader(sts)}
                        onResult={() => handleResult()}
                    />
                : null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarFree />
                    </div>
                </div>
                <div className="w-full overflow-auto flex-grow mt-[100px]">
                    <div className="space-y-5 p-5 md:p-8">
                        <div className="grid grid-cols-12 px-0 md:px-5">
                            <div>
                                <button className="p-1 rounded-md" type="button" onClick={() => navigate(-1)}>
                                    <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                </button>
                            </div>
                            <div className="flex justify-center items-center col-span-11 text-center">
                                <h1 className="text-xl text-ag-secondary md:mr-[8%]">Mobile Service</h1>
                            </div>
                        </div>
                        <div className="pt-3 lg:flex lg:justify-center lg:items-start lg:space-x-10 space-y-5 lg:space-y-0">
                            <div className="w-full lg:w-[65%]">
                                <FormShippingMethod
                                    idRetail={""}
                                    quoteDetail={quote}
                                    onModal={() => setShowModal(true)}
                                    onMethod={handleShippingMethod}
                                    onRefreshAddress={isRefreshAddress}
                                    onResponseRefreshAddress={(sts) => setIsRefreshAddress(sts)}
                                />
                            </div>
                            <div className="w-full lg:w-[35%]">
                                <InformationQuote quote={quote} onContinue={handleContinue} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyQuotesShipping;