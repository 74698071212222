import { _delete, _get, _getFree, _post, _put } from "./http";

export const getWarehouse = (id) => _get(`/warehouse/${id}`);

export const getWarehouseRack = (idW, idR) => _get(`/warehouse/${idW}/rack/${idR}`);

export const getWarehouses = () => _getFree(`/warehouse`);

export const getWarehousesRack = (idW) => _get(`/warehouse/${idW}/rack`);
 
export const getAllAvailableProducts = (idW) => _get(`/warehouse/${idW}/allAvailableProducts`);

export const getAllAvailableProductsGroupRack = (idW) => _get(`/warehouse/${idW}/allAvailableProductsGroupRack`);

export const saveWarehouse = (warehouseObject) => _post(`/warehouse`, warehouseObject);

export const saveWarehouseRack = (idW, warehouseObject) => _post(`/warehouse/${idW}/rack`, warehouseObject);

export const updateWarehouse = (id, warehouseObject) => _put(`/warehouse/${id}`, warehouseObject);

export const updateWarehouseRack = (idW, idR, warehouseObject) => _put(`/warehouse/${idW}/rack/${idR}`, warehouseObject);

export const deleteWarehouse = (id) => _delete(`/warehouse/${id}`);

export const deleteWarehouseRack = (idW, idR) => _delete(`/warehouse/${idW}/rack/${idR}`);