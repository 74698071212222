import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    let sessionState = false;
    // let typeUser = "";
    if (localStorage.getItem("user-data") !== null) sessionState = true;
    // if (localStorage.getItem("user-data") !== null) {
    //     if (JSON.parse(localStorage.getItem("user-data"))['userType'] === "RETAIL_USER") {
    //         typeUser = "retail";
    //     } else if (JSON.parse(localStorage.getItem("user-data"))['userType'] === "WHOLESALER_USER") {
    //         typeUser = "wholesaler";
    //     }
    //     sessionState = true;
    // }
    return sessionState ? <Outlet /> : <Navigate to={`/${localStorage.getItem("type-data")}`} />;
};

export default PrivateRoute;