import { IoMdCloseCircle } from "react-icons/io";
import IconTimer from "../../../assets/icons/timer.svg";

const QuoteInProcess = ({ onClose }) => {
    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-40 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[400px] lg:w-[450px]">
                        <div className="p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="pl-7 text-xl text-ag-secondary font-bold">
                                        Order in process
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-2 md:px-5 pb-3 pt-5 space-y-5">
                                <div className="pt-2">
                                    <img className="m-auto" src={IconTimer} />
                                </div>
                                <div className="py-3 space-y-3 text-center text-ag-secondary-letter text-base">
                                    <div>
                                        <span>
                                            Your order is being processed and you can check the status in your email.
                                        </span>
                                    </div>
                                    <div>
                                        <span>Thanks for waiting.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="px-2 md:px-5 w-full flex justify-center">
                                <button
                                    className="w-full bg-ag-primary-light text-center text-white p-2 rounded-xl disabled:bg-zinc-200 m-auto"
                                    type="button"
                                    onClick={() => onClose(false)}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-30 bg-black"></div>
        </>
    );
}

export default QuoteInProcess;