import { useContext, useEffect, useState } from "react";
import { getProductEspecificationsBrands, getProductEspecificationsModel, getProductEspecificationsTypec, getProductEspecificationsTypeg, getProductEspecificationsYears } from "../../../../services/Product";
import { toast } from "react-toastify";
import { alertOptions } from "../../../../utils/Utilities";
import { PermitsContext } from "../../../../services/Permits";
import { useNavigate } from "react-router-dom";
import ImageCar from "../../../../assets/images/logo_b.png";
import BannerQuoteByPhoto from "../BannerQuoteByPhoto";
import ModalVinNumber from './ModalVinNumber';
import { HiQuestionMarkCircle } from "react-icons/hi";
import { MdOutlineDoubleArrow } from "react-icons/md";

const StepOneForm = ({ type, setShowModalRequest }) => {
  const navigate = useNavigate();
  const { searchData, setSearchData, typexOptionsBase, typexOptions, setTypexOptions, typeGlassOptionsPWRBase, setTypeGlassOptionsPWRBase } = useContext(PermitsContext);
  const [yearsOptions, setYearsOptions] = useState([]);
  const [makesOptions, setMakesOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [typecOptions, setTypecOptions] = useState([]);
  const [vinModal, setVinModal] = useState(false);


  const handleChange = (evt) => {
    if (evt.target.name === "year") {
      getBrands(evt.target.value);
      setSearchData({ ...searchData, year: evt.target.value, brand: "", model: "", style: "", typeGlass: "" });
    } else if (evt.target.name === "brand") {
      getModels(searchData.year, evt.target.value);
      setSearchData({ ...searchData, brand: evt.target.value, model: "", style: "", typeGlass: "" });
    } else if (evt.target.name === "model") {
      getTypec(searchData.year, searchData.brand, evt.target.value);
      setSearchData({ ...searchData, model: evt.target.value, style: "", typeGlass: "" });
    } else if (evt.target.name === "style") {
      getTypeg(searchData.year, searchData.brand, searchData.model, evt.target.value);
      setSearchData({ ...searchData, style: evt.target.value, typeGlass: "" });
    } else {
      if (evt.target.name === "vin" && String(evt.target.value).match(/^[a-z0-9]*$/i) === null) return;
      setSearchData({ ...searchData, [evt.target.name]: evt.target.value });
    }
  };

  const handleStep = async (viewType) => {
    if (viewType === "view1" && (searchData.year !== "" && searchData.brand !== "" && searchData.model !== "" && searchData.style !== "")) {
      if (typexOptions[0].show || typexOptions[1].show || typexOptions[2].show || typexOptions[3].show) {
        navigate(type === "free" ? "/myQuotes/step_two" : "/home/step_two");
      } else {
        toast.warning("No glasses were found with the entered characteristics", alertOptions);
      }
    } else {
      toast.info("Please fill in all the fields of the form", alertOptions);
    }
  };

  const getYears = () => {
    setMakesOptions([]);
    setModelOptions([]);
    setTypecOptions([]);
    getProductEspecificationsYears().then(res => {
      if (res.status === 200) setYearsOptions(res.data);
    });
  };

  const getBrands = (yearParam) => {
    setModelOptions([]);
    setTypecOptions([]);
    getProductEspecificationsBrands(yearParam).then(res => {
      if (res.status === 200) setMakesOptions(res.data);
    });
  };

  const getModels = (yearParam, brandParam) => {
    setTypecOptions([]);
    getProductEspecificationsModel(yearParam, brandParam).then(res => {
      if (res.status === 200) setModelOptions(res.data);
    });
  };

  const getTypec = (yearParam, brandParam, modelParam) => {
    getProductEspecificationsTypec(yearParam, brandParam, modelParam).then(res => {
      if (res.status === 200) setTypecOptions(res.data);
    });
  };

  const getTypeg = (yearParam, brandParam, modelParam, typecParam) => {
    getProductEspecificationsTypeg(yearParam, brandParam, modelParam, typecParam).then(res => {
      if (res.status === 200) {
        const arrTypeGlass = res.data;
        const newTypeXOptions = typexOptionsBase.map(element => {
          const newSons = element.sons.map(elementAux => {
            const sonExist = arrTypeGlass.find(elemSon => elemSon.type_glass === elementAux.value);
            if (sonExist) return { ...elementAux, show: true };
            return { ...elementAux, show: false };
          });

          const showSons = newSons.filter(elemShowSon => elemShowSon.show);
          if (showSons.length > 0) return { ...element, show: true, sons: newSons };
          return { ...element, show: false, sons: [] };
        });
        let newTypeGlassOptionsPWRBase = [];
        typeGlassOptionsPWRBase.forEach(element => {
          const match = arrTypeGlass.find(elemSon => elemSon.type_glass === element.value);
          if (match){
            newTypeGlassOptionsPWRBase.push({ ...element });
          }
        });
        setTypexOptions(newTypeXOptions);
        setTypeGlassOptionsPWRBase(newTypeGlassOptionsPWRBase);
      }
    });
  };

  useEffect(() => {
    if (searchData.year !== "") getBrands(searchData.year);
    if (searchData.brand !== "") getModels(searchData.year, searchData.brand);
    if (searchData.model !== "") getTypec(searchData.year, searchData.brand, searchData.model);
    if (searchData.style !== "") getTypeg(searchData.year, searchData.brand, searchData.model, searchData.style);

  }, [searchData]);

  useEffect(() => getYears(), []);

  return (
    <>
      <div className="flex justify-center items-center space-x-5 pb-3 border-b-2 border-ag-primary">
        <h1 className="font-bold text-[23px] md:text-xl lg:text-2xl text-ag-secondary text-center">
          Welcome to Avondale Auto Glass
        </h1>
      </div>

      <div>
        <div className="flex justify-center items-center space-x-5 pt-8 pb-5">
          <h1 className="text-2xl md:text-xl lg:text-2xl font-light text-ag-secondary">
            Tell us about your vehicle
          </h1>
        </div>

        <div className="text-center text-ag-secondary-letter mb-[20px]">
          <p>
            Find your vehicle by entering the year, make, model and style
          </p>

          <p className="flex justify-center items-center gap-[10px] mt-[15px]">
            You can also enter the vehicle identification number (VIN)

            <HiQuestionMarkCircle className="text-ag-primary-light text-3xl cursor-pointer" onClick={() => setVinModal(true)} />
          </p>
        </div>

        <div className="flex flex-col lg:flex-row lg:justify-center gap-[10px] text-center mb-[40px] md:mb-[70px]">
          <a
            href="#serviceQuote"
          >
            <button
              className="font-bold border-2 border-ag-primary-light px-[50px] md:px-[80px] hover:bg-ag-primary-light text-ag-primary-light hover:text-white py-2 rounded-xl disabled:bg-zinc-200"
            >
              <div className="flex items-center gap-[10px] text-[14px] md:text-[16px]">
                  Auto Glass Service Quote

                <MdOutlineDoubleArrow className="rotate-90 text-2xl" />
              </div>
            </button>
          </a>

          <a
            href="#tint&repair"
          >
            <button
              className="font-bold border-2 border-ag-primary-light px-[10px] md:px-[40px] hover:bg-ag-primary-light text-ag-primary-light hover:text-white py-2 rounded-xl disabled:bg-zinc-200"
            >
              <div className="flex items-center gap-[10px] text-[14px] md:text-[16px]">
                  Window Tint & Power Window Repair Quote

                <MdOutlineDoubleArrow className="rotate-90 text-2xl" />
              </div>
            </button>
          </a>
        </div>

        <BannerQuoteByPhoto setShowModalRequest={setShowModalRequest} />

        <div className="flex flex-col lg:gap-[10px]">
          <div id="serviceQuote" className="flex-1 mt-[100px]">
            <div className="text-center text-ag-secondary-letter mt-5 font-bold">
              <span>
                For Auto Glass Service Quote.
              </span>
            </div>

            <div className="text-center text-ag-secondary-letter mt-[10px]">
              <span className="font-bold text-[#c50d0dc9] text-[20px]">
                *If your vehicle needs calibration programming, it may not be included in the quote.
              </span>
            </div>

            <div className="text-center webkit-center">
              <iframe
                title='extension'
                sandbox="allow-scripts allow-forms allow-popups allow-presentation allow-top-navigation allow-modals allow-same-origin"
                frameBorder="0"
                height="500"
                id="fraMain"
                name="fraMain"
                src="https://7942.edirectglass.com/scheduler/edgmain.asp"
                style={{ maxWidth: 800, marginTop: 0 }}
                target="fraMain"
                width="100%"
              >
              </iframe>
            </div>
          </div>

          <div className="border-t-2 border-ag-secondary-light space-y-3">
          </div>

          <div id="tint&repair" className="flex-1 mt-[40px]">
            <div className="text-center text-ag-secondary-letter mb-3 font-bold">
              <span>
                For Window Tint and Power Window Repair.
              </span>
            </div>

            <div className="grid md:flex md:justify-center md:items-start md:space-x-8 pt-10">
              <div className="lg:w-[40%] md:w-[60%]">
                <div>
                  <div className="w-full md:w-[80%] lg:w-[65%] grid gap-x-3 gap-y-2 m-auto">
                    <div className="text-center">
                      <div className="flex justify-center items-center space-x-2">
                        <label className="text-base text-ag-secondary-letter">VIN</label>

                        <label className="text-xs text-ag-secondary-letter">(Vehicle Identification Number):</label>
                      </div>

                      <input
                        className="w-full text-center text-ag-secondary-letter border border-gray-500 pl-1 pr-3 py-2 rounded-xl disabled:bg-zinc-200 truncate"
                        type="text"
                        name="vin"
                        placeholder="VIN (optional)"
                        onChange={handleChange}
                        value={searchData.vin}
                        maxLength={17}
                      />
                    </div>

                    <div>
                      <div className="text-center">
                        <label className="text-base text-ag-secondary-letter">Year:</label>
                      </div>

                      <select
                        className={`w-full text-center ${searchData.year === "" ? "text-[#C7C7C7]" : "text-ag-secondary-letter"} border border-gray-500 p-[9px] rounded-xl disabled:bg-zinc-200 truncate`}
                        name="year"
                        onChange={handleChange}
                        placeholder="Year"
                        value={searchData.year}
                        disabled={searchData.searchType === "photos" ? true : false}
                      >
                        <option value="">Year</option>
                        {
                          yearsOptions.map((element, index) => {
                            return <option className="text-ag-secondary-letter" key={index} value={element.year}>{element.year}</option>;
                          })
                        }
                      </select>
                    </div>

                    <div>
                      <div className="text-center">
                        <label className="text-base text-ag-secondary-letter">Make:</label>
                      </div>

                      <select
                        className={`w-full text-center ${searchData.brand === "" ? "text-[#C7C7C7]" : "text-ag-secondary-letter"} border border-gray-500 p-[9px] rounded-xl disabled:bg-zinc-200 truncate`}
                        name="brand"
                        onChange={handleChange}
                        placeholder="Make"
                        value={searchData.brand}
                        disabled={searchData.searchType === "photos" ? true : false}
                      >
                        <option value="">Make</option>
                        {
                          makesOptions.map((element, index) => {
                            return <option className="text-ag-secondary-letter" key={index} value={element.brand}>{element.brand}</option>;
                          })
                        }
                      </select>
                    </div>

                    <div>
                      <div className="text-center">
                        <label className="text-base text-ag-secondary-letter">Model:</label>
                      </div>

                      <select
                        className={`w-full text-center ${searchData.model === "" ? "text-[#C7C7C7]" : "text-ag-secondary-letter"} border border-gray-500 p-[9px] rounded-xl disabled:bg-zinc-200 truncate`}
                        name="model"
                        onChange={handleChange}
                        placeholder="Model"
                        value={searchData.model}
                        disabled={searchData.searchType === "photos" ? true : false}
                      >
                        <option value="">Model</option>
                        {
                          modelOptions.map((element, index) => {
                            return <option className="text-ag-secondary-letter" key={index} value={element.model}>{element.model}</option>;
                          })
                        }
                      </select>
                    </div>

                    <div>
                      <div className="text-center">
                        <label className="text-base text-ag-secondary-letter">Style:</label>
                      </div>

                      <select
                        className={`w-full text-center ${searchData.style === "" ? "text-[#C7C7C7]" : "text-ag-secondary-letter"} border border-gray-500 p-[9px] rounded-xl disabled:bg-zinc-200 truncate`}
                        name="style"
                        onChange={handleChange}
                        placeholder="Style"
                        value={searchData.style}
                        disabled={searchData.searchType === "photos" ? true : false}
                      >
                        <option value="">Style</option>
                        {
                          typecOptions.map((element, index) => {
                            return <option className="text-ag-secondary-letter" key={index} value={element.type_car}>{element.type_car}</option>;
                          })
                        }
                      </select>
                    </div>
                  </div>
                </div>

                <div className="mt-5 py-10 border-t-2 border-ag-primary space-y-3">
                  <div className="flex justify-between items-center">
                    <button
                      className="w-full bg-ag-primary-light text-white px-3 py-2 rounded-xl disabled:bg-zinc-200"
                      type="button"
                      onClick={() => handleStep("view1")}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5 pb-5 mt-[40px]">
        <img className="w-72 h-auto m-auto" src={ImageCar} alt='' />
      </div>

      <div className="pt-5 text-center">
        <p className="text-ag-secondary-letter text-xs md:text-sm font-semibold italic">
          ** To get an accurate quote please provide us with your VIN we will contact you shortly
        </p>

        <a href="mailto:glassquote1@gmail.com" className="text-blue-800 text-xs md:text-sm">
          glassquote1@gmail.com
        </a>

        <p className="text-ag-secondary-letter text-xs md:text-sm italic">
          623-337-3352
        </p>
      </div>

      <ModalVinNumber
        show={vinModal}
        onClose={() => setVinModal(false)}
      />
    </>
  );
};

export default StepOneForm;