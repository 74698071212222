import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAddresses } from "../../../../services/RetailAddress";
import { getWarehouses } from "../../../../services/Warehouse";
import { getCostdeliveryFee } from "../../../../services/ChangeDeliberyFee";
import { alertOptions } from "../../../../utils/Utilities";

const FormShippingMethod = ({ idRetail, quoteDetail, onModal, onMethod, onRefreshAddress, onResponseRefreshAddress }) => {
    const [showMethod, setShowMethod] = useState({ view1: false, view2: false });
    const [addresses, setAddresses] = useState([]);
    const [costShip, setCostShip] = useState(0);

    const handleAddress = (idAddress, addressAux, type, costShipValue) => {
        let addressCurrent = null;
        const newAddress = addressAux.map(addressElement => {
            if (addressElement._id === idAddress._id) {
                addressCurrent = addressElement;
                return { ...addressElement, status: true };
            }
            return { ...addressElement, status: false };
        });
        setAddresses(newAddress);
        onMethod({
            shippingMethod: type === "PICK_UP" ? "PICK_UP" : "SHIP",
            costShipping: type === "PICK_UP" ? 0 : (costShipValue === 0 ? costShip : costShipValue),
            id: idAddress._id,
            idWarehouse: idAddress?._idWarehouse || quoteDetail?.productInventoryAllInfo?.warehouse?._id || "",
            address: addressCurrent
        });
    };

    const updateAddresses = (addresses, costShipValue) => {
        if (addresses.length) {
            let currentAddress = addresses.find(address => address.selected) || addresses[0];
            setAddresses(addresses);
            handleAddress(currentAddress, addresses, "SHIP", costShipValue);
        } else {
            setAddresses([]);
        }
    };

    const getLocalAddresses = () => {
        const addresses = JSON.parse(window.localStorage.getItem("user-data-address"));
        return addresses || [];
    };

    const refreshAddress = async (costShipValue) => {
        const localAddresses = getLocalAddresses();
    
        if (idRetail === '') {
            if (!localAddresses.length) {
                toast.warning("Please add a shipping address", alertOptions);
                setAddresses([]);
            } else {
                updateAddresses(localAddresses, costShipValue);
            }
        } else {
            const response = await getAddresses(idRetail);
            if (response.status === 200 && response.data.length) {
                const serverAddresses = response.data.map(element => ({
                    ...element,
                    status: element.selected ? true : false
                }));
                updateAddresses(serverAddresses, costShipValue);
            } else {
                updateAddresses(localAddresses, costShipValue);
            }
        }
    };

    const handleMethod = async (type) => {
        if (type === "ship") {
            const res = await getCostdeliveryFee('RETAIL_USER');
            if (res.status === 200) {
                setCostShip(res.data.cost);
                refreshAddress(res.data.cost);
            } else {
                refreshAddress(0);
            }
            setShowMethod({ view1: true, view2: false });
        } else {
            const res = await getWarehouses();
            if (res.status === 200 && res.data.length) {
                const warehousesCurrent = res.data.filter(element => !element.deleted);
                let currentAddress = null;
                const foundLocations = warehousesCurrent.map(elementLocation => {
                    if (elementLocation._id === quoteDetail?.productInventoryAllInfo?.warehouse?._id) {
                        currentAddress = { ...elementLocation.address, _idWarehouse: elementLocation._id, status: true };
                        return currentAddress;
                    }
                    return { ...elementLocation.address, _idWarehouse: elementLocation._id };
                });
                if (!currentAddress && foundLocations.length) {
                    currentAddress = { ...foundLocations[0], status: true };
                }
                setAddresses(foundLocations);
                handleAddress(currentAddress, foundLocations, "PICK_UP", 0);
            } else {
                toast.warning("Please select a warehouse for In Shop", alertOptions);
            }
            setShowMethod({ view1: false, view2: true });
        }
    };

    useEffect(() => {
        if (onRefreshAddress) {
            refreshAddress(0);
            onResponseRefreshAddress(false);
        }
    }, [onRefreshAddress]);

    return (
        <div className="space-y-3">
            <div className={`w-full p-4 border rounded-xl ${showMethod.view1 ? "border-ag-primary" : "border-ag-secondary-light"}`}>
                <div className="flex justify-start items-center space-x-10 px-3">
                    <div>
                        <input
                            className="w-4 h-4 accent-ag-primary cursor-pointer"
                            type="radio"
                            name="shipping_method_retail"
                            onClick={() => handleMethod("ship")}
                        />
                    </div>
                    <div>
                        <span>Mobile Service</span>
                    </div>
                </div>
                {
                    showMethod.view1 ?
                        <div className="mt-3 pt-3 space-y-5 border-t border-ag-secondary-light">
                            <div className="px-16 text-ag-secondary-letter">
                                <span className="px-1">Address</span>
                            </div>
                            {
                                addresses.map((addressElement, i) => (
                                    <div className={`flex justify-center items-center space-x-3 md:space-x-0 px-3 py-2 border rounded-xl ${addressElement?.status ? "border-ag-primary" : "border-ag-secondary-light"}`} key={i}>
                                        <div className="w-[7%]">
                                            <input
                                                className="w-4 h-4 accent-ag-primary cursor-pointer"
                                                type="radio"
                                                name="shipping_method_address_retail"
                                                onClick={() => handleAddress(addressElement, addresses, "SHIP", 0)}
                                                checked={addressElement?.status}
                                            />
                                        </div>
                                        <div className="w-[93%] text-ag-secondary-letter text-xs md:text-base">
                                            <ul>
                                                <li><p className="truncate w-full">{ addressElement.street }, { addressElement.city },</p></li>
                                                <li><p className="truncate w-full">{ addressElement.suburb }, { addressElement.postalCode }</p></li>
                                                <li><p className="truncate w-full">{ addressElement.references }</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                ))
                            }
                            <div className="px-10 md:px-14">
                                <button className="text-ag-secondary-letter" type="button" onClick={() => onModal(true)}>
                                    + Save new address
                                </button>
                            </div>
                        </div>
                    : null
                }
            </div>
            <div className={`w-full p-4 border rounded-xl ${showMethod.view2 ? "border-ag-primary" : "border-ag-secondary-light"}`}>
                <div className="flex justify-start items-center space-x-10 px-3">
                    <div>
                        <input
                            className="w-4 h-4 accent-ag-primary cursor-pointer"
                            type="radio"
                            name="shipping_method_retail"
                            onClick={() => handleMethod("pickup")}
                        />
                    </div>
                    <div>
                        <span>In Shop</span>
                    </div>
                </div>
                {
                    showMethod.view2 ?
                        <div className="mt-3 pt-3 space-y-5 border-t border-ag-secondary-light">
                            <div className="px-16 text-ag-secondary-letter">
                                <span className="px-1">Locations</span>
                            </div>
                            {
                                addresses.map((addressElement, i) => (
                                    <div className={`flex justify-center items-center space-x-3 md:space-x-0 px-3 py-2 border rounded-xl ${addressElement?.status ? "border-ag-primary" : "border-ag-secondary-light"}`} key={i}>
                                        <div className="w-[7%]">
                                            <input
                                                className="w-4 h-4 accent-ag-primary cursor-pointer"
                                                type="radio"
                                                name="shipping_method_address_retail"
                                                onClick={() => handleAddress(addressElement, addresses, "PICK_UP", 0)}
                                                checked={addressElement?.status}
                                            />
                                        </div>
                                        <div className="w-[93%] text-ag-secondary-letter text-xs md:text-base">
                                            <ul>
                                                <li><p className="truncate w-full">{ addressElement?.street }, { addressElement?.locality },</p></li>
                                                <li><p className="truncate w-full">{ addressElement?.state } { addressElement?.postalCode }</p></li>
                                            </ul>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    : null
                }
            </div>
        </div>
    );
}

export default FormShippingMethod;