import axios from "axios";

const config = () => {
    const token = JSON.parse(localStorage.getItem("user-data"))['token'];
    return {headers: { Authorization: `Bearer ${token}`}}
}

const configParams = (params) => {
    const token = JSON.parse(localStorage.getItem("user-data"))['token'];
    return { headers: { Authorization: `Bearer ${token}`}, params: params }
}

const configDelete = (body) => {
    const token = JSON.parse(localStorage.getItem("user-data"))['token'];
    return {headers: {Authorization: `Bearer ${token}`}, data:body}
}

export const api = axios.create({
    baseURL:process.env.REACT_APP_API_URL
});

export const _get = async (endpoint) =>{
    const { data, status } = await api.get(endpoint,config())
    return { data: data, status: status };
};

export const _getFree = async (endpoint) =>{
    const { data, status } = await api.get(endpoint)
    return { data: data, status: status };
};

export const _getParams = async (endpoint, params) =>{
    const { data, status } = await api.get(endpoint, configParams(params))
    return { data: data, status: status };
};

export const _getParamsFree = async (endpoint, params) =>{
    const { data, status } = await api.get(endpoint, { params: params })
    return { data: data, status: status };
};

export const _post = async (endpoint, body) =>{
    const { data, status } = await api.post(endpoint, body ,config())
    return { data: data, status: status };
};

export const _postFree = async (endpoint, body) =>{
    const { data, status } = await api.post(endpoint, body)
    return { data: data, status: status };
};

export const _put = async(endpoint, body) =>{
    const { data, status } = await api.put(endpoint, body, config())
    return { data: data, status: status };
};

export const _putFree = async (endpoint, body) =>{
    const { data, status } = await api.put(endpoint, body)
    return { data: data, status: status };
};

export const _delete = async (endpoint) =>{
    const { data, status } = await api.delete(endpoint, config())
    return { data: data, status: status };
}

export const _deleteWBody = async (endpoint,body) =>{
    const { data, status } = await api.delete(endpoint, configDelete(body))
    return { data: data, status: status };
}