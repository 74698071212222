import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../services/Permits";
import StepThreeForm from "../../retail/advanced_search/step_three/StepThreeForm";
import ModalFeatures from "../../retail/advanced_search/ModalFeatures";
import NavbarFree from "../../../components/navbar/NavbarFree";

const StepThreeFree = () => {
    const { searchData, setSearchData, distFeatures, setProduOptions, produOptionsUniv } = useContext(PermitsContext);
    const [showModalFeatures, setShowModalFeatures] = useState(false);

    const handleFilters = (arrFeatures, vinValue) => {
        setSearchData({...searchData, vin: vinValue});
        if (arrFeatures.length === 0) {
            setProduOptions(produOptionsUniv);
            setShowModalFeatures(false);
        } else {
            const productsFound = produOptionsUniv.filter(prodElem => {
                let numCoinc = 0;
                for (let i = 0; i < arrFeatures.length; i++) {
                    if (String(prodElem.ad_info_a).toLowerCase().includes(String(arrFeatures[i].value).toLowerCase())) {
                        numCoinc += 1;
                    } else if (arrFeatures[i].value === "Ant" && prodElem.antena === "Y") {
                        numCoinc += 1;
                    } else if (arrFeatures[i].value === "Solar" && prodElem.solar === "Y") {
                        numCoinc += 1;
                    } else if (arrFeatures[i].value === "HUD" && prodElem.hud === "Y") {
                        numCoinc += 1;
                    }
                }
                if (numCoinc === arrFeatures.length) return prodElem;
            });
            setProduOptions(productsFound);
            setShowModalFeatures(false);
        }
    }

    useEffect(() => {
        if (produOptionsUniv.length > 1 && distFeatures.length && searchData.nag === "") {
            setShowModalFeatures(true);
        }
    }, [produOptionsUniv]);

    return (
        <>
            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarFree />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className="w-full overflow-auto flex-grow mt-16 md:mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="pt-3">
                                <StepThreeForm type="free" setShowModalFeatures={setShowModalFeatures} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalFeatures
                showModal={showModalFeatures}
                arrFeatures={distFeatures}
                vinValue={searchData.vin}
                onClose={() => {
                    setShowModalFeatures(false);
                    setProduOptions(produOptionsUniv);
                    setSearchData({...searchData, nag: ""});
                }}
                onAccept={handleFilters}
            />
        </>
    );
}

export default StepThreeFree;