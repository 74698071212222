
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { validateCarInsuranceAux, validateQuote } from "../../../utils/Validators";
import { fileUpload } from "../../../services/File";
import { saveQuote } from "../../../services/Quotes";
import { PermitsContext } from "../../../services/Permits";
import Loader from "../../../components/loader/Loader";
import StepOneForm from "../../retail/advanced_search/step_one/StepOneForm";
import ProductRequest from "../../retail/advanced_search/ProductRequest";
import QuoteInProcessConfirm from "../../retail/advanced_search/QuoteInProcessConfirm";
import NavbarFree from "../../../components/navbar/NavbarFree";

const StepOneFree = () => {
  const { dataSession, setDataSession, searchData, setSearchData } = useContext(PermitsContext);
  const [loader, setLoader] = useState(false);
  const [showModalRequest, setShowModalRequest] = useState(false);
  const [showModalInProcessPP, setShowModalInProcessPP] = useState(false);

  const [paymentMethods] = useState([
    { label: "Pay with Insurance (Free Full Ceramic Windshield Tint)", value: "CAR_INSURANCE", style: "font-semibold" },
    { label: "Pay on my own", value: "PAY_ON_MY_WON", style: "" }
  ]);

  const cleanData = () => {
    setSearchData({
      names: dataSession._id !== "" ? dataSession.names : "",
      lastNames: dataSession._id !== "" ? dataSession.lastNames : "",
      phoneNumber: dataSession._id !== "" ? dataSession.phoneNumber : "",
      email: dataSession._id !== "" ? dataSession.email : "",
      searchType: "",
      vin: "",
      year: "",
      brand: "",
      model: "",
      style: "",
      typeService: "",
      typeGlass: "",
      paymentMethod: "",
      numberOfInsurance: dataSession.numberOfInsurance ? dataSession.numberOfInsurance : "",
      companyInsurance: dataSession.companyInsurance ? dataSession.companyInsurance : "",
      dateInsurance: dataSession.dateInsurance !== "" ? new Date(dataSession.dateInsurance) : "",
      description: "",
      photos: [],
      windshieldVisorStrip: false,
      ceramicFull70: false,
      ceramicFull90: false,
      waterTreatment : false,
      installationType: "",
      nag: ""
    });
  };

  const handleChangePhotos = (photosModel) => {
    new Promise((resolve) => {
      const newSearchModel = {
        searchType: "photos",
        names: photosModel.names,
        lastNames: photosModel.lastNames,
        phoneNumber: photosModel.phoneNumber,
        comments: photosModel.comments,
        email: photosModel.email,
        description: photosModel.description,
        photos: photosModel.photos,
        paymentMethod: photosModel.paymentMethod,
        numberOfInsurance: photosModel.numberOfInsurance,
        companyInsurance: photosModel.companyInsurance,
        dateInsurance:photosModel.dateInsurance,
        vin: "",
        year: "",
        brand: "",
        model: "",
        style: "",
        typeGlass: "",
        typeService: "REPAIR",
        windshieldVisorStrip: false,
        ceramicFull70: false,
        ceramicFull90: false,
        waterTreatment : false,
        installationType: "",
        nag: ""
      };
      resolve(newSearchModel);
    }).then((res) => {
      handleSubmit(res);
    });
  };

  const handleSubmit = async (searchModel) => {
    if (
      searchModel.paymentMethod !== "" && searchModel.paymentMethod !== "default" && (searchModel.paymentMethod !== "CAR_INSURANCE" ||
            (searchModel.paymentMethod === "CAR_INSURANCE" && searchModel.numberOfInsurance !== "" &&
            searchModel.companyInsurance !== "" && searchModel.dateInsurance !== ""))
    ) {
      if (
        searchModel.searchType !== "photos" && searchModel.vin === "" && searchModel.year === "" &&
                searchModel.brand === "" && searchModel.model === "" && searchModel.style === "" && searchModel.typeGlass === ""
      ) {
        toast.info("Please fill in all the fields of the form", alertOptions);
      } else if (searchModel.searchType === "photos" && searchModel.description === "" && searchModel.photos.length === 0) {
        toast.info("Please fill in all the fields of the form", alertOptions);
      } else {
        setLoader(true);
        let quoteObject = {
          paymentMethod: searchModel.paymentMethod,
          numberOfInsurance: searchModel.numberOfInsurance,
          companyInsurance: searchModel.companyInsurance,
          dateInsurance: searchModel.dateInsurance,
          comments: searchModel.comments,
          typeService: searchModel.typeService
        };

        // Upload file
        let quoteValidImages = [];
        for (let i = 0; i < searchModel.photos.length; i++) {
          if (searchModel.photos[i].file !== null && searchModel.photos[i].name !== "") {
            let bodyFormData = new FormData();
            bodyFormData.append('file', searchModel.photos[i].file);
            const valueUpload = await fileUpload(bodyFormData).then(res => {
              if (res.status === 200) return res.data;
              return "";
            });
            quoteValidImages.push(valueUpload);
          } else if (searchModel.photos[i].name !== "") {
            quoteValidImages.push(searchModel.photos[i].name);
          }
        }

        quoteObject.photos = quoteValidImages;
        quoteObject.description = searchModel.description;
        quoteObject.searchType = searchModel.searchType;
        quoteObject.names = searchModel.names;
        quoteObject.lastNames = searchModel.lastNames;
        quoteObject.phoneNumber = searchModel.phoneNumber;
        quoteObject.email = searchModel.email;
        if (quoteObject.paymentMethod === "CAR_INSURANCE") {
          quoteObject.insurance = {
            numberOfInsurance: searchModel.numberOfInsurance,
            companyInsurance: searchModel.companyInsurance,
            dateInsurance: searchModel.dateInsurance,
          };
        }

        if (quoteObject.paymentMethod === "CAR_INSURANCE") {
          if (new Date() <= new Date(quoteObject.dateInsurance)) {
            toast.warning("Loss date must higher than today's date", alertOptions);
            setLoader(false);
            return;
          }

          const resultCar = validateCarInsuranceAux(quoteObject);
          if (!resultCar.status) {
            toast.warning(resultCar.msg, alertOptions);
            setLoader(false);
            return 0;
          }
        }

        const result = validateQuote(quoteObject);
        if (result.status) {
          handleSaveQuote(quoteObject);
        } else {
          setLoader(false);
          toast.warning(result.msg, alertOptions);
        }
      }
    } else {
      toast.info("Please fill in all the fields of the form", alertOptions);
    }
  };

  const handleSaveQuote = (quoteObject) => {
    saveQuote(quoteObject).then(res => {
      if (res.status === 200) {
        if (quoteObject?.paymentMethod === "CAR_INSURANCE") {
          let infoUserAux = JSON.parse(window.localStorage.getItem("user-data"));
          localStorage.setItem("user-data", JSON.stringify({...infoUserAux,
            numberOfInsurance: quoteObject.numberOfInsurance,
            companyInsurance: quoteObject.companyInsurance,
            dateInsurance: quoteObject.dateInsurance
          }));

          setDataSession({...dataSession,
            numberOfInsurance: quoteObject.numberOfInsurance,
            companyInsurance: quoteObject.companyInsurance,
            dateInsurance: quoteObject.dateInsurance
          });
        }

        toast.success("Quote saved successfully", alertOptions);
        setShowModalRequest(false);
        setShowModalInProcessPP(true);
        cleanData();
        setLoader(false);
      } else {
        setLoader(false);
        toast.warning(res.response.data.message ? res.response.data.message : "There was a problem saving the quote. Please try again in a few minutes", alertOptions);
      }
    }).catch((error) => {
      toast.warning(error.response.data.message ? error.response.data.message : "There was a problem saving the quote. Please try again in a few minutes", alertOptions);
    });
  };

  useEffect(() => {
    setSearchData({...searchData,
      numberOfInsurance: dataSession.numberOfInsurance ? dataSession.numberOfInsurance : "",
      companyInsurance: dataSession.companyInsurance ? dataSession.companyInsurance : "",
      dateInsurance: dataSession.dateInsurance !== "" ? new Date(dataSession.dateInsurance) : "",
      names: dataSession.names ? dataSession.names : "",
      lastNames: dataSession.lastNames ? dataSession?.lastNames : "",
      email: dataSession.email ? dataSession.email : "",
      phoneNumber: dataSession.phoneNumber ? dataSession.phoneNumber : ""
    });
  }, [dataSession]);

  return (
    <>
      { loader ? <Loader /> : null }

      {
        showModalRequest ?
          <ProductRequest
            paymentMethods={paymentMethods}
            onSubmit={handleChangePhotos}
            onClose={() => setShowModalRequest(false)}
            informationData={{
              numberOfInsurance: searchData.numberOfInsurance,
              companyInsurance: searchData.companyInsurance,
              dateInsurance: searchData.dateInsurance,
              names: searchData.names,
              lastNames: searchData.lastNames,
              email: searchData.email,
              phoneNumber: searchData.phoneNumber
            }}
          />
          : null
      }

      {
        showModalInProcessPP ?
          <QuoteInProcessConfirm onClose={() => setShowModalInProcessPP(false)} />
          : null
      }

      <div>
        <div className="flex flex-row">
          <div className="w-full bg-no-repeat bg-cover fixed z-10">
            <NavbarFree />
          </div>
        </div>
        <div className="flex h-screen overflow-hidden">
          <div className="w-full animation-scroll overflow-auto flex-grow mt-16 md:mt-[100px]">
            <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
              <div className="pt-3">
                <StepOneForm
                  type="free"
                  paymentMethods={paymentMethods}
                  setShowModalRequest={setShowModalRequest}
                  setLoader={setLoader}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepOneFree;