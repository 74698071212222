import { useContext, useEffect, useState } from "react";
import { MdMenuOpen } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { PermitsContext } from "../../services/Permits";
import { HiHome } from "react-icons/hi";
import { FaUserCircle } from "react-icons/fa";
import SBIconSignOut from "../../assets/icons/sidebar/sb_signout_icon.svg";
import SBIconListA from "../../assets/icons/sidebar/sb_list_a_icon.svg";
import SBIconTicket from "../../assets/icons/sidebar/sb_ticket_icon.svg";
import Logo from "../../assets/images/logo_a.png";
import LogoAux from "../../assets/images/logo_b.png";
import Modal from "../modal/Modal";
import useScreenSize from "../../utils/useScreenSize";

const SidebarClients = ({ type, sidebarOpen, setSidebarOpen, responsive }) => {
    const navigate = useNavigate();
    const { width } = useScreenSize();
    const { dataSession, setDataSession, clearContext } = useContext(PermitsContext);
    const [showModal, setShowModal] = useState({ status: false, title: "", element: null });

    useEffect(() => {
        if (window.localStorage.getItem("user-data") && window.localStorage.getItem("user-data") !== null) {
            let infoUserAux = JSON.parse(window.localStorage.getItem("user-data"));
            if (((type === "retail" && infoUserAux.userType === "RETAIL_USER") || (type === "wholesaler" && infoUserAux.userType === "WHOLESALER_USER")) && !infoUserAux.permissions) {
                setDataSession({
                    ...dataSession,
                    _id: infoUserAux.idAdmin,
                    _idUser: infoUserAux.id,
                    token: infoUserAux.token,
                    names: infoUserAux.names,
                    lastNames: infoUserAux.lastNames,
                    email: infoUserAux.email,
                    phoneNumber: infoUserAux.phoneNumber,
                    zipCode: infoUserAux.zipCode,
                    userType: infoUserAux.userType,
                    numberOfInsurance: infoUserAux.numberOfInsurance,
                    companyInsurance: infoUserAux.companyInsurance,
                    dateInsurance: infoUserAux.dateInsurance
                });
            } else {
                navigate(`/`);
            }
        } else {
            navigate(`/`);
        }
    }, []);

    useEffect(() => {
        if (width <= 768) setSidebarOpen(false);
    }, [width]);

    const handleHome = () => {
        navigate("/home");
        window.location.reload(true);
    }

    if (responsive) {
        if (!sidebarOpen) {
            return (
                <div className="absolute w-full flex justify-end bg-red-700">
                    <img className={`hidden md:block h-auto ${type === "retail" ? "w-[90px] md:w-[153px]" : "w-[130px] md:w-[220px]"} m-auto`} src={type === "retail" ? LogoAux : Logo} />
                    <button className="md:hidden px-3" aria-expanded={sidebarOpen} onClick={(e) => { setSidebarOpen(!sidebarOpen) }}>
                        <span className="text-2xl md:text-3xl text-white"><MdMenuOpen /></span>
                    </button>
                </div>
            )
        }

        return (
            <div className={`bg-ag-secondary-dark top-0 left-0 w-full h-full z-20 ease-in-out ${sidebarOpen ? "absolute md:relative translate-x-0" : "-translate-x-[0vw]"} overflow-y-auto`}>
                <div>
                    <div className="h-[90px] p-[30px]">
                        <button
                            className="text-white float-right text-3xl leading-none outline-none focus:outline-none"
                            onClick={() => { setSidebarOpen(false) }}
                        >
                            <MdMenuOpen />
                        </button>
                    </div>

                    <Link onClick={handleHome}>
                        <h3 className="py-3 pl-10 text-white text-left text-xl hover:bg-ag-secondary-light">Home</h3>
                    </Link>

                    <Link to={`/appointments`}>
                        <h3 className="py-3 pl-10 text-white text-left text-xl hover:bg-ag-secondary-light">My Orders</h3>
                    </Link>

                    <Link to={`/profile`}>
                        <h3 className="py-3 pl-10 text-white text-left text-xl hover:bg-ag-secondary-light">Profile</h3>
                    </Link>

                    <Link to={`/about_us`}>
                        <h3 className="py-3 pl-10 text-white text-left text-xl hover:bg-ag-secondary-light">About Us</h3>
                    </Link>

                    <button
                        type="button"
                        className="w-full"
                        onClick={() => {
                            setShowModal({
                                ...showModal,
                                status: true,
                                title: "Are you sure you want to sign out?",
                                element: ""
                            });
                        }}
                    >
                        <h3 className="py-3 pl-10 text-white text-left text-xl hover:bg-ag-secondary-light">Sign Out</h3>
                    </button>
                </div>
            </div>  
        );
    }

    return (
        <>
            {showModal.status ?
                <Modal
                    title={showModal.title}
                    onClose={() => { setShowModal({ ...showModal, status: false, title: "", element: null }) }}
                    onResponse={(sts) => {
                        if (sts) {
                            clearContext();
                            navigate(`/`);
                        }
                    }}
                />
                :
                null
            }

            <div className={`bg-ag-secondary-dark top-0 left-0 w-full h-full z-20 ease-in-out ${sidebarOpen ? "absolute md:relative translate-x-0" : "-translate-x-[0vw]"} overflow-y-auto`}>
                <div>
                    <div className="flex justify-center items-center h-[90px] md:h-[100px]">
                        {
                            sidebarOpen ?
                                <div className="w-full flex justify-end">
                                    <img className={`hidden md:block h-auto ${type === "retail" ? "w-[90px] md:w-[153px]" : "w-[130px] md:w-[220px]"} m-auto`} src={type === "retail" ? LogoAux : Logo} />
                                    <button className="md:hidden px-3" aria-expanded={sidebarOpen} onClick={(e) => { setSidebarOpen(!sidebarOpen); }}>
                                        <span className="text-2xl md:text-3xl text-white"><MdMenuOpen /></span>
                                    </button>
                                </div>
                                :
                                <img />
                        }
                    </div>
                    <Link onClick={handleHome}>
                    {/* <Link to={`/home`}> */}
                        {
                            sidebarOpen ?
                                <h3 className="py-3 pl-10 text-white text-left text-xl hover:bg-ag-secondary-light">Home</h3>
                                :
                                <h3 className="py-3 pl-5 md:pl-6 text-white text-left text-2xl group hover:bg-blue-500">
                                    <span className="w-56 -mt-3 ml-12 px-8 py-[10px] md:py-[10px] absolute bg-blue-500 text-lg rounded-r-xl hidden group-hover:block group-hover:transition group-hover:duration-700 delay-150 group-hover:ease-in-out">Home</span>
                                    <HiHome />
                                </h3>
                        }
                    </Link>
                    <Link to={`/appointments`}>
                        {
                            sidebarOpen ?
                                <h3 className="py-3 pl-10 text-white text-left text-xl hover:bg-ag-secondary-light">My Orders</h3>
                                :
                                <h3 className="py-3 pl-5 md:pl-6 text-white text-left text-2xl group hover:bg-blue-500">
                                    <span className="w-56 -mt-3 ml-12 px-8 py-[12px] md:py-3 absolute bg-blue-500 text-lg rounded-r-xl hidden group-hover:block group-hover:transition group-hover:duration-700 delay-150 group-hover:ease-in-out">{type === "retail" ? "My Appointments" : "My Orders"}</span>
                                    <img className="w-[25px]" src={SBIconListA} />
                                </h3>
                        }
                    </Link>
                    <Link to={`/profile`}>
                        {
                            sidebarOpen ?
                                <h3 className="py-3 pl-10 text-white text-left text-xl hover:bg-ag-secondary-light">Profile</h3>
                                :
                                <h3 className="py-3 pl-5 md:pl-6 text-white text-left text-2xl group hover:bg-blue-500">
                                    <span className="w-56 -mt-3 ml-12 px-8 py-[10px] md:py-[10px] absolute bg-blue-500 text-lg rounded-r-xl hidden group-hover:block group-hover:transition group-hover:duration-700 delay-150 group-hover:ease-in-out">Profile</span>
                                    <FaUserCircle />
                                </h3>
                        }
                    </Link>
                    <Link to={`/about_us`}>
                        {
                            sidebarOpen ?
                                <h3 className="py-3 pl-10 text-white text-left text-xl hover:bg-ag-secondary-light">About Us</h3>
                                :
                                <h3 className="py-3 pl-5 md:pl-6 text-white text-left text-2xl group hover:bg-blue-500">
                                    <span className="w-56 -mt-3 ml-12 px-8 py-[8.2px] md:py-[8.2px] absolute bg-blue-500 text-lg rounded-r-xl hidden group-hover:block group-hover:transition group-hover:duration-700 delay-150 group-hover:ease-in-out">About Us</span>
                                    <img className="w-[25px]" src={SBIconTicket} />
                                </h3>
                        }
                    </Link>
                    <button
                        type="button"
                        className="w-full"
                        onClick={() => {
                            setShowModal({
                                ...showModal,
                                status: true,
                                title: "Are you sure you want to sign out?",
                                element: ""
                            });
                        }}
                    >
                        {
                            sidebarOpen ?
                                <h3 className="py-3 pl-10 text-white text-left text-xl hover:bg-ag-secondary-light">Sign Out</h3>
                                :
                                <h3 className="py-3 pl-5 md:pl-6 text-white text-left text-3xl md:text-2xl group hover:bg-blue-500">
                                    <span className="w-56 -mt-3 ml-12 px-8 py-[10.5px] md:py-[10.5px] absolute bg-blue-500 text-lg rounded-r-xl hidden group-hover:block group-hover:transition group-hover:duration-700 delay-150 group-hover:ease-in-out">Sign Out</span>
                                    <img className="w-[25px]" src={SBIconSignOut} />
                                </h3>
                        }
                    </button>
                </div>
            </div>
        </>
    );
}

export default SidebarClients;