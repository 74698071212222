import { _delete, _get, _getFree, _getParams, _getParamsFree, _post, _put } from "./http";

export const getProduct = (id) => _get(`/products/${id}`);

export const getProducts = () => _get(`/products`);

export const getProductEspecification = (id) => _get(`/productSpecifications/${id}`);

export const getProductEspecifications = (page) => _get(`/productSpecifications?page=${page}&limit=10`);

export const getProductEspecificationsYears = () => _getFree(`/productSpecifications/yearsCatalog`);

export const getProductEspecificationsBrands = (year) => _getFree(`/productSpecifications/brandsCatalog?year=${year}`);

export const getProductEspecificationsModel = (year, brand) => _getFree(`/productSpecifications/modelsCatalog?year=${year}&brand=${brand}`);

export const getProductEspecificationsTypec = (year, brand, model) => _getFree(`/productSpecifications/typeCarCatalog?year=${year}&brand=${brand}&model=${model}`);

export const getProductEspecificationsTypeg = (year, brand, model, typec) => _getFree(`/productSpecifications/typeGlassCatalog?year=${year}&brand=${brand}&model=${model}&type_car=${typec}`);

export const searchProductEspecification = (filterParams) => _getParamsFree(`/productSpecifications/searchForRetail`, filterParams);

export const saveProduct = (productObject) => _post(`/products`, productObject);

export const updateProduct = (id, productObject) => _put(`/products/${id}`, productObject);

export const deleteProduct = (id) => _delete(`/products/${id}`);
