import { Route, Routes } from "react-router-dom";
import { PermitsProvider } from "../services/Permits";
import PrivateRoute from "./PrivateRoute";

import MyQuotes from "../views/myquotes/step_one/StepOneFree";
import MyQuotesTwo from "../views/myquotes/step_two/StepTwoFree";
import MyQuotesThree from "../views/myquotes/step_three/StepThreeFree";
import MyQuotesFour from "../views/myquotes/step_four/StepFourFree";
import MyQuotesRetailDetail from "../views/myquotes/MyQuotesSchedule";
import MyShippingMethodQuoteRetail from "../views/myquotes/MyQuotesShipping";
import MyInformationFinalQuoteRetail from "../views/myquotes/MyQuotesInformation";

import LoginR from "../views/auth/retail/Login";
import RecoverPassword from "../views/auth/admin/RecoverPassword";
import ChangePassword from "../views/auth/admin/ChangePassword";
import RegisterR from "../views/auth/retail/Register";

// import Retail from "../views/retail/Retail";
import HomeRetail from "../views/retail/advanced_search/step_one/StepOne";
import HomeTwoRetail from "../views/retail/advanced_search/step_two/StepTwo";
import HomeThreeRetail from "../views/retail/advanced_search/step_three/StepThree";
import HomeFourRetail from "../views/retail/advanced_search/step_four/StepFour";
import ProfileRetail from "../views/retail/profile/Profile";
import AdressesRetail from "../views/retail/adresses/Adresses";
import PaymentRetail from "../views/retail/payment/Payment";
import QuotesRetail from "../views/retail/quotes/Quotes";
import QuotesRetailDetail from "../views/retail/quotes/schedule/DetailsQuote";
import QuotesRetailReschedule from "../views/retail/quotes/reschedule/Reschedule";
import ShippingMethodQuoteRetail from "../views/retail/quotes/shippingMethod/ShippingMethodQuote";
import InformationFinalQuoteRetail from "../views/retail/quotes/information/InformationFinalQuote";
import AppointmentsRetail from "../views/retail/appointments/Appointments";
import AboutUsRetail from "../views/retail/about_us/AboutUs";
import ShippingMethodQuotePWRWT from "../views/retail/quotes/shippingMethodPWRWT/ShippingMethodQuotePWRWT";
import ChoicePWR from "../views/retail/quotes/choicePWRWT/ChoicePWR";
import ChoiceWT from "../views/retail/quotes/choicePWRWT/ChoiceWT";
import PrivacyNotice from "../views/retail/privacyNotice/PrivacyNotice";

const RoutesApp = () => {
    return (
        <PermitsProvider>
            <Routes>
                {/* ------------------------------ Quote free ------------------------------ */}
                <Route path="/myQuotes" element={<MyQuotes />} />
                <Route path="/myQuotes/step_two" element={<MyQuotesTwo />} />
                <Route path="/myQuotes/step_three" element={<MyQuotesThree />} />
                <Route path="/myQuotes/step_four" element={<MyQuotesFour />} />
                <Route path="/myQuotes/detail/:id/schedule" element={<MyQuotesRetailDetail />} />
                <Route path="/myQuotes/detail/:id/shipping" element={<MyShippingMethodQuoteRetail />} />
                <Route path="/myQuotes/detail/:id/information" element={<MyInformationFinalQuoteRetail />} />
                <Route path="/recoverPassword" element={<RecoverPassword />} />
                <Route path="/recover/password/:user/:code" element={<ChangePassword />} />

                {/* ------------------------------ Retail ------------------------------ */}
                <Route path="/" element={<LoginR />} />
                <Route path="/register" element={<RegisterR />} />


                {/* ------------------------------ PRIVATE ROUTES ------------------------------ */}
                <Route element={<PrivateRoute />}>

                    {/* ------------------------------ Retail ------------------------------ */}
                    {/* <Route path="/home" element={<Retail />} /> */}
                    <Route path="/home" element={<HomeRetail />} />
                    <Route path="/home/step_two" element={<HomeTwoRetail />} />
                    <Route path="/home/step_three" element={<HomeThreeRetail />} />
                    <Route path="/home/step_four" element={<HomeFourRetail />} />
                    <Route path="/profile" element={<ProfileRetail />} />
                    <Route path="/adresses" element={<AdressesRetail />} />
                    <Route path="/methodsPayment" element={<PaymentRetail />} />

                    {/* Retail => Quotes */}
                    <Route path="/quotes" element={<QuotesRetail />} />
                    <Route path="/quotes/detail/:id/schedule" element={<QuotesRetailDetail />} />
                    <Route path="/quotes/detail/:id/reschedule" element={<QuotesRetailReschedule />} />
                    <Route path="/quotes/detail/:id/shipping" element={<ShippingMethodQuoteRetail />} />
                    <Route path="/quotes/detail/:id/information" element={<InformationFinalQuoteRetail />} />

                    {/* Retail => Appointments */}
                    <Route path="/appointments" element={<AppointmentsRetail />} />

                    {/* Retail => About Us */}
                    <Route path="/about_us" element={<AboutUsRetail />} />

                    {/* Retail => Notice Of Privacy */}
                    <Route path="/privacy_notice" element={<PrivacyNotice />} />

                     {/* Retail => Quotes */}
                     <Route path="/quotes/power-window-repair/window-tint/detail/:id/shipping" element={<ShippingMethodQuotePWRWT />} />
                     <Route path="/quotes/choice_of_power_window_repair/:id" element={<ChoicePWR />} />
                     <Route path="/quotes/choice_of_window_tint/:id" element={<ChoiceWT />} />
                </Route>
            </Routes>
        </PermitsProvider>
    );
}

export default RoutesApp;