import { useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../utils/Utilities";
import FormProfile from "./FormProfile";
import NewInsurance from "../NewInsurance";

const ProfileView = ({ type, information, isSuccess, onSuccess,onUpdate, onSave, onEdit }) => {
    const navigate = useNavigate();
    const [showForm, setShowForm] = useState(false);
    const [showInsurance, setShowInsurance] = useState({ state: false, data: null });

    const [focusClass, setFocusClass] = useState({
        names: { placeholder: "Names", status: false },
        lastNames: { placeholder: "Last Names", status: false },
        email: { placeholder: "Email", status: false },
        password: { placeholder: "Password", status: false },
        phoneNumber: { placeholder: "Phone Number", status: false }
    });

    const [profile, setProfile] = useState({
        _id: "",
        image: "",
        names: "",
        lastNames: "",
        email: "",
        phoneNumber: "",
        password: "",
        availableCredit: 0,
        insurance: null
    });

    const handleChange = (evt) => {
        setProfile({...profile, [evt.target.name]: evt.target.value});
    }

    const handleUpdate = (profileModel) => {
        onUpdate(profileModel);
    }

    const handleInsuranceSve = (insuranceModel) => {
        setShowInsurance({...showInsurance, state: false, data: null});
        onSave(insuranceModel);
    }

    const handleInsuranceUpd = (insuranceModel) => {
        setShowInsurance({...showInsurance, state: false, data: null});
        onEdit(insuranceModel);
    }

    useEffect(() => {
        if (information !== null) {
            setProfile({...profile,
                _id: information?._id,
                image: information?.image,
                names: information?.names,
                lastNames: information?.lastNames,
                email: information?.user?.email,
                phoneNumber: information?.phoneNumber,
                password: "",
                availableCredit: information?.availableCredit,
                insurance: information?.insurance ? information?.insurance : null
            });
        }
    }, [information]);

    useEffect(() => {
        if (isSuccess) {
            setShowForm(false);
            onSuccess(false);
        }
    }, [isSuccess]);


    return (
        <>
            {
                showForm ?
                    <FormProfile userData={profile} onSave={handleUpdate} onClose={() => setShowForm(false)} />
                : null
            }

            {
                showInsurance.state ?
                    <NewInsurance
                        insuranceInfo={profile.insurance}
                        onClose={() => setShowInsurance({...showInsurance, state: false, data: null})}
                        onSave={handleInsuranceSve}
                        onUpdate={handleInsuranceUpd}
                    />
                : null
            }

            <div className="w-full md:w-[50%] xl:w-[40%] space-y-5 m-auto">
                <div className="flex justify-center items-center">
                    {
                        profile.image === "" ?
                            <span className="text-ag-secondary-letter text-[130px]">
                                <FaUserCircle />
                            </span>
                        :
                            <div className="w-[130px] h-[130px]">
                                <img className="w-full h-full object-cover rounded-full" src={profile.image} />
                            </div>
                    }
                </div>
                {
                    type === "wholesaler" ?
                        <div className="flex justify-center items-center space-x-1">
                            <span className="text-ag-secondary-letter text-base font-semibold">My Credits:</span>
                            <span className="text-ag-secondary-letter text-base font-semibold">{ formatCurrency(profile.availableCredit) }</span>
                        </div>
                    : null
                }
                <div className="space-y-2">
                    <div>
                        <input
                            className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl truncate ${focusClass.names.status ? "text-left" : "text-center"}`}
                            type="text"
                            name="names"
                            placeholder={focusClass.names.placeholder}
                            onChange={handleChange}
                            onFocus={() => setFocusClass({...focusClass, names: { placeholder: "", status: true }})}
                            onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, names: { placeholder: "Names", status: false }}) : null}
                            value={profile.names}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <input
                            className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl truncate ${focusClass.lastNames.status ? "text-left" : "text-center"}`}
                            type="text"
                            name="lastNames"
                            placeholder={focusClass.lastNames.placeholder}
                            onChange={handleChange}
                            onFocus={() => setFocusClass({...focusClass, lastNames: { placeholder: "", status: true }})}
                            onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, lastNames: { placeholder: "Last Names", status: false }}) : null}
                            value={profile.lastNames}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <input
                            className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl truncate ${focusClass.email.status ? "text-left" : "text-center"}`}
                            type="text"
                            name="email"
                            placeholder={focusClass.email.placeholder}
                            onChange={handleChange}
                            onFocus={() => setFocusClass({...focusClass, email: { placeholder: "", status: true }})}
                            onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, email: { placeholder: "Email", status: false }}) : null}
                            value={profile.email}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <input
                            className={`w-full text-ag-secondary-letter text-xs lg:text-base border border-gray-500 py-2 px-4 rounded-xl truncate ${focusClass.phoneNumber.status ? "text-left" : "text-center"}`}
                            type="text"
                            name="phoneNumber"
                            placeholder={focusClass.phoneNumber.placeholder}
                            onChange={handleChange}
                            onFocus={() => setFocusClass({...focusClass, phoneNumber: { placeholder: "", status: true }})}
                            onBlur={(evt) => evt.target.value === "" ? setFocusClass({...focusClass, phoneNumber: { placeholder: "Phone Number", status: false }}) : null}
                            value={profile.phoneNumber}
                            disabled={true}
                        />
                    </div>
                    <div className="md:flex md:justify-center md:items-center md:space-x-2 space-y-2 md:space-y-0">
                        <div className="w-full">
                            <button className="w-full bg-ag-secondary text-center text-white text-xs lg:text-base py-2 px-4 rounded-xl" type="button" onClick={() => setShowForm(true)}>
                                Edit Profile
                            </button>
                        </div>
                        {
                            type === "wholesaler" ?
                                <div className="w-full">
                                    <button className="w-full bg-ag-secondary text-center text-white text-xs lg:text-base py-2 px-4 rounded-xl" type="button" onClick={() => navigate(`/methodsPayment`)}>
                                        Payment Method
                                    </button>
                                </div>
                            : null
                        }
                        {
                            type === "retail" ?
                                <div className="w-full">
                                    <button className="w-full bg-ag-secondary text-center text-white text-xs lg:text-base py-2 px-4 rounded-xl" type="button" onClick={() => setShowInsurance({...showInsurance, state: true, data: null})}>
                                        Insurance Information
                                    </button>
                                </div>
                            : null
                        }
                    </div>
                    {
                        type !== "admin" ?
                            <div className="flex justify-center items-center">
                                <div className="w-full md:w-[50%]">
                                    <button className="w-full bg-ag-secondary text-center text-white text-xs lg:text-base py-2 px-4 rounded-xl" type="button" onClick={() => navigate(`/adresses`)}>
                                        My Address
                                    </button>
                                </div>
                            </div>
                        : null
                    }
                </div>
            </div>
        </>
    );
}

export default ProfileView;