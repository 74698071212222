import { BsFillFilterSquareFill } from "react-icons/bs";
import { PermitsContext } from "../../../../services/Permits";
import { useContext } from "react";
import { toast } from "react-toastify";
import { alertOptions, getAllFeaturesWC } from "../../../../utils/Utilities";
import { useNavigate } from "react-router-dom";
import IconRetail from "../../../../assets/icons/icon_glass.svg";
import AdditionalServices from "./AdditionalServices";

const StepThreeForm = ({ type, setShowModalFeatures }) => {
    const navigate = useNavigate();
    const {
        searchData,
        setSearchData,
        produOptions,
        produOptionsUniv,
        setProduOptions,
        distFeatures
    } = useContext(PermitsContext);

    const handleProduct = (productData) => {
        const newProducts = produOptions.map(element =>
            ({ ...element, status: element._id === productData._id })
        );
        setProduOptions(newProducts);
        setSearchData({ ...searchData, nag: productData });
    };

    const handleStep = () => {
        if (searchData.nag) {
            navigate(type === "free" ? "/myQuotes/step_four" : "/home/step_four");
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    };

    const handleBack = () => {
        setSearchData({
            ...searchData,
            windshieldVisorStrip: false,
            ceramicFull70: false,
            ceramicFull90: false,
            waterTreatment: false
        });
        navigate(type === "free" ? "/myQuotes/step_two" : "/home/step_two");
    };

    const formatFeatures = (product) => {
        const featuresAux = getAllFeaturesWC();
        const distinctFeatures = featuresAux.filter(feature => {
            const featureValue = String(feature.value).toLowerCase();
            const isIncluded = String(product.ad_info_a).toLowerCase().includes(featureValue);
            const isAntenna = product.antena === "Y" && feature.value === "Antenna";
            const isHUD = product.hud === "Y" && (
                ["HUD (Head up display)", "W/HUD", "Augmented Reality HUD", "HUD W/Augmented Reality Navigation"].includes(feature.value)
            );

            return isIncluded || isAntenna || isHUD;
        });

        const allFeatures = distinctFeatures.map(feature => `(${feature.label})`).join('');
        return allFeatures;
    };

    const buttonFilters = () => {
        if (produOptionsUniv.length < 2 || !distFeatures.length) {
            return null
        }

        return (
            <button
            className="text-ag-secondary-letter"
            type="button"
            onClick={() => {
                setShowModalFeatures(true);
                setSearchData({ ...searchData, nag: "" });
            }}
            >
            <div className="flex items-center space-x-2 px-3 py-1 border border-ag-secondary rounded-xl">
                <BsFillFilterSquareFill className="text-xl" />

                <span className="text-sm">Filters</span>
            </div>
        </button>
    );
    }

    return (
        <>
            <div className="flex justify-center items-center space-x-5 pb-5">
                <h1 className="text-2xl md:text-xl lg:text-2xl font-light text-ag-secondary">
                    Select your glass
                </h1>

                { buttonFilters() }
            </div>

            <div className="grid md:flex md:justify-between md:items-start md:space-x-8">
                <div className="w-auto md:w-[83%] space-y-8">
                    <div className="space-y-3">
                        {produOptions
                            .slice()
                            .sort((a, b) => a.price - b.price)
                            .map((prodElement, i) => (
                                <div
                                    key={i}
                                    className={`px-5 md:px-8 py-3 border ${prodElement?.status ? 'border-4 border-green-500' : 'border-ag-secondary'} rounded-xl cursor-pointer hover:bg-zinc-200`}
                                    onClick={() => handleProduct(prodElement)}
                                >
                                    <div className="flex space-x-3">
                                        <div className="w-[20%] md:w-[15%] lg:w-[10%] flex justify-center items-center pr-3 lg:pr-5">
                                            <img className="w-full m-auto" src={IconRetail} alt="Icon" />
                                        </div>

                                        <div className="w-[80%] md:w-[85%] lg:w-[90%] space-y-3">
                                            <div className="text-left">
                                                <div>
                                                    <span className="text-ag-secondary-letter text-xs md:text-sm">
                                                        {'('}{String(prodElement?.year).replace(/\s+/g, '').split(',').length > 1 ? `${String(prodElement?.year).replace(/\s+/g, '').split(',').sort()[0]} - ${String(prodElement?.year).replace(/\s+/g, '').split(',').sort().pop()}` : prodElement?.year}{') '}
                                                        {prodElement?.brand} {prodElement?.model} {prodElement?.type_car}
                                                    </span>
                                                </div>

                                                <div className="flex justify-between items-center pt-2 space-x-2">
                                                    <span className="text-ag-secondary-letter text-[10px] md:text-xs">
                                                        {formatFeatures(prodElement)}
                                                        {prodElement.encapsulated === "Y" && "(Encapsulated)"}
                                                        {prodElement.heated === "Y" && "(Heated)"}
                                                        {prodElement.slider === "Y" && "(Slider)"}
                                                    </span>

                                                    <div className={`flex justify-center items-center p-[3px] border rounded-full ${prodElement?.status ? 'border-red-500' : 'border-zinc-400'}`}>
                                                        <div className={`p-1 rounded-full ${prodElement?.status ? 'bg-red-500' : ''}`}></div>
                                                    </div>
                                                </div>

                                                <div className="flex items-center pt-2 space-x-2">
                                                    {prodElement.category.map((catElement, j) => (
                                                        <div key={j}>
                                                            <img title={catElement.label} src={catElement.status ? catElement.icont : catElement.iconf} alt={catElement.label} />
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                    </div>

                    <AdditionalServices searchData={searchData} setSearchData={setSearchData} />
                </div>
            </div>

            <div className="mt-10 py-10 border-t-2 border-ag-primary space-y-3">
                <div className="flex justify-between items-center">
                    <button
                        className="w-full lg:w-[30%] bg-transparent text-ag-secondary-letter px-3 py-2 rounded-xl disabled:bg-zinc-200"
                        type="button"
                        onClick={handleBack}
                    >
                        Back
                    </button>

                    <button
                        className="w-full lg:w-[30%] bg-ag-primary-light text-white px-3 py-2 rounded-xl disabled:bg-zinc-200"
                        type="button"
                        onClick={handleStep}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </>
    );
};

export default StepThreeForm;