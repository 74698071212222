import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../../services/Permits";
import NavbarClients from "../../../../components/navbar/NavbarClients";
import SidebarClients from "../../../../components/sidebar/SidebarClients";
import StepThreeForm from "./StepThreeForm";
import ModalFeatures from "../ModalFeatures";

const StepThree = () => {
    const {
        searchData,
        setSearchData,
        distFeatures,
        setProduOptions,
        produOptionsUniv,
    } = useContext(PermitsContext);

    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showModalFeatures, setShowModalFeatures] = useState(false);

    const handleFilters = (arrFeatures, vinValue) => {
        setSearchData({ ...searchData, vin: vinValue });

        if (arrFeatures.length === 0) {
            setProduOptions(produOptionsUniv);
            setShowModalFeatures(false);
            return;
        }

        const productsFound = produOptionsUniv.filter(prodElem => {
            const matchesFeature = (feature) => {
                const featureValue = String(feature.value).toLowerCase();
                return (
                    String(prodElem.ad_info_a).toLowerCase().includes(featureValue) ||
                    (featureValue === "ant" && prodElem.antena === "Y") ||
                    (featureValue === "solar" && prodElem.solar === "Y") ||
                    (featureValue === "hud" && prodElem.hud === "Y")
                );
            };

            return arrFeatures.every(matchesFeature);
        });

        setProduOptions(productsFound);
        setShowModalFeatures(false);
    };

    useEffect(() => {
        if (produOptionsUniv.length > 1 && distFeatures.length && searchData.nag === "") {
            setShowModalFeatures(true);
        }
    }, [produOptionsUniv]);

    const handleCloseModal = () => {
        setShowModalFeatures(false);
        setProduOptions(produOptionsUniv);
        setSearchData({ ...searchData, nag: "" });
    };

    return (
        <>
            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>

                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`} >
                        <SidebarClients
                            type="retail"
                            sidebarOpen={sidebarOpen}
                            setSidebarOpen={setSidebarOpen}
                        />
                    </div>

                    <div className="w-full overflow-auto flex-grow mt-16 md:mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="px-5 xl:px-28 2xl:px-40 pt-3">
                                <StepThreeForm type="user" setShowModalFeatures={setShowModalFeatures} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalFeatures
                showModal={showModalFeatures}
                arrFeatures={distFeatures}
                vinValue={searchData.vin}
                onClose={handleCloseModal}
                onAccept={handleFilters}
            />
        </>
    );
};

export default StepThree;
