import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../services/Permits";
import { cancelAppointment, deleteAppointment, getAppointments } from "../../../services/Appointments";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { cancelQuoteRetail, deleteQuoteRetail, getQuotesByRetail } from "../../../services/Quotes";
import { getAllPowerWindowRepair } from "../../../services/PowerWindowRepair";
import { getOrdersRetail } from "../../../services/Orders";
import Loader from "../../../components/loader/Loader";
import NavbarClients from "../../../components/navbar/NavbarClients";
import SidebarClients from "../../../components/sidebar/SidebarClients";
import ListAppointments from "./ListAppointments";
import Modal from "../../../components/modal/Modal";

const Appointments = () => {
    const { dataSession } = useContext(PermitsContext);
    const [loader, setLoader] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showModal, setShowModal] = useState({ status: false, type: "", title: "", element: null });
    const [appointments, setAppointments] = useState([]);

    const getAppointmentsData = async () => {
        getOrdersRetail(dataSession?._id).then(res => {
            setAppointments(res.data);
            setLoader(false);
        });
        // setLoader(true);
        // Cotizaciones por fotos
        // const quotesPhotoRes = await getQuotesByRetail(dataSession._id);
        // const quotesPhotoVal = (quotesPhotoRes.status === 200 ? quotesPhotoRes.data : []).filter(elem => elem?.searchType === "photos").map(elem => ({...elem, type_order: "quote_photo"}));

        // Ordenes normales (pago propio y aseguradora), por foto (pago propio y aseguradora)
        // const ordersNormalPhotoRes = await getAppointments(dataSession._id);
        // const ordersNormalPhotoVal = (ordersNormalPhotoRes.status === 200 ? ordersNormalPhotoRes.data : []).map(elem => ({...elem, type_order: "order_all"}));

        // Cotizaciones Power window repair
        // const quotesPowerWRRes = await getAllPowerWindowRepair(dataSession._id);
        // const quotesPowerWRVal = (quotesPowerWRRes.status === 200 ? quotesPowerWRRes.data : []).map(elem => ({...elem, type_order: "quote_repair"}));

        // let allMyOrders = [];
        // allMyOrders = allMyOrders.concat(quotesPhotoVal, ordersNormalPhotoVal, quotesPowerWRVal);
        // allMyOrders.sort(function(a, b) {
        //     return new Date(b.createdAt) - new Date(a.createdAt);
        // });
        // setAppointments(allMyOrders);
        // setLoader(false);
    }

    const handleModal = (idAppointment) => {
        if (showModal.type === "cancel") {
            setShowModal({...showModal, status: false, type: "", title: "", element: null});
            handleCancel(idAppointment);
        } else if (showModal.type === "cancel_quote_photo") {
            setShowModal({...showModal, status: false, type: "", title: "", element: null});
            handleCancelQuote(idAppointment);
        } else if (showModal.type === "delete_quote_photo") {
            setShowModal({...showModal, status: false, type: "", title: "", element: null});
            handleDeleteQuote(idAppointment);
        } else {
            setShowModal({...showModal, status: false, type: "", title: "", element: null});
            handleDelete(idAppointment);
        }
    }

    const handleDelete = (idAppointment) => {
        setLoader(true);
        deleteAppointment(dataSession._id, idAppointment).then(res => {
            if (res.status === 200) {
                getAppointmentsData();
                toast.success("Appointment deleted successfully", alertOptions);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleCancel = (idAppointment) => {
        setLoader(true);
        cancelAppointment(dataSession._id, idAppointment, { status: "CANCELED" }).then(res => {
            if (res.status === 200) {
                getAppointmentsData();
                toast.success("Appointment canceled successfully", alertOptions);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleCancelQuote = (idQuote) => {
        setLoader(true);
        cancelQuoteRetail(dataSession._id, idQuote, { status: "canceled" }).then(res => {
            if (res.status === 200) {
                getAppointmentsData();
                toast.success("Quote canceled successfully", alertOptions);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleDeleteQuote = (idQuote) => {
        setLoader(true);
        deleteQuoteRetail(dataSession._id, idQuote).then(res => {
            if (res.status === 200) {
                getAppointmentsData();
                toast.success("Quote deleted successfully", alertOptions);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    useEffect(() => {
        if (dataSession._id) {
            getAppointmentsData();
        }
    }, [dataSession._id]);

    return (
        <>
            { loader ? <Loader /> : null }

            { showModal.status ?
                <Modal
                    title={showModal.title}
                    onClose={() => setShowModal({...showModal, status: false, type: "", title: "", element: null})}
                    onResponse={() => handleModal(showModal.element)}
                />
            :
                null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="text-center">
                                <h1 className="text-2xl md:text-xl lg:text-2xl font-light text-ag-secondary">My Orders</h1>
                            </div>
                            <div className="pt-3">
                                <ListAppointments
                                    appointments={appointments}
                                    onCancel={(idAppointment) => setShowModal({...showModal,
                                        status: true,
                                        type: "cancel",
                                        title: "Are you sure you want to cancel the appointment?",
                                        element: idAppointment
                                    })}
                                    onDelete={(idAppointment) => setShowModal({...showModal,
                                        status: true,
                                        type: "delete",
                                        title: "Are you sure you want to delete the appointment?",
                                        element: idAppointment
                                    })}
                                    onCancelQuote={(idAppointment) => setShowModal({...showModal,
                                        status: true,
                                        type: "cancel_quote_photo",
                                        title: "Are you sure you want to delete the quote?",
                                        element: idAppointment
                                    })}
                                    onDeleteQuote={(idAppointment) => setShowModal({...showModal,
                                        status: true,
                                        type: "delete_quote_photo",
                                        title: "Are you sure you want to delete the quote?",
                                        element: idAppointment
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Appointments;