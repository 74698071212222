import { useContext, useEffect, useState } from "react";
import { PermitsContext } from "../../../services/Permits";
import { cancelQuoteRetail, deleteQuoteRetail, getQuotesByRetail } from "../../../services/Quotes";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { getAppointments } from "../../../services/Appointments";
import Loader from "../../../components/loader/Loader";
import NavbarClients from "../../../components/navbar/NavbarClients";
import SidebarClients from "../../../components/sidebar/SidebarClients";
import ListQuotes from "./ListQuotes";
import Modal from "../../../components/modal/Modal";

const Quotes = () => {
    const { dataSession } = useContext(PermitsContext);
    const [loader, setLoader] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const [showModal, setShowModal] = useState({ status: false, type: "", title: "", element: null });
    const [quotes, setQuotes] = useState([]);

    const handleModal = (idQuote) => {
        if (showModal.type === "cancel") {
            setShowModal({...showModal, status: false, type: "", title: "", element: null});
            handleCancel(idQuote);
        } else {
            setShowModal({...showModal, status: false, type: "", title: "", element: null});
            handleDelete(idQuote);
        }
    }

    const handleCancel = (idQuote) => {
        setLoader(true);
        cancelQuoteRetail(dataSession._id, idQuote, { status: "canceled" }).then(res => {
            if (res.status === 200) {
                getQuotes();
                toast.success("Quote canceled successfully", alertOptions);
            } else {
                toast.warning(res.response.data.message, alertOptions);
            }
            setLoader(false);
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const handleDelete = (idQuote) => {
        setLoader(true);
        deleteQuoteRetail(dataSession._id, idQuote).then(res => {
            if (res.status === 200) {
                getQuotes();
                toast.success("Quote deleted successfully", alertOptions);
            } else {
                setLoader(false);
                toast.warning(res.response.data.message, alertOptions);
            }
        }).catch(error => {
            setLoader(false);
            toast.warning(error.response.data.message, alertOptions);
        });
    }

    const getQuotes = () => {
        getQuotesByRetail(dataSession._id).then(resQuotes => {
            if (resQuotes.status === 200 && resQuotes.data.length > 0) {
                getAppointments(dataSession._id).then(resAppointments => {
                    const newQuotes = resQuotes.data.map(qteEle => {
                        const quoteFound = resAppointments.data.find(aptEle => aptEle.quote._id === qteEle._id);
                        if (quoteFound) return {...qteEle, status: "scheduled"};
                        return qteEle;
                    });

                    const array_sort = newQuotes.sort(function(a,b){
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    setQuotes(array_sort);
                });
            }
            setLoader(false);
        });
    }

    useEffect(() => {
        if (dataSession._id) {
            getQuotes();
        }
    }, [dataSession._id]);

    return (
        <>
            { loader ? <Loader /> : null }

            { showModal.status ?
                <Modal
                    title={showModal.title}
                    onClose={() => setShowModal({...showModal, status: false, type: "", title: "", element: null})}
                    onResponse={() => handleModal(showModal.element)}
                />
            :
                null
            }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                </div>
                <div className="flex h-screen overflow-hidden">
                    <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                        <SidebarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                    </div>
                    <div className="w-full overflow-auto flex-grow mt-[100px]">
                        <div className="space-y-5 px-3 py-5 md:px-8 md:py-8">
                            <div className="text-center">
                                <h1 className="text-2xl md:text-xl lg:text-2xl font-light text-ag-secondary">My Quotes</h1>
                            </div>
                            <div className="pt-3">
                                <ListQuotes
                                    quotes={quotes}
                                    onCancel={(idQuote) => setShowModal({...showModal,
                                        status: true,
                                        type: "cancel",
                                        title: "Are you sure you want to cancel the quote?",
                                        element: idQuote
                                    })}
                                    onDelete={(idQuote) => setShowModal({...showModal,
                                        status: true,
                                        type: "delete",
                                        title: "Are you sure you want to delete the quote?",
                                        element: idQuote
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Quotes;