import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FiChevronLeft } from "react-icons/fi";
import { alertOptions, formatReverseDate } from "../../utils/Utilities";
import { toast } from "react-toastify";
import { validateCarInsuranceAux } from "../../utils/Validators";
import { savePaymentQuoteRetail } from "../../services/Quotes";
import { PermitsContext } from "../../services/Permits";
import Loader from "../../components/loader/Loader";
import NavbarFree from "../../components/navbar/NavbarFree";
import InformationFinalQuoteForm from "../retail/quotes/information/InformationFinalQuoteForm";
import QuoteInProcess from "../retail/advanced_search/QuoteInProcess";
import QuoteInProcessConfirm from "../retail/advanced_search/QuoteInProcessConfirm";

const MyQuotesInformation = () => {
    const navigate = useNavigate();
    const { clearQuote } = useContext(PermitsContext);
    const location = useLocation();
    const params = useParams();
    const [loader, setLoader] = useState(false);
    const [showSuccessGral, setShowSuccessGral] = useState(false);
    const [showSuccessAseg, setShowSuccessAseg] = useState(false);
    const [quote, setQuote] = useState(null);

    const handleQuote = (informationModel) => {
        setLoader(true);
        const bodyQuote = {
            names: informationModel.name,
            lastNames: informationModel.lastNames,
            email: informationModel.email,
            phoneNumber: informationModel.phoneNumber,
            total: Number(quote.selectProduct.total) + Number(quote.costShipping),
            installationType: quote?.shippingMethod === "SHIP" ? "HOMESERVICE" : "WAREHOUSESERVICE",
            date: `${formatReverseDate(quote.day)}T${quote.hour}:00`,
            shippingMethod: quote.shippingMethod,
            warehouse: quote.fromWarehouse,
            costShipping: quote.costShipping,
            paymentMethod: quote.paymentMethod
        }

        // Shipping method
        if (quote.shippingMethod === "SHIP") {
            bodyQuote.userAddress = quote.userAddress;
        } else {
            bodyQuote.warehouseAddress = quote.warehouseAddress;
            bodyQuote.fromWarehouse = quote.fromWarehouse;
        }

        // Payment
        if (quote.paymentMethod === "CAR_INSURANCE") {
            const bodyInsurance = {
                numberOfInsurance: quote.numberOfInsurance,
                companyInsurance: quote.companyInsurance,
                dateInsurance: quote.dateInsurance
            }

            if (new Date() <= new Date(bodyQuote.dateInsurance)) {
                toast.warning("Loss date must higher than today's date", alertOptions);
                setLoader(false);
                return;
            }

            const result = validateCarInsuranceAux(bodyInsurance);
            if (!result.status) {
                setLoader(false);
                toast.warning(result.msg, alertOptions);
                return;
            } else {
                bodyQuote.insurance = bodyInsurance;
            }
        }

        savePaymentQuoteRetail(params.id, bodyQuote).then(resQuote => {
            if (resQuote.status === 200) {
                setLoader(false);
                window.localStorage.removeItem("user-data-address");
                if (quote.paymentMethod === "PAY_ON_MY_WON" && quote?.searchType === "specifications") {
                    setShowSuccessGral(true);
                    clearQuote();
                    window.history.replaceState({}, document.title);
                } else {
                    setShowSuccessAseg(true);
                    clearQuote();
                    window.history.replaceState({}, document.title);
                }
            } else {
                setLoader(false);
                toast.warning(resQuote.response.data.message, alertOptions);
            }
        }).catch((error) => {
            setLoader(false);
            toast.error(error.response.data.message, alertOptions);
        });
    }

    useEffect(() => {
        if (location.state) {
            setLoader(true);
            setQuote(location.state);
            setLoader(false);
        } else {
            navigate("/myQuotes");
        }
    }, []);

    return (
        <>
            { loader ? <Loader /> : null }

            { showSuccessGral ? <QuoteInProcess onClose={() => navigate("/myQuotes")} /> : null }

            { showSuccessAseg ? <QuoteInProcessConfirm onClose={() => navigate("/myQuotes")} /> : null }

            <div>
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-10">
                        <NavbarFree />
                    </div>
                </div>
                <div className="w-full overflow-auto flex-grow mt-[100px]">
                    <div className="space-y-5 p-5 md:p-8">
                        <div className="grid grid-cols-12 px-0 md:px-5">
                            <div>
                                <button className="p-1 rounded-md" type="button" onClick={() => navigate(-1)}>
                                    <span className="text-ag-secondary-dark text-3xl"><FiChevronLeft /></span>
                                </button>
                            </div>
                            <div className="flex justify-center items-center col-span-11 text-center">
                                <h1 className="text-2xl md:text-xl lg:text-2xl font-light text-ag-secondary md:mr-[8%]">Provide your information</h1>
                            </div>
                        </div>
                        <div className="px-3 md:px-9">
                            <span className="text-base text-ag-secondary">
                                To continue with the order, we need to collect some information:
                            </span>
                        </div>
                        <div className="px-3 md:px-9">
                            <InformationFinalQuoteForm type="free" quote={quote} onSave={handleQuote} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MyQuotesInformation;