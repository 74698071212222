import { RiErrorWarningLine } from "react-icons/ri";
import OrderAllCard from "./OrderAllCard";
import QuotePhotoCard from "./QuotePhotoCard";
import QuoteRepair from "./QuoteRepair";

const ListAppointments = ({ appointments, onCancel, onDelete, onCancelQuote, onDeleteQuote }) => {
    return (
        <>
            <div className="space-y-5">
            {
                appointments.length > 0 ?
                    appointments.map((appointment, i) => (
                        appointment?.quote !== undefined || appointment?.anotherQuote !== undefined?
                            <OrderAllCard key={i} appointment={appointment} onCancel={onCancel} onDelete={onDelete} />
                        : appointment?.searchType === "photos" ?
                            <QuotePhotoCard key={i} quote={appointment} onCancel={onCancelQuote} onDelete={onDeleteQuote} />
                        : appointment?.type === "WINDOW_TINT" || appointment?.type === "POWER_WINDOW_REPAIR" ?
                            <QuoteRepair key={i} quote={appointment} />
                        : null
                    ))
                :
                    <div className="w-full flex justify-center items-center space-x-3 pt-[15%] text-center text-ag-secondary-letter">
                        <div>
                            <span className="flex justify-center pb-3 text-2xl"><RiErrorWarningLine /></span>
                            <span className="text-xs italic md:text-base">
                                You currently do not have any appointments in the system...
                            </span>
                        </div>
                    </div>
            }
        </div>
        </>
    );
}

export default ListAppointments;