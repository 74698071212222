import { IoMdImage } from "react-icons/io";
import { formatAMPM, formatCurrency, formatDateEng } from "../../../utils/Utilities";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const statusAppointment = {
    ATTEND: { text: "Finished", textColor: "text-ag-secondary-letter", bgColor: "bg-green-600", borderColor: "border-green-600" },
    CANCELED: { text: "Canceled", textColor: "text-ag-primary-light", bgColor: "bg-ag-primary-light", borderColor: "border-ag-primary-light" },
    INPROCESS: { text: "In process", textColor: "text-ag-secondary-letter", bgColor: "bg-ag-secondary", borderColor: "border-ag-secondary"}
}

const paymentQuote = {
    PAY_ON_MY_WON: "Pay on my own",
    CAR_INSURANCE: "Pay with Insurance"
}

const OrderAllCard = ({ appointment, onCancel, onDelete }) => {
    const navigate = useNavigate();
    const [canRC, setCanRC] = useState(false);

    const handleReschedule = () => {
        const newModelQuote = {...appointment?.quote,
            _idAppointment: appointment?._id,
            payment: appointment?.payment,
            costShipping: appointment?.payment?.costShipping,
            paymentMethod: appointment?.payment?.paymentMethod,
            shippingMethod: appointment?.payment?.shippingMethod,
            total: appointment?.payment?.total,
            warehouseAddress: appointment?.payment?.warehouseAddress,
            userAddress: appointment?.payment?.userAddress,
            address: appointment?.payment?.userAddress ? appointment?.payment?.userAddress : appointment?.payment?.warehouseAddress,
            installationType: appointment?.installationType,
            warehouse: appointment?.warehouse
        };
        navigate(`/quotes/detail/${appointment?.quote._id}/reschedule`, {state: newModelQuote});
    }

    useEffect(() => {
        if (appointment && appointment !== null) {
            let d = new Date(appointment.createdAt);
            d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
            
            const diasTranscurridos = Math.floor((new Date() - d) / (1000 * 60 * 60 * 24));
            if (diasTranscurridos > 0) setCanRC(true);
        }
    }, [appointment]);


    return (
        <div className="w-full lg:flex lg:justify-between p-3 md:p-0 border border-ag-secondary-light rounded-xl">
            <div className="w-full lg:w-[15%] flex justify-center items-center text-center text-ag-secondary-letter">
                {
                    appointment?.quote?.photos.length > 0 ?
                        <div className="w-[150px] h-[150px] flex justify-center items-center rounded-xl p-1">
                            <img className="w-full h-full object-contain rounded-xl" src={appointment?.quote?.photos[0]} />
                        </div>
                    :
                        <span className="text-[70px] md:text-[60px] xl:text-[70px]"><IoMdImage /></span>
                }
            </div>
            <div className="w-full lg:w-[35%] text-base md:text-xs lg:text-base relative">
                <div className="px-3 py-8 text-ag-secondary-letter">
                    <ul>
                        {
                            appointment?.type === "BY_PHOTO" ?
                                <li className="flex justify-start items-center space-x-2">
                                    <span className="font-semibold">Type:</span>
                                    <p className="truncate">Per photo...</p>
                                </li>
                            : null
                        }
                        {
                            appointment?.type === "BY_PHOTO" ?
                                <li className="flex justify-start items-center space-x-2">
                                    <span className="font-semibold">Description:</span>
                                    <p className="truncate w-full md:w-36 lg:w-full" title={appointment.quote?.description}>
                                        { appointment.quote?.description }
                                    </p>
                                </li>
                            : null
                        }
                        <li className="flex justify-start items-center space-x-2">
                            <span className="font-semibold">Year:</span>
                            <p className="truncate">{ appointment.quote?.year ? appointment.quote?.year : "Quote per photo..." }</p>
                        </li>
                        <li className="flex justify-start items-center space-x-2">
                            <span className="font-semibold">Make:</span>
                            <p className="truncate">{ appointment.quote?.brand ? appointment.quote?.brand : "Quote per photo..." }</p>
                        </li>
                        <li className="flex justify-start items-center space-x-2">
                            <span className="font-semibold">Model:</span>
                            <p className="truncate">{ appointment.quote?.model ? appointment.quote?.model : "Quote per photo..." }</p>
                        </li>
                        <li className="flex justify-start items-center space-x-2">
                            <span className="font-semibold">Warehouse:</span>
                            <p className="truncate" title={appointment.warehouse?.name ? appointment.warehouse?.name : "Quote per photo..."}>
                                {
                                    appointment?.installationType === "WAREHOUSESERVICE" ? 
                                        appointment.warehouse?.name ? appointment.warehouse?.name : "Quote per photo..."
                                    : <span className="italic">Home services</span>
                                }
                            </p>
                        </li>
                        {
                            appointment?.type !== "BY_PHOTO" ?
                                <li className="flex justify-start items-center space-x-2">
                                    <span className="font-semibold">Style:</span>
                                    <p className="truncate">{ appointment.quote?.style ? appointment.quote?.style : "Quote per photo..." }</p>
                                </li>
                            : null
                        }
                        {
                            appointment?.type !== "BY_PHOTO" ?
                                <li className="flex justify-start items-center space-x-2">
                                    <span className="font-semibold">Glass type:</span>
                                    <p className="truncate">{ appointment.quote?.typeGlass ? appointment.quote?.typeGlass : "Quote per photo..." }</p>
                                </li>
                            : null
                        }
                        {
                            appointment?.quote?.paymentMethod === "CAR_INSURANCE" ?
                                <li className="py-2"></li>
                            : null
                        }
                    </ul>
                </div>
                <div className={`flex justify-center space-x-3 px-3 py-0 text-white border ${statusAppointment[appointment?.status].borderColor} ${statusAppointment[appointment?.status].bgColor}`}>
                    <span>{ statusAppointment[appointment.status].text }</span>
                </div>
            </div>
            <div className="w-full lg:w-[35%] text-base md:text-xs lg:text-base text-ag-secondary-letter relative">
                <div className="px-3 py-8 text-ag-secondary-letter">
                    <ul>
                        <li className="flex justify-start items-center space-x-2">
                            <span className="font-semibold">Date:</span>
                            <p className="truncate">{ appointment?.date ? formatDateEng(appointment?.date) : "---" }</p>
                        </li>
                        <li className="flex justify-start items-center space-x-2">
                            <span className="font-semibold">Hour:</span>
                            <p className="truncate">{ appointment?.date ? formatAMPM(appointment?.date) : "---" }</p>
                        </li>
                        <li className="flex justify-start items-center space-x-2">
                            <span className="font-semibold">Price:</span>
                            <span>
                                {
                                    appointment?.quote?.selectProduct?.total ?
                                        formatCurrency(appointment?.quote?.paymentMethod !== "CAR_INSURANCE" ? appointment?.quote?.selectProduct?.total + appointment?.payment?.costShipping : 0)
                                    : "---"
                                }
                            </span>
                        </li>
                        <li className="flex justify-start items-center space-x-2">
                            <span className="font-semibold">Payment method:</span>
                            <p className="truncate">{ appointment?.quote ? paymentQuote[appointment?.quote?.paymentMethod] : "---" }</p>
                        </li>
                        {
                            appointment?.quote?.paymentMethod === "CAR_INSURANCE" ?
                                <li className="text-xs">{'(Free full Ceramic Windshield Tint)'}</li>
                            : null
                        }
                        <li className="flex justify-start items-center space-x-2">
                            <p className="font-semibold">Address:</p>
                            <p className="truncate w-full md:w-36 lg:w-full" title={appointment?.payment?.warehouseAddress ?
                             `${appointment.payment?.warehouseAddress?.street},` : `${appointment.payment?.userAddress?.street},`
                            }>
                                {
                                    appointment?.payment?.warehouseAddress ?
                                        `${appointment.payment?.warehouseAddress?.street}, `
                                        :
                                        `${appointment.payment?.userAddress?.street}`
                                }

                                {  appointment?.payment?.warehouseAddress ?
                                        `${appointment.payment?.warehouseAddress?.locality} ${appointment.payment?.warehouseAddress?.postalCode}`
                                        :
                                        `${appointment.payment?.userAddress?.city} ${appointment.payment?.userAddress?.postalCode}`
                                }
                            </p>
                        </li>
                        { <li className="flex justify-start items-center space-x-3">                           
                            <p className="truncate w-full md:w-36 lg:w-full" title={appointment?.payment?.warehouseAddress ?
                                `${appointment.payment?.warehouseAddress?.locality} ${appointment.payment?.warehouseAddress?.postalCode}`
                                : `${appointment.payment?.userAddress?.city} ${appointment.payment?.userAddress?.postalCode}`
                            }> <br/>

                            </p>
                        </li> }
                    </ul>
                </div>
                <div className={`hidden md:block flex justify-center space-x-3 px-3 py-0 text-transparent border absolute bottom-0 w-full ${statusAppointment[appointment.status].borderColor} ${statusAppointment[appointment.status].bgColor}`}>
                    <span>...</span>
                </div>
            </div>
            <div className="w-full lg:w-[15%] flex justify-center items-center md:block space-y-0 md:space-y-1 p-5">
                {
                    appointment.status === "INPROCESS" && !canRC ?
                        <button className="w-full px-2 xl:px-4 py-2 bg-ag-secondary text-base md:text-xs xl:text-base text-white rounded-xl" type="button" onClick={() => handleReschedule()}>
                            Reschedule
                        </button>
                    :
                        null
                }
                {
                    appointment.status === "INPROCESS" && !canRC ?
                        <button className="w-full px-2 xl:px-4 py-2 bg-ag-primary-light text-base md:text-xs xl:text-base text-white rounded-xl" type="button" onClick={() => onCancel(appointment._id)}>
                            Cancel
                        </button>
                    :
                        null
                }
                {
                    appointment.status === "CANCELED" ?
                        <button className="w-full px-2 xl:px-4 py-2 bg-ag-primary-light text-base md:text-xs xl:text-base text-white rounded-xl" type="button" onClick={() => onDelete(appointment._id)}>
                            Delete
                        </button>
                    :
                        null
                }
            </div>
        </div>
    );
}

export default OrderAllCard;