import { useEffect, useState, useContext } from "react";
import { IoMdCloseCircle } from "react-icons/io";
import { toast } from "react-toastify";
import { alertOptions } from "../../../utils/Utilities";
import { validateFormReparation } from "../../../utils/Validators";
import { savePowerWindowRepair } from "../../../services/PowerWindowRepair";
import Select from 'react-select';
import { PermitsContext } from "../../../services/Permits";
import { PatternFormat } from "react-number-format";

let parseGlasses = [
    { parseVariable: "FRONT_DOOR_LEFT", value: "Door / Front / Left" },
    { parseVariable: "REAR_DOOR_LEFT", value: "Door / Rear / Left" },
    { parseVariable: "REAR_QUARTER_LEFT", value: "Quarter / Left" },
    { parseVariable: "FRONT_DOOR_RIGHT", value: "Door / Front / Right" },
    { parseVariable: "REAR_DOOR_RIGHT", value: "Door / Rear / Right" },
    { parseVariable: "REAR_QUARTER_RIGHT", value: "Quarter / Right" },
    { parseVariable: "BACK_GLASS_SLIDER", value: "Back Window" },
    { parseVariable: "SUNROOF", value: "Roof" }
]

const customStylesSelect = {
    control: (provided, state) => ({
        ...provided,
        borderColor: state.isFocused ? 'rgb(107, 114, 128)' : 'rgb(107, 114, 128)',
        borderRadius: '12px',
        boxShadow: state.isFocused ? '0 0 5px rgba(0, 123, 255, 0.5)' : 'none',
        '&:hover': {
            borderColor: '#rgb(107, 114, 128)'
        },
        textAlign: 'center'
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 5000,
    }),
    menuList: (provided) => ({
        ...provided,
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#393D45',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#FF5F52',
            borderRadius: '6px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#F41A28',
        },
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: '#9CC5D3'
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        color: '#333'
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        color: '#333',
        ':hover': {
            backgroundColor: '#FF4444',
            color: 'white'
        }
    })
};

const FormReparation = ({ information, informationData, onClose, onSuccess, onLoader }) => {
    const { typeGlassOptionsPWRBase } = useContext(PermitsContext);
    const [glassOptions, setGlassOptions] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [data, setData] = useState({
        type: "POWER_WINDOW_REPAIR",
        names: "",
        lastNames: "",
        email: "",
        phoneNumber: "",
        description: "",
        vin: "",
        year: "",
        brand: "",
        model: "",
        style: "",
        typeGlass: "",
        selectedOptionsPWR: []
    });

    const handleChangeGlass = (selected) => {
        let valueGlasses = selected.map((item) => {
            let match = parseGlasses.find((x) => x.value === item.value)
            return match.parseVariable
        })
        setData({
            ...data,
            selectedOptionsPWR: valueGlasses
        });
        setGlassOptions(selected);
    };

    const customNoOptionsMessage = () => {
        return 'Records not found';
    };

    const handleChange = (evt) => {
        if (evt.target.name === "phoneNumber") {
            const pattern = new RegExp(/^[0-9\s]+$/g);
            if (pattern.test(evt.target.value) || evt.target.value === "") {
                setData({ ...data, [evt.target.name]: evt.target.value });
            }
        } else {
            setData({ ...data, [evt.target.name]: evt.target.value });
        }
    }

    const handleChangePhoneNumber = (value) => {
        const pattern = new RegExp(/^[0-9\s]+$/g);
        if (pattern.test(value) || value === "") {
            setData({ ...data, phoneNumber: value });
        }
    }

    useEffect(() => {
        if (informationData?.names !== "" && informationData?.lastNames !== "" && informationData?.email !== "") setIsDisabled(true)
        setData({
            type: "POWER_WINDOW_REPAIR",
            year: information.year,
            brand: information.brand,
            model: information.model,
            style: information.style,
            typeGlass: information.typeGlass,
            vin: information.vin,
            names: information?.names,
            lastNames: information?.lastNames,
            email: information?.email,
            phoneNumber: information?.phoneNumber,
            selectedOptionsPWR: []
        });
    }, [information]);


    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (data.names !== "" && data.lastNames !== "" && data.phoneNumber !== "" && data.email !== "" && data.description !== "" && data.year !== "" && data.brand !== "" && data.model !== "" && data.style !== "") {
            onLoader(true);
            const result = validateFormReparation(data);
            if (result.status) {
                savePowerWindowRepair(data).then(res => {
                    if (res.status === 200) {
                        onLoader(false);
                        setData({
                            ...data,
                            type: "POWER_WINDOW_REPAIR",
                            names: "",
                            lastNames: "",
                            email: "",
                            phoneNumber: "",
                            vin: "",
                            year: "",
                            brand: "",
                            model: "",
                            style: "",
                            typeGlass: "",
                            description: ""
                        });
                        onSuccess(true);
                    } else {
                        onLoader(false);
                        toast.warning(res.response.data.message, alertOptions);
                    }
                }).catch((error) => {
                    onLoader(false);
                    toast.warning(error.response.data.message, alertOptions);
                });
            } else {
                onLoader(false);
                toast.warning(result.msg, alertOptions);
            }
        } else {
            toast.info("Please fill in all the fields of the form", alertOptions);
        }
    }

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none mb-0 md:mb-5 xl:mb-0">
                    <div className="w-[400px] md:w-[400px] lg:w-[500px] h-auto md:h-[500px] xl:h-auto">
                        <div className="my-2 p-5 border-0 rounded-lg shadow-lg flex-col w-full bg-white outline-none focus:outline-none">
                            <div className="w-full flex items-center justify-center rounded text-center">
                                <div className="w-[95%]">
                                    <span className="text-xl text-ag-secondary font-bold pl-[5%]">
                                        Power Window Repair
                                    </span>
                                </div>
                                <div className="w-[5%]">
                                    <button
                                        className="text-ag-secondary float-right text-3xl leading-none outline-none focus:outline-none"
                                        onClick={() => onClose(false)}
                                    >
                                        <IoMdCloseCircle />
                                    </button>
                                </div>
                            </div>
                            <div className="px-2 md:px-5 pb-3 pt-5 h-[550px] lg:h-[570px] overflow-auto content-pwr">
                                <div className="space-y-1">
                                    <div className="px-2 text-ag-secondary-letter text-sm">
                                        <span>
                                            If your power window has stopped working, you need more than a quick fix.
                                        </span>
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Name:</label>
                                        </div>
                                        <input
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            name="names"
                                            type="text"
                                            placeholder="Name"
                                            onChange={handleChange}
                                            value={data.names}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Last Name:</label>
                                        </div>
                                        <input
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            name="lastNames"
                                            type="text"
                                            placeholder="Last Name"
                                            onChange={handleChange}
                                            value={data.lastNames}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Email:</label>
                                        </div>
                                        <input
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            name="email"
                                            type="text"
                                            placeholder="Email"
                                            onChange={handleChange}
                                            value={data.email}
                                            disabled={isDisabled}
                                        />
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Phone Number:</label>
                                        </div>
                                        <PatternFormat
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 pl-1 pr-3 py-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            format="(###)###-####"
                                            name="phoneNumber"
                                            placeholder="Phone Number"
                                            value={data.phoneNumber}
                                            disabled={isDisabled}
                                            onValueChange={(values, _) => handleChangePhoneNumber(values.value)}
                                        />
                                        {/* <input
                                            className="w-full text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200 truncate"
                                            name="phoneNumber"
                                            type="text"
                                            placeholder="Phone Number"
                                            onChange={handleChange}
                                            value={data.phoneNumber}
                                            maxLength={10}
                                            disabled={isDisabled}
                                        /> */}
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Choose at least 1 window to repair:</label>
                                        </div>
                                        <Select
                                            isMulti
                                            value={glassOptions}
                                            onChange={handleChangeGlass}
                                            options={typeGlassOptionsPWRBase}
                                            styles={customStylesSelect}
                                            classNamePrefix="select"
                                            placeholder="Window(s) to be repaired"
                                            noOptionsMessage={customNoOptionsMessage}
                                        />
                                    </div>
                                    <div>
                                        <div className="text-center">
                                            <label className="text-base text-ag-secondary-letter">Description:</label>
                                        </div>
                                        <textarea
                                            className="overflow-y-hidden w-full min-h-20 text-center text-ag-secondary-letter border border-gray-500 p-2 rounded-xl disabled:bg-zinc-200"
                                            name="description"
                                            type="text"
                                            placeholder="Description"
                                            onChange={handleChange}
                                            value={data.description}
                                            onInput={(e) => {
                                                e.target.style.height = "auto";
                                                e.target.style.height = e.target.scrollHeight + "px";
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="w-full flex justify-center">
                                    <button
                                        className="w-full bg-ag-primary-light text-center text-white p-2 rounded-xl disabled:bg-zinc-200 m-auto z-1"
                                        type="button"
                                        onClick={(evt) => handleSubmit(evt)}
                                    >
                                        Continue
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-20 bg-black"></div>
        </>
    );
}

export default FormReparation;