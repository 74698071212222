import { useState } from "react";
import NavbarClients from "../../../components/navbar/NavbarClients";
import SidebarClients from "../../../components/sidebar/SidebarClients";
import AboutUsInfo from "../../fragments/about_us/AboutUsInfo";
import AboutUsFooter from "../../fragments/about_us/AboutUsFooter";

const AboutUs = () => {
    const [sidebarOpen, setSidebarOpen] = useState(true);

    return (
        <div>
            <div className="flex flex-row">
                <div className="w-full bg-no-repeat bg-cover fixed z-10">
                    <NavbarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                </div>
            </div>
            <div className="flex h-screen overflow-hidden">
                <div className={`${sidebarOpen ? "w-[100vw] md:w-80 z-20" : "w-20 z-0"}`}>
                    <SidebarClients type="retail" sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                </div>
                <div className="w-full overflow-auto flex flex-col mt-16 md:mt-[100px]">
                    <div className="flex-grow">
                        <AboutUsInfo />
                    </div>
                    <AboutUsFooter />
                </div>
            </div>
        </div>
    );
}

export default AboutUs;