import { _delete, _get, _post, _postFree, _put } from "./http";

export const getAddress = (idRetail, idAddress) => _get(`/retailUser/${idRetail}/myAddresses/${idAddress}`);

export const getAddresses = (idRetail) => _get(`/retailUser/${idRetail}/myAddresses`);

export const saveAddress = (idRetail, addressObject) => _post(`/retailUser/${idRetail}/myAddresses`, addressObject);

export const saveAddressWOR = (addressObject) => _postFree(`/retailUser/none/myAddresses`, addressObject);

export const updateAddress = (idRetail, idAddress, addressObject) => _put(`/retailUser/${idRetail}/myAddresses/${idAddress}`, addressObject);

export const deleteAddress = (idRetail, idAddress) => _delete(`/retailUser/${idRetail}/myAddresses/${idAddress}`);
